// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logsMenu {
    display: flex;
    justify-content: stretch;
    width: 100%;
    gap: 10px;
}

.logsMenu__item {
    font-size: 18px;
    font-weight: 500;
    padding: 10px 0px;   
    border-bottom: 3px solid transparent;
    color: black;
    text-align: center;
}

.logsMenu__item.active {
    border-color: #1850AC;
}

.logsMenu__item:hover {
    color: #A8A8A8;
    border-color: #A8A8A8;
}`, "",{"version":3,"sources":["webpack://./src/css/LogsMenu.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,wBAAwB;IACxB,WAAW;IACX,SAAS;AACb;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,oCAAoC;IACpC,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".logsMenu {\r\n    display: flex;\r\n    justify-content: stretch;\r\n    width: 100%;\r\n    gap: 10px;\r\n}\r\n\r\n.logsMenu__item {\r\n    font-size: 18px;\r\n    font-weight: 500;\r\n    padding: 10px 0px;   \r\n    border-bottom: 3px solid transparent;\r\n    color: black;\r\n    text-align: center;\r\n}\r\n\r\n.logsMenu__item.active {\r\n    border-color: #1850AC;\r\n}\r\n\r\n.logsMenu__item:hover {\r\n    color: #A8A8A8;\r\n    border-color: #A8A8A8;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
