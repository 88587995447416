// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hr_table--first-header.person {
  width: 350px;
}

.hr_table.person .hr_table--root thead th:nth-child(1) {
  min-width: 350px;
}

.hr_table.person .hr_table--root thead th:nth-child(2) {
  min-width: 200px;
}

.hr_table.person .hr_table--root thead th:nth-child(3) {
  min-width: 250px;
}

.hr_table.person .hr_table--root thead th:nth-child(4) {
  min-width: 320px;
}

.hr_table.person .hr_table--root thead th:nth-child(5) {
  width: 100%;
}

.hr_table.person td:nth-child(2) {
  text-align: center;
}

.hr_table.person td:nth-child(3) {
  text-align: center;
}

.hr_table.person td:nth-child(4) {
  text-align: center;
}

.hr_table.person td:nth-child(5) {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/css/PersonTable.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".hr_table--first-header.person {\r\n  width: 350px;\r\n}\r\n\r\n.hr_table.person .hr_table--root thead th:nth-child(1) {\r\n  min-width: 350px;\r\n}\r\n\r\n.hr_table.person .hr_table--root thead th:nth-child(2) {\r\n  min-width: 200px;\r\n}\r\n\r\n.hr_table.person .hr_table--root thead th:nth-child(3) {\r\n  min-width: 250px;\r\n}\r\n\r\n.hr_table.person .hr_table--root thead th:nth-child(4) {\r\n  min-width: 320px;\r\n}\r\n\r\n.hr_table.person .hr_table--root thead th:nth-child(5) {\r\n  width: 100%;\r\n}\r\n\r\n.hr_table.person td:nth-child(2) {\r\n  text-align: center;\r\n}\r\n\r\n.hr_table.person td:nth-child(3) {\r\n  text-align: center;\r\n}\r\n\r\n.hr_table.person td:nth-child(4) {\r\n  text-align: center;\r\n}\r\n\r\n.hr_table.person td:nth-child(5) {\r\n  text-align: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
