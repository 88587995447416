export const fields = {
  name: 'Полное наименование организации',
  short_name: 'Краткое наименование организации',
  legal_address: 'Адрес юридический',
  actual_address: 'Адрес фактический',
  inn: 'ИНН',
  ogrn: 'ОГРН',
  kpp: 'КПП',
  okpo: 'ОКПО',
  okved: 'ОКВЭД',
  chief_position: 'Должность руководителя',
  chief_position_id: 'Должность руководителя',
  chief_person: 'ФИО руководителя',
  chief_person_id: 'ФИО руководителя',
  parent_organization: 'Вышестоящая организация',
  phone: 'Телефон',
  phone_additional: 'Телефон (доп.)',
  email: 'Электронная почта',
  admin_person: 'Ответственный администратор системы',
  admin_person_id: 'Ответственный администратор системы',
  hr_person: 'Ответственный сотрудник кадров',
  hr_person_id: 'Ответственный сотрудник кадров',
  departments: 'Департаменты, которые входят внутрь юридического лица'
}

export const order = [
  'name',
  'short_name',
  'legal_address',
  'actual_address',
  'inn',
  'ogrn',
  'kpp',
  'okpo',
  'okved',
  'chief_position_id',
  'chief_person_id',
  'parent_organization',
  'phone',
  'phone_additional',
  'email',
  'admin_person_id',
  'hr_person_id'
]

export const main = 'name'

export const table = [
  'short_name',
  'legal_address',
  'actual_address',
  'inn',
  'ogrn',
  'kpp',
  'okpo',
  'okved',
  'chief_position',
  'chief_person',
  'parent_organization',
  'phone',
  'phone_additional',
  'email',
  'admin_person',
  'hr_person'
]
