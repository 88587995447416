import React from 'react';

import '../css/Input.css';

const Input = ({label, validationError, ...props}) => {
    return (
        <div className='input-container'>
            <label className='label'>
                {label}
                <input className={`input ${validationError ? "error" : ""}`} {...props}/>
            </label>    
        </div>
    );
};

export default Input;