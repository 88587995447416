import {useState, useEffect} from 'react'
import {useNavigate} from "react-router-dom"
import {enqueueSnackbar} from 'notistack'

import {devices as api} from 'API/hr'
import {fields, order, main, table} from 'utils/device.fields'
import {HR_PAGE_SIZE} from 'utils/constants'

import HrModal from 'components/HrModal'
import Header from '../components/Header'
import HrMenu from '../components/HrMenu'
import HrSearch from '../components/HrSearch'
import HrTable from '../components/HrTable'
import DeviceCard from 'components/DeviceCard'
import HrDialog from 'components/HrDialog'
import HrPagination from 'components/HrPagination'

import 'css/DeviceTable.css'

const get_full_name = person => {
  if (!person) return ''
  const {
    family_name,
    first_name,
    last_name
  } = person
  return `${family_name} ${first_name} ${last_name}`
}

const process_date = date => {
  if (!date || date === '') return new Date()
  return new Date(date)
}

const show_date = date_str => {
  if (!date_str || date_str === '') return ''
  const date = process_date(date_str)
  const year = date.getFullYear()
  const month_dirty = date.getMonth() + 1
  const month = month_dirty > 9 ? month_dirty : `0${month_dirty}`
  const date_dirty = date.getDate()
  const date_clear = date_dirty > 9 ? date_dirty : `0${date_dirty}`
  return `${date_clear}.${month}.${year}`
}

const process_model = model => {
  const {
    device_type,
    user
  } = model
  const res = {...model}
  res.device_type = res.device_type.name
  res.user = get_full_name(model.user)
  res.date_of_issue = show_date(model.date_of_issue)
  res.last_connection = show_date(model.last_connection)
  return res
}

const Devices = () => {
  const [models, set_models] = useState()
  const [total, set_total] = useState(0)
  const [page, set_page] = useState(0)
  const [page_size, set_page_size] = useState(10)
  const [column, set_column] = useState()
  const [direction, set_direction] = useState('asc')
  const [query, set_query] = useState()

  const [device, set_device] = useState()
  const [remove_device, set_remove_device] = useState()

  const navigate = useNavigate()

  const load_devices = async () => {
    const args = {
      page: {page, page_size},
      order: {column: column || order[0], direction},
      query: query || ''
    }
    try {
      const loaded_res = await api
        .get_page(args)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_models(loaded_res.Success.models.map(model => process_model(model)))
        set_total(loaded_res.Success.total.amount)
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  useEffect(() => {
    load_devices()
  }, [page, page_size, column, direction, query])

  const column_change = column => {
    if (!column) set_column()
    else set_column(column)
  }

  const direction_change = direction => {
    if (!direction) set_direction('asc')
    else (set_direction(direction))
  }

  const query_change = query => {
    if (!query) set_query('')
    else set_query(query)
  }

  const on_add = () => {
    navigate('/hr/device')
  }

  const on_select = async id => {
    try {
      const loaded_res = await api
        .get_by_id(id)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_device(loaded_res.Success)
      } else if (loaded_res.IsAbsent) {
        enqueueSnackbar('Нет прибора с таким Id', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  const on_device_close = () => {
    set_device()
  }

  const on_device_edit = () => {
    navigate(`/hr/device/${device.id}`)
    set_device()
  }

  const on_device_remove = () => {
    set_remove_device(device)
    set_device()
  }

  const on_remove_close = () => {
    set_remove_device()
  }

  const on_remove_aprove = async () => {
    try {
      const remove_res = await api
        .remove(remove_device.id)
        .then(res => res.json())
      if (remove_res.Success) {
        load_devices()
        enqueueSnackbar('Прибор удалён', {variant: 'success'})
      } else if (remove_res.IsAbsent) {
        enqueueSnackbar('Нет прибора с таким Id', {variant: 'error'})
      } else if (remove_res.NotEmpty) {
        enqueueSnackbar('Невозможно удалить. Есть связанные объекты', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
    set_remove_device()
  }

  const on_page_change = value => {
    set_page(value)
  }

  return (
    <div className="layout-page hr_layout">
      <Header>Кадровый учет</Header>
      <HrMenu />
      <div className="hr_page">
        <HrSearch
          fields={fields}
          order={order}
          column={column}
          onColumnChange={column_change}
          direction={direction}
          onDirectionChange={direction_change}
          query={query}
          onQueryChange={query_change}
          addTitle="Добавить новый прибор"
          onAdd={on_add}
        />
        {models && models.length > 0 ? (
          <>
            <HrTable
              models={models}
              fields={fields}
              main={main}
              table={table}
              select={on_select}
              short={total > page_size}
              className="device"
            />
            {total > page_size ? (
              <HrPagination
                current={page}
                size={page_size}
                total={total}
                change={on_page_change}
              />
            ) : null}
          </>
        ) : (
          <div className="hr_page--empty">Нет ни одного прибора</div>
        )}
      </div>
      {device ? (
        <HrModal
          title={device.name}
          onClose={on_device_close}
          onEdit={on_device_edit}
          onRemove={on_device_remove}
        >
          <DeviceCard className="hr_modal--card" device={device} />
        </HrModal>
      ) : null}
      {remove_device ? (
        <HrModal
          title="Удаление прибора"
          onClose={on_remove_close}
        >
          <HrDialog
            title={`Удалить прибор "${remove_device.serial_number}"?`}
            onRemove={on_remove_aprove}
            onCancel={on_remove_close}
          />
        </HrModal>
      ) : null}
    </div>
  )
}

export default Devices
