import {useState, useEffect} from 'react'
import {useNavigate} from "react-router-dom"
import {enqueueSnackbar} from 'notistack'

import {positions as api} from 'API/hr'
import {fields, order, main, table} from 'utils/position.fields'
import {HR_PAGE_SIZE} from 'utils/constants'

import HrModal from 'components/HrModal'
import Header from '../components/Header'
import HrMenu from '../components/HrMenu'
import HrSearch from '../components/HrSearch'
import HrTable from '../components/HrTable'
import PositionCard from 'components/PositionCard'
import HrDialog from 'components/HrDialog'
import HrPagination from 'components/HrPagination'

import 'css/PositionTable.css'

const process_category = category => category ? category.name : ''

const process_model = model => {
  const {category} = model
  const res = {...model}
  res.category = process_category(model.category)
  return res
}

const Positions = () => {
  const [models, set_models] = useState()
  const [total, set_total] = useState(0)
  const [page, set_page] = useState(0)
  const [page_size, set_page_size] = useState(10)
  const [column, set_column] = useState()
  const [direction, set_direction] = useState('asc')
  const [query, set_query] = useState()

  const [position, set_position] = useState()
  const [remove_position, set_remove_position] = useState()

  const navigate = useNavigate()

  const load_positions = async () => {
    const args = {
      page: {page, page_size},
      order: {column: column || order[0], direction},
      query: query || ''
    }
    try {
      const loaded_res = await api
        .get_page(args)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_models(loaded_res.Success.models.map(model => process_model(model)))
        set_total(loaded_res.Success.total.amount)
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  useEffect(() => {
    load_positions()
  }, [page, page_size, column, direction, query])

  const column_change = column => {
    if (!column) set_column()
    else set_column(column)
  }

  const direction_change = direction => {
    if (!direction) set_direction('asc')
    else (set_direction(direction))
  }

  const query_change = query => {
    if (!query) set_query('')
    else set_query(query)
  }

  const on_add = () => {
    navigate('/hr/position')
  }

  const on_select = async id => {
    try {
      const loaded_res = await api
        .get_by_id(id)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_position(loaded_res.Success)
      } else if (loaded_res.IsAbsent) {
        enqueueSnackbar('Нет должности с таким Id', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  const on_position_close = () => {
    set_position()
  }

  const on_position_edit = () => {
    navigate(`/hr/position/${position.id}`)
    set_position()
  }

  const on_position_remove = () => {
    set_remove_position(position)
    set_position()
  }

  const on_remove_close = () => {
    set_remove_position()
  }

  const on_remove_aprove = async () => {
    try {
      const remove_res = await api
        .remove(remove_position.id)
        .then(res => res.json())
      if (remove_res.Success) {
        load_positions()
        enqueueSnackbar('Должность удалена', {variant: 'success'})
      } else if (remove_res.IsAbsent) {
        enqueueSnackbar('Нет должности с таким Id', {variant: 'error'})
      } else if (remove_res.NotEmpty) {
        enqueueSnackbar('Невозможно удалить. Есть связанные объекты', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
    set_remove_position()
  }

  const on_page_change = value => {
    set_page(value)
  }

  return (
    <div className="layout-page hr_layout">
      <Header>Кадровый учет</Header>
      <HrMenu />
      <div className="hr_page">
        <HrSearch
          fields={fields}
          order={order}
          column={column}
          onColumnChange={column_change}
          direction={direction}
          onDirectionChange={direction_change}
          query={query}
          onQueryChange={query_change}
          addTitle="Добавить новую должность"
          onAdd={on_add}
        />
        {models && models.length > 0 ? (
          <>
            <HrTable
              models={models}
              fields={fields}
              main={main}
              table={table}
              select={on_select}
              short={total > page_size}
              className="position"
            />
            {total > page_size ? (
              <HrPagination
                current={page}
                size={page_size}
                total={total}
                change={on_page_change}
              />
            ) : null}
          </>
        ) : (
          <div className="hr_page--empty">Нет ни одной должности</div>
        )}
      </div>
      {position ? (
        <HrModal
          title={position.name}
          onClose={on_position_close}
          onEdit={on_position_edit}
          onRemove={on_position_remove}
        >
          <PositionCard className="hr_modal--card" position={position} />
        </HrModal>
      ) : null}
      {remove_position ? (
        <HrModal
          title="Удаление должности"
          onClose={on_remove_close}
        >
          <HrDialog
            title={`Удалить должность "${remove_position.name}"?`}
            onRemove={on_remove_aprove}
            onCancel={on_remove_close}
          />
        </HrModal>
      ) : null}
    </div>
  )
}

export default Positions
