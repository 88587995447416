import '../css/OfficeCard.css'

import {fields} from 'utils/office.fields'
import {HR_EMPTY_VALUE, HR_EMPTY_VALUES} from 'utils/constants'

const show_person = person => {
  if (!person) return HR_EMPTY_VALUE
  const {
    first_name,
    family_name,
    last_name
  } = person
  return `${family_name} ${first_name} ${last_name}`
}

const show_company = company => company ? company.short_name : HR_EMPTY_VALUE

const show_department = department => department ? department.full_name : HR_EMPTY_VALUE

const show_field = value => value ? value : HR_EMPTY_VALUE

const OfficeCard = ({className, office}) => {
  return (
    <div className={`hr_office-card ${className}`}>
      <div className="hr_office-card__field">
        <div className="hr_office-card__field--title">{fields.full_name}</div>
        <div className="hr_office-card__field--value">{show_field(office?.full_name)}</div>
      </div>
      <div className="hr_office-card__field">
        <div className="hr_office-card__field--title">{fields.company}</div>
        <div className="hr_office-card__field--value">{show_company(office?.company)}</div>
      </div>
      <div className="hr_office-card__field">
        <div className="hr_office-card__field--title">{fields.department}</div>
        <div className="hr_office-card__field--value">{show_department(office?.department)}</div>
      </div>
      <div className="hr_office-card__field">
        <div className="hr_office-card__field--title">{fields.chief_person}</div>
        <div className="hr_office-card__field--value">{show_person(office?.chief_person)}</div>
      </div>
      <div className="hr_office-card__field">
        <div className="hr_office-card__field--title">{fields.chief_phone}</div>
        <div className="hr_office-card__field--value">{show_field(office?.chief_phone)}</div>
      </div>
      <div className="hr_office-card__field">
        <div className="hr_office-card__field--title">{fields.chief_phone_additional}</div>
        <div className="hr_office-card__field--value">{show_field(office?.chief_phone_additional)}</div>
      </div>
      <div className="hr_office-card__field">
        <div className="hr_office-card__field--title">{fields.chief_email}</div>
        <div className="hr_office-card__field--value">{show_field(office?.chief_email)}</div>
      </div>
      <div className="hr_office-card__left_double">
        <div className="hr_office-card__field">
          <div className="hr_office-card__field--title">{fields.tasks}</div>
          <div className="hr_office-card__field--list">
            {office?.tasks?.map(task => (
              <div className="hr_office-card__field--value">{task.name}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="hr_office-card__right_double">
        <div className="hr_office-card__field">
          <div className="hr_office-card__field--title">{fields.persons}</div>
          <div className="hr_office-card__field--list">
            {office?.persons?.map(person => (
              <div className="hr_office-card__field--value">{show_person(person)}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfficeCard
