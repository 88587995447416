// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hr_menu {
  position: fixed;
  top: 60px;
  left: 380px;
  right: 0px;
  z-index: 3;
  height: 80px;
  background: #FFFFFF;
  box-shadow: 0px 9px 16px -14px #566067;
  display: flex;
  align-items: center;
}

.hr_menu__container {
  margin-left: 105px;
  width: 1330px;
  border-radius: 5px;
  background: #F5F5F5;
  height: 62px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 2px 0px rgba(24, 80, 172, 0.16);
}

.hr_menu__item {
  height: 42px;
  line-height: 42px;
  border-radius: 5px;
  border: 1px solid #002242;
  background: #FFFFFF;
  margin-left: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #1C5182;
  cursor: pointer;
}

.hr_menu__item.active {
  background: #F07F09;
  color: #FFFFFF;
}

.hr_menu__item.companies {
  width: 216px;
  margin-left: 15px;
}

.hr_menu__item.departments {
  width: 150px;
}

.hr_menu__item.offices {
  width: 170px;
}

.hr_menu__item.persons {
  width: 170px;
}

.hr_menu__item.divices {
  width: 310px;
}

.hr_menu__item.positions {
  width: 184px;
}
`, "",{"version":3,"sources":["webpack://./src/css/HrMenu.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,WAAW;EACX,UAAU;EACV,UAAU;EACV,YAAY;EACZ,mBAAmB;EACnB,sCAAsC;EACtC,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mDAAmD;AACrD;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":[".hr_menu {\r\n  position: fixed;\r\n  top: 60px;\r\n  left: 380px;\r\n  right: 0px;\r\n  z-index: 3;\r\n  height: 80px;\r\n  background: #FFFFFF;\r\n  box-shadow: 0px 9px 16px -14px #566067;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.hr_menu__container {\r\n  margin-left: 105px;\r\n  width: 1330px;\r\n  border-radius: 5px;\r\n  background: #F5F5F5;\r\n  height: 62px;\r\n  display: flex;\r\n  align-items: center;\r\n  box-shadow: 0px 0px 2px 0px rgba(24, 80, 172, 0.16);\r\n}\r\n\r\n.hr_menu__item {\r\n  height: 42px;\r\n  line-height: 42px;\r\n  border-radius: 5px;\r\n  border: 1px solid #002242;\r\n  background: #FFFFFF;\r\n  margin-left: 20px;\r\n  text-align: center;\r\n  font-size: 18px;\r\n  font-weight: 500;\r\n  color: #1C5182;\r\n  cursor: pointer;\r\n}\r\n\r\n.hr_menu__item.active {\r\n  background: #F07F09;\r\n  color: #FFFFFF;\r\n}\r\n\r\n.hr_menu__item.companies {\r\n  width: 216px;\r\n  margin-left: 15px;\r\n}\r\n\r\n.hr_menu__item.departments {\r\n  width: 150px;\r\n}\r\n\r\n.hr_menu__item.offices {\r\n  width: 170px;\r\n}\r\n\r\n.hr_menu__item.persons {\r\n  width: 170px;\r\n}\r\n\r\n.hr_menu__item.divices {\r\n  width: 310px;\r\n}\r\n\r\n.hr_menu__item.positions {\r\n  width: 184px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
