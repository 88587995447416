import {useState, useEffect} from 'react'
import {enqueueSnackbar} from 'notistack'

import {devices as devices_api} from 'API/hr'

const process_device = device => ({
  id: device.id,
  name: device.serial_number
})

const DeviceSelect = ({onSelect, onCancel}) => {
  const [query, set_query] = useState('')
  const [devices, set_devices] = useState([])
  const [selected, set_selected] = useState()

  const load_devices = async () => {
    const args = {
      page: {page: 0, page_size: 5},
      order: {column: null, direction: null},
      query: query || ''
    }
    try {
      const loaded_res = await devices_api
        .get_empty(args)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_devices(loaded_res.Success.models.map(device => process_device(device)))
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  useEffect(() => {
    load_devices()
  }, [query])

  const on_query_change = e => {
    const {value} = e.target
    set_query(value)
  }

  const on_select = item => {
    set_selected(item)
  }

  const on_save = () => {
    if (!selected) return
    onSelect(selected)
  }

  const selected_class = item =>
    selected?.id === item.id ? ' hr_form--search_field__option--selected' : ''

  return (
    <div className="hr_dialog hr_diaglog--form">
      <div className={`hr_form--search_field__options`}>
        <input
          className="hr_form--search_input"
          autoFocus
          value={query}
          onChange={on_query_change}
        />
        {devices.map(item => (
          <div
            className={`hr_form--search_field__option${selected_class(item)}`}
            onClick={() => on_select(item)}
          >{item.name}</div>
        ))}
      </div>
      <div className="hr_dialog--buttons">
        <button className="hr_dialog--button success" onClick={on_save}>Сохранить</button>
        <button className="hr_dialog--button" onClick={onCancel}>Отмена</button>
      </div>
    </div>

  )
}

export default DeviceSelect
