// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.log-header {
    display: flex;
    justify-content: space-between;
    color: #3D3D3D;
    align-items: center;
}

.log-header > .title {    
    font-size: 18px;
    font-weight: 700;
}

.log-header > .downloads {
    display: flex;
    gap: 25px;
}

.log-header > .downloads > .downloads__container {
    display: flex;
    gap: 15px;
    align-items: center;
}

.log-header > .downloads > .downloads__container > .icon {
    width: 30px;
    height: 30px;
    object-fit: contain;
    cursor: pointer;
}

.log-header > .downloads > .downloads__container > .text {
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
    white-space: pre;
}`, "",{"version":3,"sources":["webpack://./src/css/LogHeader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,0BAA0B;IAC1B,gBAAgB;AACpB","sourcesContent":[".log-header {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    color: #3D3D3D;\r\n    align-items: center;\r\n}\r\n\r\n.log-header > .title {    \r\n    font-size: 18px;\r\n    font-weight: 700;\r\n}\r\n\r\n.log-header > .downloads {\r\n    display: flex;\r\n    gap: 25px;\r\n}\r\n\r\n.log-header > .downloads > .downloads__container {\r\n    display: flex;\r\n    gap: 15px;\r\n    align-items: center;\r\n}\r\n\r\n.log-header > .downloads > .downloads__container > .icon {\r\n    width: 30px;\r\n    height: 30px;\r\n    object-fit: contain;\r\n    cursor: pointer;\r\n}\r\n\r\n.log-header > .downloads > .downloads__container > .text {\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n    text-decoration: underline;\r\n    white-space: pre;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
