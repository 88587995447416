// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 380px;
}

.modal__content {
    padding: 30px;
    border-radius: 5px;
    background-color: #FFFFFF;
    height: 254px;
    width: 560px;
}`, "",{"version":3,"sources":["webpack://./src/css/Modal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,oCAAoC;IACpC,eAAe;IACf,UAAU;IACV,MAAM;IACN,OAAO;IACP,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;IACb,YAAY;AAChB","sourcesContent":[".modal {\r\n    height: 100vh;\r\n    width: 100vw;\r\n    background-color: rgba(0, 0, 0, 0.4);\r\n    position: fixed;\r\n    z-index: 3;\r\n    top: 0;\r\n    left: 0;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    padding-left: 380px;\r\n}\r\n\r\n.modal__content {\r\n    padding: 30px;\r\n    border-radius: 5px;\r\n    background-color: #FFFFFF;\r\n    height: 254px;\r\n    width: 560px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
