// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hr_title {
  margin-top: 34px;
  width: 100%;
  height: 86px;
  border-radius: 5px;
  background: #FFFFFF;
  border: 1px solid #3D7CB6;
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.hr_title .hr_title--back_icon {
  width: 40px;
  height: 40px;
  margin-left: 30px;
  cursor: pointer;
  object-fit: cover;
}

.hr_title .hr_title--devider {
  margin-left: 30px;
  height: 60px;
  width: 2px;
  background: #E8E8E8;
}

.hr_title .hr_title--menu {
  margin-left: 30px;
  width: 150px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.hr_title .hr_title--item {
  height: 15px;
  line-height: 15px;
  border-left: 3px solid #2A547A;
  padding-left: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #2A547A;
  cursor: pointer;
}

.hr_title .hr_title--item.active {
  border-left: 3px solid #F07F09;
  font-weight: 700;
  color: #F07F09;
}

.hr_title .hr_title--text {
  flex: 1 0;
  text-align: center;
  height: 22px;
  line-height: 22px;
  font-size: 18px;
  font-weight: 700;
  color: #002242;
  margin-right: 282px;
}
`, "",{"version":3,"sources":["webpack://./src/css/HrTitle.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,8BAA8B;EAC9B,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,8BAA8B;EAC9B,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".hr_title {\r\n  margin-top: 34px;\r\n  width: 100%;\r\n  height: 86px;\r\n  border-radius: 5px;\r\n  background: #FFFFFF;\r\n  border: 1px solid #3D7CB6;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: stretch;\r\n}\r\n\r\n.hr_title .hr_title--back_icon {\r\n  width: 40px;\r\n  height: 40px;\r\n  margin-left: 30px;\r\n  cursor: pointer;\r\n  object-fit: cover;\r\n}\r\n\r\n.hr_title .hr_title--devider {\r\n  margin-left: 30px;\r\n  height: 60px;\r\n  width: 2px;\r\n  background: #E8E8E8;\r\n}\r\n\r\n.hr_title .hr_title--menu {\r\n  margin-left: 30px;\r\n  width: 150px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 12px;\r\n}\r\n\r\n.hr_title .hr_title--item {\r\n  height: 15px;\r\n  line-height: 15px;\r\n  border-left: 3px solid #2A547A;\r\n  padding-left: 8px;\r\n  font-size: 12px;\r\n  font-weight: 400;\r\n  color: #2A547A;\r\n  cursor: pointer;\r\n}\r\n\r\n.hr_title .hr_title--item.active {\r\n  border-left: 3px solid #F07F09;\r\n  font-weight: 700;\r\n  color: #F07F09;\r\n}\r\n\r\n.hr_title .hr_title--text {\r\n  flex: 1 0;\r\n  text-align: center;\r\n  height: 22px;\r\n  line-height: 22px;\r\n  font-size: 18px;\r\n  font-weight: 700;\r\n  color: #002242;\r\n  margin-right: 282px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
