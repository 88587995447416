// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
    border: 0.5px solid #1C5182;
    border-radius: 5px;
    padding-top: 9.5px;
    padding-bottom: 9.5px;
    background: rgba(255, 255, 255, 0.20);
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    color: #1C5182;
    width: 100%;
}

.button:hover, .button.active:hover {
    background: linear-gradient(357deg, #FFF 2.68%, #BDBEC3 97.74%);
    border-color: transparent;
    color: #FF7A00;
    cursor: pointer;
}
.button.active:hover{
    cursor: default;
}

.button.active {
    background: #F07F09;
    color: #FFF;
}

.button.blue_btn {
    color: #FFF;
    width: 170px;
    font-size: 14px; 
    font-weight: 500; 
    letter-spacing: 1.4px;
    background: #002242;
}

.button.white_btn {
    border: 1px solid #002242;
    width: 170px;
    letter-spacing: 1.4px; 
    font-weight: 300; 
    font-size: 14px; 
}

.button.blue_btn:hover, .button.white_btn:hover {
    background: #F07F09; 
    color: #FFF;
    border-color: #F07F09;
}`, "",{"version":3,"sources":["webpack://./src/css/Button.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,qCAAqC;IACrC,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,WAAW;AACf;;AAEA;IACI,+DAA+D;IAC/D,yBAAyB;IACzB,cAAc;IACd,eAAe;AACnB;AACA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;IACrB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,qBAAqB;AACzB","sourcesContent":[".button {\r\n    border: 0.5px solid #1C5182;\r\n    border-radius: 5px;\r\n    padding-top: 9.5px;\r\n    padding-bottom: 9.5px;\r\n    background: rgba(255, 255, 255, 0.20);\r\n    font-family: Montserrat;\r\n    font-size: 18px;\r\n    font-weight: 500;\r\n    color: #1C5182;\r\n    width: 100%;\r\n}\r\n\r\n.button:hover, .button.active:hover {\r\n    background: linear-gradient(357deg, #FFF 2.68%, #BDBEC3 97.74%);\r\n    border-color: transparent;\r\n    color: #FF7A00;\r\n    cursor: pointer;\r\n}\r\n.button.active:hover{\r\n    cursor: default;\r\n}\r\n\r\n.button.active {\r\n    background: #F07F09;\r\n    color: #FFF;\r\n}\r\n\r\n.button.blue_btn {\r\n    color: #FFF;\r\n    width: 170px;\r\n    font-size: 14px; \r\n    font-weight: 500; \r\n    letter-spacing: 1.4px;\r\n    background: #002242;\r\n}\r\n\r\n.button.white_btn {\r\n    border: 1px solid #002242;\r\n    width: 170px;\r\n    letter-spacing: 1.4px; \r\n    font-weight: 300; \r\n    font-size: 14px; \r\n}\r\n\r\n.button.blue_btn:hover, .button.white_btn:hover {\r\n    background: #F07F09; \r\n    color: #FFF;\r\n    border-color: #F07F09;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
