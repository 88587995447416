// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.horMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 15px 0px 15px;
    border-radius: 5px;
    background: #F5F5F5;
    box-shadow: 0px 0px 4px 0px #1850AC inset;
}

.tabs-container {
    margin-bottom: 16px;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/css/HorMenu.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,2BAA2B;IAC3B,kBAAkB;IAClB,mBAAmB;IACnB,yCAAyC;AAC7C;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf","sourcesContent":[".horMenu {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    padding: 15px 15px 0px 15px;\r\n    border-radius: 5px;\r\n    background: #F5F5F5;\r\n    box-shadow: 0px 0px 4px 0px #1850AC inset;\r\n}\r\n\r\n.tabs-container {\r\n    margin-bottom: 16px;\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
