import {useState, useEffect} from 'react'
import {useParams} from "react-router-dom"
import {useNavigate} from "react-router-dom"
import {enqueueSnackbar} from 'notistack'

import {departments as api, companies as company_api} from 'API/hr'
import Header from 'components/Header'
import HrMenu from 'components/HrMenu'
import HrTitle from 'components/HrTitle'
import DepartmentForm from 'components/DepartmentForm'
import DepartmentCard from 'components/DepartmentCard'

import {HR_VIEW_MODE, HR_EDIT_MODE} from 'utils/constants'

const Department = () => {
  const [department, set_department] = useState()
  const [mode, set_mode] = useState(HR_EDIT_MODE)

  const {id, company_id} = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const get_title = () => {
    if (!id) return 'Новый департамент'
    else if (department) return department.full_name
    return ''
  }

  const load_department = async () => {
    if (id) {
      try {
        const loaded_res = await api
          .get_by_id(id)
          .then(res => res.json())
        if (loaded_res.Success) {
          set_department(loaded_res.Success)
        } else if (loaded_res.IsAbsent) {
          enqueueSnackbar('Нет департамента с таким Id', {variant: 'error'})
        } else {
          enqueueSnackbar('Invalid query', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    } else if (company_id) {
      load_company()
    }
  }

  const load_company = async () => {
    try {
      const loaded_res = await company_api
        .get_by_id(company_id)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_department({
          full_name: '',
          chief_phone: '',
          chief_phone_additional: '',
          chief_email: '',
          company: {
            id: loaded_res.Success.id,
            short_name: loaded_res.Success.short_name
          }
        })
      } else if (loaded_res.IsAbsent) {
        enqueueSnackbar('Нет компании с таким Id', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  useEffect(() => {
    load_department()
  }, [id])

  useEffect(() => {
    if (!department) return
    if (department.company) return
    load_company()
  }, [department])

  const save_department = async values => {
    if (id) {
      try {
        const save_res = await api
          .update({id: department.id, ...values})
          .then(res => res.json())
        if (save_res.Success) {
          enqueueSnackbar('Департамент успешно обновлён', {variant: 'success'})
          load_department()
        } else if (save_res.IsAbsent) {
          enqueueSnackbar('Нет департамента с таким Id', {variant: 'error'})
        } else if (save_res.IsExistByField) {
          enqueueSnackbar('Департамент с таким названием уже существует', {variant: 'error'})
        } else {
          enqueueSnackbar('Invalid update', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    } else {
      try {
        const save_res = await api
          .create({...values})
          .then(res => res.json())
        if (save_res.Success) {
          enqueueSnackbar('Департамент успешно сохранён', {variant: 'success'})
          navigate(`/hr/department/${save_res.Success}`)
        } else if (save_res.IsExist) {
          enqueueSnackbar('Департамент с таким названием уже существует', {variant: 'error'})
        } else {
          enqueueSnackbar('Invalid update', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    }
  }

  const on_view = () => set_mode(HR_VIEW_MODE)
  const on_edit = () => set_mode(HR_EDIT_MODE)

  const on_back = () => {
    navigate('/hr/departments')
  }

  return (
    <div className="layout-page hr_layout">
      <Header>Кадровый учет</Header>
      <HrMenu />
      <div className="hr_page">
        <HrTitle
          mode={mode}
          onView={on_view}
          onEdit={on_edit}
          onBack={on_back}
        >{get_title()}</HrTitle>
        {mode === HR_EDIT_MODE ? (
          <DepartmentForm
            department={department}
            onSave={save_department}
            onReload={load_department}
          />
        ) : null}
        {mode === HR_VIEW_MODE ? (
          <DepartmentCard className="hr_page--card" department={department} />
        ) : null}
      </div>
    </div>  )
}

export default Department
