import { useEffect, useState } from 'react';

export const useOutsideClick = (el, initialState) => {
    const [isActive, setIsActive] = useState(initialState);

    useEffect(() => {
        const onClick = (e) => {
            if (el.current && !el.current.contains(e.target) && el.current.id !== 'disabled') {
                setIsActive(!isActive);
            }
        };

        if (isActive) {
            setTimeout(() => {
                document.addEventListener("click", onClick);
            });
        }

        return () => {
            document.removeEventListener("click", onClick);
        };
    }, [isActive, el]);

    return [isActive, setIsActive];
};