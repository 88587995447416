import React from 'react';

const Status = ({addStyles}) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
            <circle cx='9' cy='9' r='7' fill={addStyles.color} stroke={addStyles.stroke} strokeWidth={addStyles.strokeWidth}/>
        </svg>
    );
};

export default Status;