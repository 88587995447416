import {useState} from 'react'

const Switch = ({on, onClick}) => {
  return (
    <div className={`hr_switch${on ? ' on' : ''}`} onClick={onClick}>
      <div className="hr_thumb"></div>
    </div>
  )
}

const ProfileRights = () => {
  const [is_ecg_data, set_is_ecg_data] = useState(false)
  const [is_edit, set_is_edit] = useState(false)
  const [is_view, set_is_view] = useState(false)
  const [is_location, set_is_location] = useState(false)
  const [is_email, set_is_email] = useState(false)

  return (
    <div className="profile_container">
      <div className="profile__rights">
        <div className="profile__rights--header">
          <div className="profile__rights--title">Доступ</div>
          <div className="profile__rights--title">Разрешение</div>
        </div>
        <div className="profile__rights--body">
          <div className="profile__rights--row">
            <div className="profile__rights--field">Данные ЭКГ</div>
            <div className="profile__rights--value">
              <Switch on={is_ecg_data} onClick={() => set_is_ecg_data(is => !is)} />
            </div>
          </div>
          <div className="profile__rights--row">
            <div className="profile__rights--field">Редактирование личных данных</div>
            <div className="profile__rights--value">
              <Switch on={is_edit} onClick={() => set_is_edit(is => !is)} />
            </div>
          </div>
          <div className="profile__rights--row">
            <div className="profile__rights--field">Просмотр результатов диагностики</div>
            <div className="profile__rights--value">
              <Switch on={is_view} onClick={() => set_is_view(is => !is)} />
            </div>
          </div>
          <div className="profile__rights--row">
            <div className="profile__rights--field">Отслеживание местоположения</div>
            <div className="profile__rights--value">
              <Switch on={is_location} onClick={() => set_is_location(is => !is)} />
            </div>
          </div>
          <div className="profile__rights--row">
            <div className="profile__rights--field">Отправка уведомлений на электронную почту</div>
            <div className="profile__rights--value">
              <Switch on={is_email} onClick={() => set_is_email(is => !is)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileRights
