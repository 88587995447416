import React from 'react';

import 'css/LogHeader.css';

import pdf_icon from 'assets/pdf.png';
import xls_icon from 'assets/xls.png';

const LogHeader = ({children, downloads}) => {
    return (
        <div className='log-header'>
            <div className='title'>
                {children}
            </div>
            {
                downloads && (
                    <div className='downloads'>
                        <div className='downloads__container'>
                            <img className='icon' src={xls_icon}/>
                            <div className='text'>
                                {'Download\nreport as XLS'}
                            </div>
                            <img className='icon' src={pdf_icon}/>
                            <div className='text'>
                                {'Download\nreport as PDF'}
                            </div>
                        </div>
                    </div>
                )
            }    
        </div>
    );
};

export default LogHeader;