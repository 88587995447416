// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-button__container {
    display: flex;
    align-items: flex-start;
    gap: 1px;
}

.filter-button__container .filter-button__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #021139;
    height: 24px;
    width: 174px;
    font-size: 12px;
    font-family: Montserrat;
    color: white;
    font-weight: 400;
}

.filter-button__container .filter-button__close {
    width: 12px;
    height: 12px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/css/FilterButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".filter-button__container {\r\n    display: flex;\r\n    align-items: flex-start;\r\n    gap: 1px;\r\n}\r\n\r\n.filter-button__container .filter-button__button {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    border-radius: 5px;\r\n    background-color: #021139;\r\n    height: 24px;\r\n    width: 174px;\r\n    font-size: 12px;\r\n    font-family: Montserrat;\r\n    color: white;\r\n    font-weight: 400;\r\n}\r\n\r\n.filter-button__container .filter-button__close {\r\n    width: 12px;\r\n    height: 12px;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
