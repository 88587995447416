import '../css/DeviceCard.css'

import {fields} from 'utils/device.fields'
import {HR_EMPTY_VALUE} from 'utils/constants'

const show_field = value => value ? value : HR_EMPTY_VALUE

const get_full_name = person => {
  if (!person) return HR_EMPTY_VALUE
  const {
    family_name,
    first_name,
    last_name
  } = person
  return `${family_name} ${first_name} ${last_name}`
}

const process_date = date => {
  if (!date || date === '') return new Date()
  return new Date(date)
}

const show_date = date_str => {
  if (!date_str || date_str === '') return HR_EMPTY_VALUE
  const date = process_date(date_str)
  const year = date.getFullYear()
  const month_dirty = date.getMonth() + 1
  const month = month_dirty > 9 ? month_dirty : `0${month_dirty}`
  const date_dirty = date.getDate()
  const date_clear = date_dirty > 9 ? date_dirty : `0${date_dirty}`
  return `${date_clear}.${month}.${year}`
}

const DeviceCard = ({className, device}) => {
  return (
    <div className={`hr_device-card ${className}`}>
      <div className="hr_device-card__field">
        <div className="hr_device-card__field--title">{fields.serial_number}</div>
        <div className="hr_device-card__field--value">{show_field(device?.serial_number)}</div>
      </div>
      <div className="hr_device-card__field">
        <div className="hr_device-card__field--title">{fields.device_type}</div>
        <div className="hr_device-card__field--value">{show_field(device?.device_type?.name)}</div>
      </div>
      <div className="hr_device-card__field">
        <div className="hr_device-card__field--title">{fields.date_of_issue}</div>
        <div className="hr_device-card__field--value">{show_date(device?.date_of_issue)}</div>
      </div>
      <div className="hr_device-card__field">
        <div className="hr_device-card__field--title">{fields.sim_number}</div>
        <div className="hr_device-card__field--value">{show_field(device?.sim_number)}</div>
      </div>
      <div className="hr_device-card__field">
        <div className="hr_device-card__field--title">{fields.last_connection}</div>
        <div className="hr_device-card__field--value">{show_date(device?.last_connection)}</div>
      </div>
      <div className="hr_device-card__field">
        <div className="hr_device-card__field--title">{fields.contract}</div>
        <div className="hr_device-card__field--value">{show_field(device?.contract)}</div>
      </div>
      <div className="hr_device-card__field">
        <div className="hr_device-card__field--title">{fields.user}</div>
        <div className="hr_device-card__field--value">{get_full_name(device?.user)}</div>
      </div>
    </div>
  )
}

export default DeviceCard
