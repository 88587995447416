import React from 'react';

import '../css/LogsInfo.css';

import ButtonGroup from './ButtonGroup';

import {BiSolidDownArrow} from "react-icons/bi";
import Indicator from './Indicator';
import DisplayPeriod from './DisplayPeriod';
import { useOutletContext } from 'react-router-dom';

const LogsInfo = ({tabs, indicators, hideBlock}) => {
    const context = useOutletContext();

    return (
        <div className={hideBlock ? 'logs-info hide' : 'logs-info'}>
            <div className='logs-menu'>
                <ButtonGroup tabs={tabs} bigGap/>
                <div className='department'>
                    <span className='department__text'>
                        Department
                    </span>
                    <BiSolidDownArrow color='#002242'/>
                </div>
            </div>
            <div className='indicators'>
                {
                    indicators.map((info, i) => (<Indicator key={i}
                                                            info={info}
                                                            data={{percent: 10}}/>))
                }
            </div>
            <DisplayPeriod {...context} />
        </div>
    );
};

export default LogsInfo;
