// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Period-time {
    display: flex;
    align-items: center;
    gap: 4px;
}

.Period-time svg {
    margin-right: 6px;
}

.Period-time .item_hours {
    font-family: Inter;
    color: #1C5182;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    outline: 0;
    cursor: pointer;
    padding: 5px 0px;
    background: #D0E9FF;
    padding-left: 4px;
    padding-right: 4px;
}

.Period-time .item_minutes {
    font-family: Inter;
    color: #1C5182;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    outline: 0;
    cursor: pointer;
    padding: 5px 0px;
    background: #D0E9FF;
    padding-left: 4px;
    padding-right: 4px;
}

`, "",{"version":3,"sources":["webpack://./src/css/PeriodTime.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".Period-time {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 4px;\r\n}\r\n\r\n.Period-time svg {\r\n    margin-right: 6px;\r\n}\r\n\r\n.Period-time .item_hours {\r\n    font-family: Inter;\r\n    color: #1C5182;\r\n    font-size: 14px;\r\n    border: none;\r\n    border-radius: 5px;\r\n    outline: 0;\r\n    cursor: pointer;\r\n    padding: 5px 0px;\r\n    background: #D0E9FF;\r\n    padding-left: 4px;\r\n    padding-right: 4px;\r\n}\r\n\r\n.Period-time .item_minutes {\r\n    font-family: Inter;\r\n    color: #1C5182;\r\n    font-size: 14px;\r\n    border: none;\r\n    border-radius: 5px;\r\n    outline: 0;\r\n    cursor: pointer;\r\n    padding: 5px 0px;\r\n    background: #D0E9FF;\r\n    padding-left: 4px;\r\n    padding-right: 4px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
