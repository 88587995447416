// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.down-arrow-cell {
    display: flex;
    align-items: center;
    width: 100%;
}

.down-arrow-cell > .text {
    flex-grow: 1;
}

.down-arrow-cell > .arrow {
    color: '#002242';
    height: 16px;
    width: 16px;
    flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./src/css/DownArrowCell.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,cAAc;AAClB","sourcesContent":[".down-arrow-cell {\r\n    display: flex;\r\n    align-items: center;\r\n    width: 100%;\r\n}\r\n\r\n.down-arrow-cell > .text {\r\n    flex-grow: 1;\r\n}\r\n\r\n.down-arrow-cell > .arrow {\r\n    color: '#002242';\r\n    height: 16px;\r\n    width: 16px;\r\n    flex-shrink: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
