import '../css/HrTable.css'

const HrTable = (props) => {
  const {
    models,
    fields,
    main,
    table,
    select,
    short,
    className
  } = props

  return (
    <>
      <div className={`hr_table--first-header ${className}`}>{fields[main]}</div>
      <div className={`hr_table ${short ? 'short' : ''} ${className}`}>
        <table className="hr_table--root">
          <thead>
            <tr>
              <th>{fields[main]}</th>
              {table.map(table_field => (
                <th>{fields[table_field]}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {models.map(model => (
              <tr onClick={() => select(model.id)}>
                <th>{model[main]}</th>
                {table.map(table_field => (
                  <td>{model[table_field]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default HrTable
