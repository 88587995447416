import React, { useState, useEffect } from 'react';
import '../css/Calendar.css';
import CalendarHeader from './CalendarHeader';
import CalendarWeekdays from './CalendarWeekdays';
import CalendarDays from './CalendarDays';
import DataService from 'API/DataService'

const Calendar = (props) => {
    const {
      selectDate,
      setSelectDate,
      handleClick,
      isEcgMonths,
      fullYears
    } = props
    const [calendarDate, setCalendarDate] = useState(selectDate);
    const [hl_days, set_hl_days] = useState([])

    useEffect(() => {
      if (!calendarDate || !isEcgMonths) return
      (async () => {
        const month = calendarDate.getMonth()
        const year = calendarDate.getFullYear()
        const load_res = await DataService.getEcgStatus(year, month + 1)
        if (load_res && load_res.length > 0) set_hl_days(load_res)
      })()
    }, [calendarDate])


    return (
        <div className='calendar'>
            <CalendarHeader calendarDate={calendarDate} 
                            setCalendarDate={setCalendarDate}
                            handleClick={handleClick}
                            fullYears />
            <div className='calendar__body'>
                <CalendarWeekdays/>
                <CalendarDays calendarDate={calendarDate} 
                              setCalendarDate={setCalendarDate}
                              selectDate={selectDate}
                              setSelectDate={setSelectDate}
                              hlDays={hl_days} />
            </div>
        </div>
    );
};

export default Calendar;
