import React, { useEffect, useState, useRef } from 'react';

import Menu from '../components/Menu';
import { Outlet, useLocation } from 'react-router-dom';
import { TabContext } from '../context';
import { getEmailMessage, getHeaderMenu, setTabButton } from 'utils/functions';
import { getDateHourMinute, getToday } from 'utils/date-utils';
import { getProfileOwner, lead_zero } from '../utils/functions';
import { useOutsideClick } from 'hooks/useOutsideClick';
import {IoMdClose} from 'react-icons/io';
import Modal from 'components/Modal';

import 'css/Main.css';
import Input from 'components/Input';
import Button from 'components/Button';
import DataService from 'API/DataService';
import { useField } from 'hooks/useField';
import { useFetching } from 'hooks/useFetching';
import {enqueueSnackbar} from 'notistack';

const modalLabel = "Enter doctor's email to send the report";

const Main = () => {
    const location = useLocation();
    const [clicked, setClicked] = useState(null);
    const [startDate, setStartDate] = useState(getToday());
    const [endDate, setEndDate] = useState(getToday());
    const [timeDate, setTimeDate] = useState(new Date());
    const [isDiagnostics, setIsDiagnostics] = useState(false);
    const [hideBlock, setHideBlock] = useState(false);
    const [headerMenu, setHeaderMenu] = useState([]);
    const emailField= useField('')

    const modalRef = useRef(null);

    const [isModalActive, setIsModalActive] = useOutsideClick(modalRef, false);

    const [fetchEmail, emailLoading, emailError] = useFetching(async (email, data) => {
        const response = await DataService.sendEmail(email, data);
        setIsModalActive(false);
        enqueueSnackbar('Message was sent successfully', {variant: 'success'})
        emailField.change('');
    });

    useEffect(() => {
        const {
            tabIndex,
            diagnostics
        } = setTabButton(location.pathname);
        setClicked(tabIndex);
        setIsDiagnostics(diagnostics);
        setHeaderMenu(() => getHeaderMenu(location.pathname))
        window.scrollTo(0, 0);
    }, [location])

    useEffect(() => {
        window.addEventListener("scroll", onScroll)
        return () => {
            window.removeEventListener("scroll", onScroll)
        }
    }, [])

    const onScroll = () => {
        if (window.scrollY > 0) {
            setHideBlock(true)
        }  
        else {
            setHideBlock(false)
        }
    }

    const onSendEmail = async () => {
        if (emailLoading) return;
        let date = new Date(startDate.getTime());
        date.setHours(timeDate.getHours(), timeDate.getMinutes(), 0, 0);
        let minutes = Math.floor((new Date().getTime() - date.getTime()) / 1000 / 60);
        if (minutes <= 0) {
            alert("Wrong date and time");
            return;
        } else if (minutes > 10) {
            minutes = 10;
        }

        if (emailField.validate_email()) {
            return;
        }

        const offset = startDate.getTimezoneOffset();
        const is_plus = offset < 0;
        const iso_hh = Math.floor(Math.abs(offset) / 60);
        const iso_mm = Math.floor(Math.abs(offset) % 60);
        const iso_date = new Date(date.getTime() - offset * 1000 * 60);
        const tz = `${is_plus ? '+' : '-'}${lead_zero(iso_hh)}:${lead_zero(iso_mm)}`;
        const owner = getProfileOwner();
        const data = {
            subject: `${owner.name} ${owner.surname} / Date of research: ${getDateHourMinute(date)}`,
            message: getEmailMessage(owner),
            pdfArgs: [
                {
                    startDateTime: `${iso_date.toISOString().replace('Z', '')}${tz}`,
                    minCount: minutes
                }
            ]
        };

        fetchEmail(emailField.value, data);
    }
    
    const onClose = () => {
        if (emailLoading) return;
        setIsModalActive(false);
        emailField.change('');
    }

    return (
        <TabContext.Provider value={{
            clicked
        }}>
            <Menu/>
            <Outlet context={{startDate, setStartDate,
                              endDate, setEndDate,
                              timeDate, setTimeDate,
                              isModalActive, setIsModalActive,
                              hideBlock, setHideBlock,
                              headerMenu, isDiagnostics}}/>
            {
                isModalActive &&
                <Modal ref={modalRef}
                       disabled={emailLoading}>
                    <div className='modal__content__container'>
                        <IoMdClose 
                            className='close-icon'
                            color='#1C5182'
                            onClick={onClose}/>
                        <Input label={modalLabel}
                               value={emailField.value}
                               validationError={emailField.is_error}
                               onChange={e => emailField.change(e.target.value)}/>
                        <Button className='button blue_btn'
                                onClick={onSendEmail}
                                disabled={emailLoading}>
                            SEND
                        </Button>
                    </div>
                </Modal>
            }
        </TabContext.Provider>    
    );
};

export default Main;