export const fields = {
  serial_number: 'Серийный номер устройства',
  device_type: 'Тип носимого устройства',
  date_of_issue: 'Дата выдачи',
  sim_number: 'Номер сим-карты',
  last_connection: 'Последнее время связи',
  contract: 'Контракт поставки',
  user_id: 'Сотрудник (пользователь прибора)',
  user: 'Сотрудник (пользователь прибора)'
}

export const order = [
  'serial_number',
  'device_type',
  'date_of_issue',
  'sim_number',
  'last_connection',
  'contract',
  'user_id'
]

export const main = 'serial_number'

export const table = [
  'device_type',
  'date_of_issue',
  'sim_number',
  'last_connection',
  'contract',
  'user'
]
