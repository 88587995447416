export const fields = {
  full_name: 'Полное наименование департамента',
  company_id: 'Юридическое лицо, к которому относится организация',
  company: 'Юридическое лицо, к которому относится организация',
  chief_person_id: 'ФИО руководителя департамента',
  chief_person: 'ФИО руководителя департамента',
  chief_phone: 'Телефон Руководителя',
  chief_phone_additional: 'Телефон руководителя (доп).',
  chief_email: 'Эл. почта руководителя',
  tasks: 'Задачи департамента',
  offices: 'Отделы, входящие внутрь департамента'
}

export const order = [
  'full_name',
  'company_id',
  'chief_person_id',
  'chief_phone',
  'chief_phone_additional',
  'chief_email'
]

export const main = 'full_name'

export const table = [
  'company',
  'chief_person',
  'chief_phone',
  'chief_phone_additional',
  'chief_email'
]
