import React, { useContext, useState } from 'react';
import {Link} from 'react-router-dom';
import Dropdown from './Dropdown';

const NavBar = () => {
    const [move, setMove] = useState(false);
    
    const onMove = (move) => {
        setMove(move);
    }

    return (
        <div className='navbar'>
            <Link to='/report/summary' 
                  className='navbar__link' >
                Reports
            </Link>
            <Dropdown onMove={onMove}/>
            <Link to='/employees' className='navbar__link'>Employees</Link>
            <Link to='/employee-location' className='navbar__link'>Employees’ location</Link>
            <Link to='/hr' className='navbar__link'>Personnel records</Link>
            <Link to='/handbooks' className='navbar__link'>User Guide</Link>
        </div>
    );
};

export default NavBar;
