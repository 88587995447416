import {useEffect} from 'react'
import {enqueueSnackbar} from 'notistack'

import DataService from 'API/DataService'
import {useField} from 'hooks/useField'
import {Field} from 'components/HrForms'

const ProfileContacts = ({profile, onReload}) => {
  const phone = useField('')
  const email = useField('')
  const actual_address = useField('')

  useEffect(() => {
    if (!profile) return
    phone.change(profile.phone)
    email.change(profile.email)
    actual_address.change(profile.actual_address)
  }, [profile])

  const on_save = async () => {
    const errors = []
    errors.push(phone.validate_phone_opt())
    errors.push(email.validate_email())
    if (errors.some(err => err)) return
    try {
      const save_res = await DataService.updateProfile({
        id: profile.id,
        first_name: profile.first_name,
        family_name: profile.family_name,
        last_name: profile.last_name,
        passport_series: profile.passport_series,
        passport_number: profile.passport_number,
        passport_issue_office: profile.passport_issue_office,
        passport_office_code: profile.passport_office_code,
        passport_date_of_issue: profile.passport_date_of_issue,
        actual_address: actual_address.value,
        date_of_birth: profile.date_of_birth,
        gender: profile.gender,
        personal_number: profile.personal_number,
        family_status: profile.family_status,
        clearance_category: profile.clearance_category,
        position_id: profile.position?.id,
        phone: phone.value,
        email: email.value,
        office_id: profile.office?.id
      })
      if (save_res.Success) {
        enqueueSnackbar('Сохранение прошло успешно', {variant: 'success'})
        onReload()
      } else {
        enqueueSnackbar('Invalid update', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  return (
    <div className="profile_container">
      <div className="profile_personal__two_column--contacts">
        <div className="profile_title">Телефон</div>
        <Field
          value={phone.value}
          isError={phone.is_error}
          onChange={e => phone.change(e.target.value)}
        />
        <div className="profile_title second">Электронная почта</div>
        <Field
          value={email.value}
          isError={email.is_error}
          onChange={e => email.change(e.target.value)}
        />
      </div>
      <div className="profile_personal__one_column--contacts">
        <div className="profile_title">Адрес фактического проживания</div>
        <Field
          value={actual_address.value}
          isError={actual_address.is_error}
          onChange={e => actual_address.change(e.target.value)}
        />
      </div>
      <div className="profile__footer">
        <button className="profile--button" onClick={on_save}>СОХРАНИТЬ </button>
      </div>
    </div>
  )
}

export default ProfileContacts
