import unlink_icon from 'assets/dialog_unlink.svg'
import remove_icon from 'assets/dialog_remove.svg'
import warning_icon from 'assets/dialog_warning.svg'
import '../css/ProfileDialog.css'
import ClickAwayListener from 'react-click-away-listener'

const ProfileDialog = (props) => {
  const {
    text,
    tool,
    onClose,
    onAprove,
    onCancel
  } = props

  return (
    <div className="profile_dialog--cloak">
      <ClickAwayListener onClickAway={onClose}>
        <div className="profile_dialog">
          <div className="profile_dialog--header">
            {tool === 'unlink' ? (
              <img src={unlink_icon} />
            ) : null}
            {tool === 'remove' ? (
              <img src={remove_icon} />
            ) : null}
            <img src={warning_icon} />
          </div>
          <div className="profile_dialog--text">{text}</div>
          <div className="profile_dialog--footer">
            <button className="profile_dialog--button aprove" onClick={onAprove}>YES</button>
            <button className="profile_dialog--button cancel" onClick={onCancel}>CANCEL</button>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  )
}

export default ProfileDialog
