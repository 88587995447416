// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hr_position-card {
  width: 876px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; 
  column-gap: 20px;
}

.hr_position-card__field {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: stretch;
}

.hr_position-card__field--title {
  line-height: 15px;
  font-weight: 700;
  font-size: 12px;
  color: #002242;
}

.hr_position-card__field--value {
  line-height: 15px;
  font-weight: 400;
  font-size: 12px;
  color: #002242;
}
`, "",{"version":3,"sources":["webpack://./src/css/PositionCard.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,kCAAkC;EAClC,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB","sourcesContent":[".hr_position-card {\r\n  width: 876px;\r\n  display: grid;\r\n  grid-template-columns: 1fr 1fr 1fr; \r\n  column-gap: 20px;\r\n}\r\n\r\n.hr_position-card__field {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 12px;\r\n  align-items: stretch;\r\n}\r\n\r\n.hr_position-card__field--title {\r\n  line-height: 15px;\r\n  font-weight: 700;\r\n  font-size: 12px;\r\n  color: #002242;\r\n}\r\n\r\n.hr_position-card__field--value {\r\n  line-height: 15px;\r\n  font-weight: 400;\r\n  font-size: 12px;\r\n  color: #002242;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
