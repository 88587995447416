// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar {
    width: 270px;
    height: 270px;
    padding: 25px;
    border-radius: 10px;
    border: 1px solid #000;
    background: #FFF;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-family: Inter;
    color: #1C5182;
    font-size: 13px;
}
`, "",{"version":3,"sources":["webpack://./src/css/Calendar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;IAChB,aAAa;IACb,8BAA8B;IAC9B,sBAAsB;IACtB,kBAAkB;IAClB,cAAc;IACd,eAAe;AACnB","sourcesContent":[".calendar {\r\n    width: 270px;\r\n    height: 270px;\r\n    padding: 25px;\r\n    border-radius: 10px;\r\n    border: 1px solid #000;\r\n    background: #FFF;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    flex-direction: column;\r\n    font-family: Inter;\r\n    color: #1C5182;\r\n    font-size: 13px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
