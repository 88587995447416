import axios from "axios";

export const BASE_URL = 'https://mapsys.spa2099.com/api/';

const $api = axios.create({
    baseURL: BASE_URL
});

$api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
});

export default $api;
