import close_icon from 'assets/hr_modal_close.svg'
import edit_icon from 'assets/hr_modal_edit.svg'
import remove_icon from 'assets/hr_modal_remove.svg'
import '../css/HrModal.css'
import ClickAwayListener from 'react-click-away-listener'

const HrModal = (props) => {
  const {
    title,
    className,
    children,
    onClose,
    onEdit,
    onRemove
  } = props

  const is_tools = !!onEdit || !!onRemove

  return (
    <div className="hr_modal--cloak">
      <ClickAwayListener onClickAway={onClose}>
        <div className="hr_modal">
          <div className="hr_modal--top">
            <img
              src={close_icon}
              onClick={onClose}
              className="hr_modal--top__icon"
            />
          </div>
          <div className="hr_modal--header">
            <div className="hr_modal--header__title">{title}</div>
            {is_tools ? (
              <div className="hr_modal--header__tools">
                {onEdit ? (
                  <img
                    src={edit_icon}
                    className="hr_modal--header__icon"
                    onClick={onEdit}
                  />
                ) : null}
                {onRemove ? (
                  <img
                    src={remove_icon}
                    className="hr_modal--header__icon"
                    onClick={onRemove}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="hr_modal--body">{children}</div>
        </div>
      </ClickAwayListener>
    </div>
  )
}

export default HrModal
