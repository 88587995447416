import React from 'react';
import '../css/CalendarDays.css';
import { getDatesInMonthDisplay, getDayMonthYear, getDayOfMonth, 
         getMonth, getYear, isWeekday } from '../utils/date-utils';

const CalendarDays = (props) => {
    const calendarDate = props.calendarDate;
    const setCalendarDate = props.setCalendarDate;
    const selectDate = props.selectDate;
    const setSelectDate = props.setSelectDate;
    const {hlDays} = props

    const dates = getDatesInMonthDisplay(getMonth(calendarDate) + 1, getYear(calendarDate));

    const changeDate = (date) => {
        if (date.currentMonth) {
            setCalendarDate(date.date);
            setSelectDate(date.date);
        } 
    };

    const resultDates = dates.map((d, i) => {
        const currentMonth = d.currentMonth ? '' : 'notCurrent';
        const weekday = isWeekday(d.date) ? 'weekday' : '';
        const selected = getDayMonthYear(selectDate) === getDayMonthYear(d.date) ? 'selected' : '';
        const highlighted = () => {
          if (!d.currentMonth) return ''
          const cur_date = d.date.getDate()
          return hlDays[cur_date - 1] === 1 ? 'highlighted' : ''
        }
        return (
            <div className={`calendar__day ${currentMonth} ${weekday} ${selected} ${highlighted()}`} 
                 key={i}
                 onClick={() => changeDate(d)}>
                    {getDayOfMonth(d.date)}
            </div>
        );
    });

    return (
        <div className='calendar__days'>
            {resultDates}
        </div>
    );
};

export default CalendarDays;
