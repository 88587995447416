import React, { useContext } from 'react';
import ButtonGroup from './ButtonGroup';
import { TabContext } from '../context';
import '../css/HorMenu.css';
import LogsMenu from './LogsMenu';
import { TAB_BUTTONS_INFO } from 'utils/constants';

const HorMenu = () => {
    const {clicked} = useContext(TabContext);

    return (
        <div className='horMenu'>
            <div className='tabs-container'>
                <ButtonGroup tabs={TAB_BUTTONS_INFO}/>
            </div>
            {clicked === 1  && 
                <LogsMenu/>
            }
        </div>
    );
};

export default HorMenu;