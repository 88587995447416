// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.avatar__contour {   
    position: relative;
    border-radius: 100px;
    border: 3px solid #DCEDEC;
}

.avatar__name {
    display: flex;
    flex-direction: column;
    min-width: 167px;
    gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/css/Avatar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,SAAS;AACb","sourcesContent":[".avatar {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    cursor: pointer;\r\n}\r\n\r\n.avatar__contour {   \r\n    position: relative;\r\n    border-radius: 100px;\r\n    border: 3px solid #DCEDEC;\r\n}\r\n\r\n.avatar__name {\r\n    display: flex;\r\n    flex-direction: column;\r\n    min-width: 167px;\r\n    gap: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
