// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login {
    background: linear-gradient(172deg, #E4F1FF 0%, #FFF 100%);
    height: 100vh;
    width: 100%;
    position: fixed;
}

.login__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 560px;
    height: 640px; 
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 10px 1px rgba(189, 184, 251, 0.25); 
}

.login__img {
    width: 230px;
    height: 80px; 
    filter: invert(15%) sepia(13%) saturate(2970%) hue-rotate(189deg) brightness(98%) contrast(96%)
}

.login__img-text {
    margin-top: 11px;
}

.login__form, .form__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.login__form {
    margin-top: 53px;
}

.form__buttons {
    gap: 20px;
}

.form__buttons {
    margin-top: 37px;
}
`, "",{"version":3,"sources":["webpack://./src/css/Login.css"],"names":[],"mappings":"AAAA;IACI,0DAA0D;IAC1D,aAAa;IACb,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,sDAAsD;AAC1D;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ;AACJ;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".login {\r\n    background: linear-gradient(172deg, #E4F1FF 0%, #FFF 100%);\r\n    height: 100vh;\r\n    width: 100%;\r\n    position: fixed;\r\n}\r\n\r\n.login__container {\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    width: 560px;\r\n    height: 640px; \r\n    border-radius: 5px;\r\n    background: #FFF;\r\n    box-shadow: 0px 0px 10px 1px rgba(189, 184, 251, 0.25); \r\n}\r\n\r\n.login__img {\r\n    width: 230px;\r\n    height: 80px; \r\n    filter: invert(15%) sepia(13%) saturate(2970%) hue-rotate(189deg) brightness(98%) contrast(96%)\r\n}\r\n\r\n.login__img-text {\r\n    margin-top: 11px;\r\n}\r\n\r\n.login__form, .form__buttons {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    gap: 30px;\r\n}\r\n\r\n.login__form {\r\n    margin-top: 53px;\r\n}\r\n\r\n.form__buttons {\r\n    gap: 20px;\r\n}\r\n\r\n.form__buttons {\r\n    margin-top: 37px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
