import '../css/DepartmentCard.css'

import {fields} from 'utils/department.fields'
import {HR_EMPTY_VALUE, HR_EMPTY_VALUES} from 'utils/constants'

const show_person = person => {
  if (!person) return HR_EMPTY_VALUE
  const {
    first_name,
    family_name,
    last_name
  } = person
  return `${family_name} ${first_name} ${last_name}`
}

const show_company = company => company ? company.short_name : HR_EMPTY_VALUE

const show_field = value => value ? value : HR_EMPTY_VALUE

const DepartmentCard = ({className, department}) => {
  return (
    <div className={`hr_department-card ${className}`}>
      <div className="hr_department-card__field">
        <div className="hr_department-card__field--title">{fields.full_name}</div>
        <div className="hr_department-card__field--value">{show_field(department?.full_name)}</div>
      </div>
      <div className="hr_department-card__triple">
        <div className="hr_department-card__field">
          <div className="hr_department-card__field--title">{fields.chief_person}</div>
          <div className="hr_department-card__field--value">{show_person(department?.chief_person)}</div>
        </div>
      </div>
      <div className="hr_department-card__field">
        <div className="hr_department-card__field--title">{fields.company}</div>
        <div className="hr_department-card__field--value">{show_company(department?.company)}</div>
      </div>
      <div className="hr_department-card__field">
        <div className="hr_department-card__field--title">{fields.chief_phone}</div>
        <div className="hr_department-card__field--value">{show_field(department?.chief_phone)}</div>
      </div>
      <div className="hr_department-card__field">
        <div className="hr_department-card__field--title">{fields.chief_phone_additional}</div>
        <div className="hr_department-card__field--value">{show_field(department?.chief_phone_additional)}</div>
      </div>
      <div className="hr_department-card__field">
        <div className="hr_department-card__field--title">{fields.chief_email}</div>
        <div className="hr_department-card__field--value">{show_field(department?.chief_email)}</div>
      </div>
      <div className="hr_department-card__left_double">
        <div className="hr_department-card__field">
          <div className="hr_department-card__field--title">{fields.tasks}</div>
          <div className="hr_department-card__field--list">
            {department?.tasks?.map(task => (
              <div className="hr_department-card__field--value">{task.name}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="hr_department-card__right_double">
        <div className="hr_department-card__field">
          <div className="hr_department-card__field--title">{fields.offices}</div>
          <div className="hr_department-card__field--list">
            {department?.offices?.map(office => (
              <div className="hr_department-card__field--value">{office.full_name}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DepartmentCard
