import {useState, useEffect, useRef} from 'react'
import {enqueueSnackbar} from 'notistack'
import ClickAwayListener from 'react-click-away-listener'

import 'css/ProfileCard.css'

import avatar_empty from 'assets/avatar_empty.svg';
import avatar_add_icon from 'assets/avatar_add.svg';
import avatar_remove_icon from 'assets/avatar_remove.svg';
import avatar_update_icon from 'assets/avatar_update.svg';
import close_icon from 'assets/hr_modal_close.svg'
import little_plus_icon from 'assets/little_plus.svg'

import {avatars as api} from 'API/hr'

const EMPTY_VALUE = 'Нет данных'

const get_gender = gender => {
  if (gender === 'Male') return 'муж.'
  if (gender === 'Female') return 'жен.'
  return ''
}

const get_age = dob => {
  if (!dob) return ''
  const date = new Date(dob)
  const delta = Date.now() - date.getTime()
  const age = Math.abs(new Date(delta).getUTCFullYear() - 1970)
  const last_digit = age % 10
  let suffix = ''
  if (last_digit === 1) suffix = 'год'
  if (last_digit >= 2 && last_digit <= 4) suffix = 'года'
  if (last_digit >= 5 || last_digit === 0) suffix = 'лет'
  return ` ${age} ${suffix}`
}

const get_devices = devices => {
  if (!devices || devices.length === 0) return EMPTY_VALUE
  return (
    <>
      {devices.map((device, idx) => {
        if (idx === 0) return device.serial_number
        return (
          <>
            <>{device.serial_number}</>
            <br />
            <>{device.serial_number}</>
          </>
        )
      })}
    </>
  )
}

const lead_zero = val => val > 9 ? val.toString() : `0${val}`

const get_date = date => {
  const month = lead_zero(date.getMonth() + 1)
  const year = date.getFullYear()
  const day = lead_zero(date.getDate())
  return `${day}.${month}.${year}`
}

const get_dob = dob => {
  if (!dob) return EMPTY_VALUE
  return get_date(new Date(dob))
}

const get_random = () => Math.ceil(Math.random() * 100000)

const ProfileCard = ({profile}) => {
  const [is_avatar_exist, set_is_avatar_exist] = useState(false)
  const [is_editor, set_is_editor] = useState(false)
  const canvas_ref = useRef()
  const [is_avatar_image, set_is_avatar_image] = useState(false)
  const [random, set_random] = useState(get_random())

  const {
    first_name,
    family_name,
    last_name,
    gender,
    date_of_birth,
    devices,
    personal_number,
    position,
    office,
    clearance_category,
    phone,
    email
  } = profile

  const load_is_avatar_exist = async () => {
    try {
      const loaded_res = await api
        .is_exist(profile.id)
        .then(res => res.json())
      if (loaded_res.IsAvatarExist) {
        set_is_avatar_exist(true)
        set_random(get_random())
      } else if (loaded_res.IsAvatarAbsent) {
        set_is_avatar_exist(false)
      } else if (loaded_res.IsIdAbsent) {
        enqueueSnackbar('Нет пользователя с таким Id', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  useEffect(() => {
    load_is_avatar_exist()
  }, [])

  const avatar_url = is_avatar_exist ? `/api/v1/hr/avatars/${profile.id}?random=${random}` : avatar_empty

  const unset_avatar = async () => {
    if (!is_avatar_exist) return
    try {
      const loaded_res = await api
        .unset_image(profile.id)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_is_avatar_exist(false)
      } else if (loaded_res.IsAbsent) {
        enqueueSnackbar('Нет пользователя с таким Id', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  const avatar_add = async () => {
    set_is_avatar_image()
    set_is_editor(true)
  }

  const editor_close = () => {
    set_is_editor(false)
  }

  const image_change = e => {
    const file = e.target.files[0]
    if (!file) return
    const img = new Image()
    img.onload = () => {
      const canvas = canvas_ref.current
      canvas.width = 74;
      canvas.height = 74;
      const ctx = canvas.getContext("2d")
      const
        img_width = img.width,
        img_height = img.height
      if (img_width > img_height) {
        const sx = (img_width - img_height) / 2
        ctx.drawImage(img, sx, 0, img_height, img_height, 0, 0, 74, 74)
      } else {
        const sy = (img_height - img_width) / 2
        ctx.drawImage(img, 0, sy, img_width, img_width, 0, 0, 74, 74)
      }
      set_is_avatar_image(true)
    }
    img.src = URL.createObjectURL(file)
  }

  const save_avatar = async () => {
    if (!is_avatar_image) return
    canvas_ref.current.toBlob(async blob => {
      const formData = new FormData()
      formData.append('image', blob)
      try {
        const upload_res = await api
          .set_image(profile.id, formData)
          .then(res => res.json())
        if (upload_res.Success) {
          set_is_avatar_image(false)
          set_is_editor(false)
          set_is_avatar_exist(false)
          load_is_avatar_exist()
        } else if (upload_res.IsAbsent) {
          enqueueSnackbar('Нет пользователя с таким Id', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    })
  }

  return <>
    <div className="profile_card">
      <div className="profile_card--header">
        <div className="profile_card--avatar">
          <div className="profile_card--avatar__placeholder">
            <img src={avatar_url} />
          </div>
          {is_avatar_exist ? (
            <img
              className="profile_card--avatar__btn profile_card--avatar__remove"
              src={avatar_remove_icon}
              onClick={unset_avatar}
            />
          ) : null}
          {is_avatar_exist ? (
            <img
              className="profile_card--avatar__btn profile_card--avatar__update"
              src={avatar_update_icon}
              onClick={avatar_add}
            />
          ) : (
            <img
              className="profile_card--avatar__btn profile_card--avatar__update"
              src={avatar_add_icon}
              onClick={avatar_add}
            />
          )}
        </div>
        <div className="profile_card--header-data">
          <div className="profile_card--header-title">{first_name} {family_name}{last_name ? ` ${last_name}` : ''}</div>
          <div className="profile_card--header-details">{get_gender(gender)}{get_age(date_of_birth)}</div>
        </div>
      </div>
      <div className="profile_card--items">
        <div className="profile_card--item">
          <div className="profile_card--item_title">Оборудование</div>
          <div className="profile_card--item_value">{get_devices(devices)}</div>
        </div>
        <div className="profile_card--item">
          <div className="profile_card--item_title">Табельный номер</div>
          <div className="profile_card--item_value">{personal_number || EMPTY_VALUE}</div>
        </div>
        <div className="profile_card--item">
          <div className="profile_card--item_title">Должность</div>
          <div className="profile_card--item_value">{position?.name || EMPTY_VALUE}</div>
        </div>
        <div className="profile_card--item">
          <div className="profile_card--item_title">Отдел</div>
          <div className="profile_card--item_value">{office?.full_name || EMPTY_VALUE}</div>
        </div>
        <div className="profile_card--item">
          <div className="profile_card--item_title">Категория допуска</div>
          <div className="profile_card--item_value">{clearance_category || EMPTY_VALUE}</div>
        </div>
        <div className="profile_card--item">
          <div className="profile_card--item_title"></div>
          <div className="profile_card--item_value"></div>
        </div>
        <div className="profile_card--item">
          <div className="profile_card--item_title">Телефон (доп.)</div>
          <div className="profile_card--item_value">{phone || EMPTY_VALUE}</div>
        </div>
        <div className="profile_card--item">
          <div className="profile_card--item_title">Электронная почта</div>
          <div className="profile_card--item_value truncate">{email}</div>
        </div>
        <div className="profile_card--item">
          <div className="profile_card--item_title">Дата рождения</div>
          <div className="profile_card--item_value">{get_dob(date_of_birth)}</div>
        </div>
      </div>
    </div>
    {is_editor ? (
      <div className="profile_editor--cloak">
        <ClickAwayListener onClickAway={editor_close}>
          <div className="profile_editor">
            <div className="profile_editor--top">
              <img src={close_icon} onClick={editor_close} />
            </div>
            <div className="profile_editor--container">
              <div className="profile_editor--body">
                <canvas ref={canvas_ref}></canvas>
                {!is_avatar_image ? (
                  <div className="profile_editor--label">
                    <span>выбрать файл</span>
                    <img src={little_plus_icon} />
                  </div>
                ) : null}
                <input type="file" onChangeCapture={image_change} />
              </div>
              <div
                className="profile--button"
                onClick={save_avatar}
              >СОХРАНИТЬ</div>
            </div>
          </div>
        </ClickAwayListener>
      </div>
    ) : null}
  </>
}

export default ProfileCard
