import {useRef, useEffect} from 'react'
import * as d3 from 'd3'

import loading_img from 'assets/loading.gif'

const get_time = time => {
  const hours = lead_zero(time.getHours())
  const mins = lead_zero(time.getMinutes())
  const secs = lead_zero(time.getSeconds())
  return `${hours}:${mins}:${secs}`
}

const time_tick = time => {
  const hours = lead_zero(time.getHours())
  return `${hours} h`
}

const lead_zero = num => num > 9 ? num.toString() : `0${num}`

const get_axis_class = theme => {
  if (theme === 'dark') return 'axis-dark'
  return 'axis-light'
}

export const EcgTrend = ({points, ranges, cursor, setCursor, loading}) => {
  const main_ref = useRef()

  useEffect(() => {
    if (!points || points.length < 1440) return
    const path_stroke = points.every(p => p.value === 0) ? '#a3a3a3' : '#4BB56F'
    if (!main_ref || !main_ref.current) return
    const {width, height} = main_ref.current.getBoundingClientRect()
    const bg_color = '#1B1B1B'

    d3
      .select(main_ref.current)
      .selectAll('svg')
      .remove()
    const svg = d3
      .select(main_ref.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
    const rect = svg
      .append('rect')
      .attr('width', width - 60)
      .attr('height', height - 34)
      .attr('x', 30)
      .attr('y', 0)
      .attr('fill', bg_color)

    const x_ticks = []
    const begin_point = points[0]
    const end_point = points[points.length - 1]
    for (let t = 0; t <= 23; t++) {
      const tick = points[t * 60].time
      x_ticks.push(tick)
    }
    const x_scale = d3
      .scaleTime([begin_point.time, end_point.time], [0, width - 60])
    const x_axis = d3
      .axisBottom(x_scale)
      .tickSize(-height)
      .tickFormat(time_tick)
      .tickPadding(12)
      .tickValues(x_ticks)
    svg
      .append('g')
      .attr('class', `axis-dark`)
      .attr('transform', `translate(30,${height-34})`)
      .call(x_axis)

    const max = Math.max(...points.map(p => p.value))
    const min = Math.min(...points.map(p => p.value))
    const top = max > 0 ? max * 1.1 : max * 0.9
    const bottom = min > 0 ? min * 0.9 : min * 1.1
    const y_scale = d3.scaleLinear([bottom, top], [height-46, 12])
    const y_axis = d3
      .axisLeft(y_scale)
      .tickSize(-width + 60)
      .tickPadding(4)
      .offset(-1)
      .ticks(5)
    svg
      .append('g')
      .attr('class', `axis-dark`)
      .attr('transform', 'translate(31,0)')
      .call(y_axis)
    const y_axis_2 = d3
      .axisRight(y_scale)
      .tickSize(0)
      .tickPadding(4)
      .offset(-1)
      .ticks(5)
    svg
      .append('g')
      .attr('class', `axis-dark`)
      .attr('transform', `translate(${width-30},0)`)
      .call(y_axis_2)

    const line = d3
      .line()
      .x(d => x_scale(d.time))
      .y(d => y_scale(d.value))
    svg
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', path_stroke)
      .attr('stroke-width', 2)
      .attr('d', line(points))
      .attr('transform', `translate(${30},0)`)

    for (let range of ranges) {
      let begin = x_scale(new Date(range.timestamp)) + 30
      //if (begin > width - 30) begin = width - 30
      //let end = x_scale(range.end) + 30
      //if (end > width - 30) end = width - 30
      svg
        .append('rect')
        .attr('x', begin)
        .attr('y', 0)
        .attr('width', 3)
        .attr('height', height - 34)
        .attr('fill', '#FFD75B')
        .attr('fill-opacity', '0.3')
    }

    if (cursor) {
      const begin = x_scale(cursor) + 30
      svg
        .append('line')
        .attr('x1', begin)
        .attr('y1', 0)
        .attr('x2', begin)
        .attr('y2', height - 34)
        .attr('stroke', '#A7D5FF')
        .attr('stroke-width', 2)
    }

    svg
      .on('click', e => {
        let time = x_scale.invert(e.offsetX - 30)
        time.setMilliseconds(0)
        time.setSeconds(0)
        console.log(time)
        setCursor(time)
      })
  }, [points, ranges, cursor])

  return (
    <div className="ecg-trend" ref={main_ref}>
      {loading ? (
        <img className="ecg-trend__loader" src={loading_img} />
      ) : null}
    </div>
  )
}
