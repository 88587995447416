import React from 'react';
import {IoMdClose} from 'react-icons/io';
import '../css/FilterButton.css';

const FilterButton = ({children, onCloseButtonClicked}) => {
    return (
        <div className='filter-button__container'>
            <div className='filter-button__button'>
                {children}
            </div>
            <IoMdClose className='filter-button__close'
                       onClick={() => onCloseButtonClicked(0)}/>
        </div>
    );
};

export default FilterButton;