import React, { useRef, useContext } from 'react';
import {FiCalendar} from 'react-icons/fi';
import '../css/PeriodData.css';
import { getDayMonthYear } from '../utils/date-utils';
import Calendar from './Calendar';
import { useOutsideClick } from '../hooks/useOutsideClick';

import { TabContext } from 'context';

const PeriodData = (props) => { 
    const selectDate = props.date;
    const setSelectDate = props.setDate;

    const calendarRef = useRef(null);

    const [isActive, setIsActive] = useOutsideClick(calendarRef, false);
    const {clicked} = useContext(TabContext);

    const handleClick = () => {
        setIsActive(!isActive);
    };
    
    return (
        <div className={[props.className, 'displayPeriod__item', 'date'].join(' ')}>
            <FiCalendar color='#3D7CB6' size='20px' onClick={handleClick} cursor={'pointer'}></FiCalendar>
            <div className='item__date'>
                <span>{getDayMonthYear(selectDate)}</span>
            </div>
            <div className='absoluteContainer' ref={calendarRef}>   
                {isActive && 
                    <Calendar selectDate={selectDate} 
                              setSelectDate={setSelectDate}
                              handleClick={handleClick}
                              isEcgMonths />
                }
            </div>
        </div>
    );
};

export default PeriodData;
