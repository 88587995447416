import React, { useState } from 'react';
import HorMenu from './HorMenu';
import DisplayPeriod from './DisplayPeriod';
import Profile from './Profile';
import '../css/InfoBlock.css';

const InfoBlock = (props) => {
    return (
        <div className={props.hideBlock ? 'info__block hide' : 'info__block'}>
            <div className='info__container'>
                <div style={{display: 'flex', gap: '160px', justifyContent: 'stretch', height: '100%'}}>
                    <Profile />
                    <div style={{display: 'flex', flexDirection: 'column', flex: '1 0', gap: 20}}>
                        <HorMenu />
                        <DisplayPeriod {...props} />
                    </div>
                </div>  
            </div>           
        </div>
    );
};

export default InfoBlock;
