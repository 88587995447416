import React from 'react';

import 'css/DownArrowCell.css';

import { BiSolidDownArrow } from 'react-icons/bi';

const DownArrowCell = ({children}) => {
    return (
        <div className='down-arrow-cell'>
            <div className='text'>
                {children}
            </div>
            <BiSolidDownArrow className='arrow'/>
        </div>
    );
};

export default DownArrowCell;