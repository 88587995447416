import { COLORS } from "./constants";

export const getColorByText = (result) => {
    let color = '';
    switch (result) {
        case 'WITHIN NORMAL':
            color = COLORS.green;
            break;
        case 'Risk of a disease. Seek medical advice':
        case 'Risk of a disease':
        case 'Есть отколонения, требующие проверки':
        case 'REST REQUIRED':
            color = COLORS.yellow;
            break;
        case 'Pathologies have been identified':
        case 'Pathologies detected':
        case 'Irregularities requiring examination':
            color = COLORS.red;
            break;
        default:
            color = COLORS.gray;
            break;
    }
    return color;
}

export const getColorByValue = (result, reverse) => {
    let colors = Object.values(COLORS).slice(0, -1);
    if (reverse) colors.reverse();
    let color = colors[0];
    if (result > 30 && result <= 70)
        color = colors[1];
    else if (result > 70)
        color = colors[2];
    return color;
}

export const setColor = (data, reverse) => {
    let color;
    if (typeof data.percent !== 'undefined')
        color = getColorByValue(data.percent, reverse);
    else
        color = getColorByText(data.state);
    return color;
};