import React from 'react';

import LogHeader from 'components/LogHeader';
import Table from 'components/Table';

import { NAME_ACCESS_LOG_DEF } from 'utils/constants';

const staticDate = new Date(2023, 5, 14, 17, 36, 0);

const staticData = [
    {
        name: 'Maxim Vlasov',
        id: '2099/011',
        serial: 'MAPSYS0007',
        location: 'Office, 2nd floor',
        index: 0,
        battery: '98%',
        time: staticDate
    },
    {
        name: 'Anastasia Zayakina',
        id: '2099/012',
        serial: 'MAPSYS0008',
        location: 'Warehouse',
        index: 1,
        battery: '67%',
        time: staticDate
    },
    {
        name: 'Philipp Sidin',
        id: '2099/013',
        serial: 'MAPSYS0009',
        location: 'Workshop №1',
        index: 0,
        battery: '100%',
        time: staticDate
    },
    {
        name: 'Kirill Ulanov',
        id: '2099/014',
        serial: 'MAPSYS0006',
        location: 'Main reception',
        index: 2,
        battery: '15%',
        time: staticDate
    },
    {
        name: 'Maxim Vlasov',
        id: '2099/015',
        serial: 'MAPSYS0005',
        location: 'Garage',
        index: 1,
        battery: '24%',
        time: staticDate
    },
    {
        name: 'Anastasia Zayakina',
        id: '2099/016',
        serial: 'MAPSYS0004',
        location: 'Workshop №2',
        index: 1,
        battery: '6%',
        time: staticDate
    },
    {
        name: 'Kirill Ulanov',
        id: '2099/017',
        serial: 'MAPSYS0003',
        location: 'Accounting department',
        index: 2,
        battery: '100%',
        time: staticDate
    },
    {
        name: 'Philipp Sidin',
        id: '2099/018',
        serial: 'MAPSYS0002',
        location: 'Office, 3d floor',
        index: 0,
        battery: '80%',
        time: staticDate
    },

];

const NameAccessLog = () => {
    return (
        <div className='logs-container'>
            <LogHeader downloads>
                Access and location log
            </LogHeader>
            <Table columns={NAME_ACCESS_LOG_DEF}
                   staticData={staticData}
                   style={{height: 460}}/>
        </div>
    );
};

export default NameAccessLog;