import { getPathologyDateTime } from "./date-utils";
import { downArrowSellRender, generateRandomArray, getAccessType, severityLevelRender } from "./functions";

// temp
import vlasov_img from '../img/avatar.jpeg';
import ulanov_img from '../img/ulanov_avatar.jpg';
import dev_img from '../img/dev_avatar.jpg';

export const DELAY = 60000;

export const COLORS = {
    green: '#4BB56F',
    yellow: '#FFD75B',
    red: '#CC2A2A',
    gray: '#828282',
};

export const STROKE_COLORS = {
    green: '#00923A',
    yellow: '#FF9393',
    red: '#8D2323',
    gray: '#868686',
};

export const YEARS = [2021, 2022, 2023, 2024];

export const TAB_BUTTONS_INFO = [
    {
        label: 'Summary',
        path: '/report/summary',
        index: 0,
    },
    {
        label: 'Logs',
        path: '/report/health-log',
        subPath: '/report/access-log',
        index: 1,
        subTabInfo: [
            {
                label: 'Pathologies log',
                path: '/report/health-log',
                index: 0,
            },
            {
                label: 'Access and location log',
                path: '/report/access-log',
                index: 1,
            },
        ]
    },
    {
        label: 'Raw ECG',
        path: '/report/diagnostics',
        index: 2,
    },
    {
        label: 'Maps',
        path: '/report/maps',
        index: 3,
    },
];

export const SUBTAB_INFO = TAB_BUTTONS_INFO.at(1).subTabInfo;

export const INDICATORS_INFO = [
    {
        name: 'Cardio-vascular system',
    },
    {
        name: 'ECG interpretation\nWithin Normal',
        icons: true,
    },
    {
        name: 'Fatigue levels',
    },
    {
        name: 'Productivity',
        reverse: true,
    },
    {
        name: 'Stress levels',
    },
    {
        name: 'Heart age',
    },
    {
        name: 'Risk of pathological changes',
    },
    {
        name: 'Arrhytmias',
    },
];

export const PATHOLOGY_DEF = [
    {
        field: 'dateTime', headerName: 'TIME ', 
        valueFormatter: getPathologyDateTime, cellStyle: {whiteSpace: 'pre'}
    },
    {
        field: 'pathology', headerName: 'PATHOLOGY'
    },
    {
        field: 'severityLevel', headerName: 'CRITICALITY ', 
        cellRenderer: severityLevelRender
    },
    {
        field: 'recommendation', headerName: 'RECOMMENDATION', flex: 2
    }
];

export const ACCESS_DEF = [
    {
        field: 'dateTime', headerName: 'TIME', 
        valueFormatter: getPathologyDateTime, cellStyle: {whiteSpace: 'pre'}
    },
    {
        field: 'room', headerName: 'LOCATION'
    },
    {
        field: 'accessType', headerName: 'TYPE OF ACCESS', 
        valueFormatter: getAccessType
    },
    {
        field: 'violationAdmission', headerName: 'CLEARANCE VIOLATION', 
        flex: 2, cellRenderer: severityLevelRender
    }
];

// temp data
export const PROFILE_DATA = {
    VlasovMA: {
        name: 'Maxim',
        surname: 'Vlasov',
        age: 48,
        number: '2099/0001',
        photo: vlasov_img
    },
    UlanovKA_: {
        name: 'Kirill',
        surname: 'Ulanov',
        age: 26,
        number: '2099/0002',
        photo: ulanov_img
    },
    Dev: {
        name: 'Dev',
        surname: 'Test',
        age: 3,
        number: '2099/0000',
        photo: dev_img
    }
}

export const fakeTrendData = generateRandomArray();

export const HEALTH_LOG_TAB_INFO = [
    {
        label: 'View by Name',
        path: '/health-log/name',
        index: 0,
    },
    {
        label: 'View by Event ',
        path: '/health-log/event',
        index: 1,
    },
]

export const ACCESS_LOG_TAB_INFO = [
    {
        label: 'View by Name',
        path: '/access-log/name',
        index: 0,
    },
    {
        label: 'View by Event ',
        path: '/access-log/event',
        index: 1,
    },
]

export const HEALTH_INDICATORS_INFO = [
    {
        name: 'Employees with severe\npathologies',
    },
    {
        name: 'Employees who are\nrecommended to see a doctor',
    },
    {
        name: 'Total integrated health index\nof the department',
    },
]

export const ACCESS_INDICATORS_INFO = [
    {
        name: 'Major violations of\nthe flowcharts',
    },
    {
        name: 'Disputes',
    },
    {
        name: 'Total integrated flowchart\nexecution index',
    },
]

export const NAME_HEALTH_LOG_DEF = [
    {
        field: 'name', headerName: 'NAME'
    },
    {
        field: 'id', headerName: 'EMPLOYEE ID'
    },
    {
        field: 'serial', headerName: 'SERIAL NUMBER'
    },
    {
        field: 'pathologies', headerName: 'OBSERVED PATHOLOGIES', flex: 1.3,
        cellRenderer: downArrowSellRender
    },
    {
        field: 'index', headerName: 'INTEGRATED HEALTH INDEX',
        cellRenderer: severityLevelRender
    },
    {
        field: 'rate', headerName: 'HEART RATE'
    },
    {
        field: 'battery', headerName: 'BATTERY'
    },
    {
        field: 'time', headerName: 'TIME OF THE LAST RECEIVED DATA',
        valueFormatter: getPathologyDateTime, cellStyle: {whiteSpace: 'pre'}
    }
];

export const EVENT_HEALTH_LOG_DEF = [
    {
        field: 'time', headerName: 'TIME',
        valueFormatter: getPathologyDateTime, cellStyle: {whiteSpace: 'pre'}
    },
    {
        field: 'serial', headerName: 'SERIAL NUMBER'
    },
    {
        field: 'name', headerName: 'NAME'
    },
    {
        field: 'battery', headerName: 'BATTERY'
    },
    {
        field: 'rate', headerName: 'HEART RATE'
    },
    {
        field: 'pathologies', headerName: 'OBSERVED PATHOLOGIES', flex: 1.3,
    },
    {
        field: 'severity', headerName: 'SEVERITY',
        cellRenderer: severityLevelRender
    },
    {
        field: 'recommendation', headerName: 'RECOMMENDATION', flex: 1.3
    }          
];

export const NAME_ACCESS_LOG_DEF = [
    {
        field: 'name', headerName: 'NAME'
    },
    {
        field: 'id', headerName: 'EMPLOYEE ID'
    },
    {
        field: 'serial', headerName: 'SERIAL NUMBER'
    },
    {
        field: 'location', headerName: 'LOCATION HISTORY', flex: 1.3,
        cellRenderer: downArrowSellRender
    },
    {
        field: 'index', headerName: 'INTEGRATED FLOWCHART EXECUTION INDEX', flex: 1.3,
        cellRenderer: severityLevelRender
    },
    {
        field: 'battery', headerName: 'BATTERY'
    },
    {
        field: 'time', headerName: 'TIME OF THE LAST RECEIVED DATA',
        valueFormatter: getPathologyDateTime, cellStyle: {whiteSpace: 'pre'}
    }
];

export const EVENT_ACCESS_LOG_DEF = [
    {
        field: 'time', headerName: 'TIME',
        valueFormatter: getPathologyDateTime, cellStyle: {whiteSpace: 'pre'}
    },
    {
        field: 'name', headerName: 'NAME'
    },
    {
        field: 'id', headerName: 'EMPLOYEE ID'
    },
    {
        field: 'serial', headerName: 'SERIAL NUMBER'
    },   
    {
        field: 'battery', headerName: 'BATTERY'
    },
    {
        field: 'location', headerName: 'LOCATION'
    },
    {
        field: 'accessType', headerName: 'TYPE OF ACCESS', 
        valueFormatter: getAccessType
    },
    {
        field: 'violation', headerName: 'CLEARANCE VIOLATION', 
        cellRenderer: severityLevelRender
    }          
];

export const DIAGNOSTICS_FILTERS = [
    {
        value: 'No filters',
        lowFrequency: 0.1, 
        highFrequency: 120, 
        flag: false
    },
    {
        value: '30 Hz',
        lowFrequency: 1, 
        highFrequency: 30, 
        flag: false
    },
    {
        value: '50 Hz bandpass',
        lowFrequency: 0.05, 
        highFrequency: 120, 
        flag: true
    },
    {
        value: '80 Hz',
        lowFrequency: 0.5, 
        highFrequency: 80, 
        flag: false
    },
    {
        value: '80 Hz / 50 Hz bandpass',
        lowFrequency: 0.5, 
        highFrequency: 80, 
        flag: true
    },
    {
        value: '120 Hz',
        lowFrequency: 0.5, 
        highFrequency: 120, 
        flag: false
    },
    {
        value: '120 Hz / 50 Hz bandpass',
        lowFrequency: 0.5, 
        highFrequency: 120, 
        flag: true
    },
];

export const BUILD_CHART_EVENTS = {
    build: 0,
    cursor: 1,
    arrow: 2,
    ecg: 3
};

export const
  HR_PAGE_SIZE = 50

export const
  HR_VIEW_MODE = 'VIEW_MODE',
  HR_EDIT_MODE = 'EDIT_MODE'

export const
  HR_EMPTY_VALUE = 'No value'

export const
  INIT_STEP = 'init_step',
  CURRENT_STEP = 'current_step',
  HISTORY_STEP = 'history_step'

export const
    ECG_MARGIN_RIGHT = 30,
    ECG_MARGIN_LEFT = 30,
    ECG_MARGIN_BOTTOM = 34,
    ECG_SHIFT = 20
