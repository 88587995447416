import React from 'react';

import { Outlet, useOutletContext } from 'react-router-dom';

import Header from 'components/Header';
import LogsInfo from 'components/LogsInfo';

import { HEALTH_INDICATORS_INFO, HEALTH_LOG_TAB_INFO } from 'utils/constants';

const MainHealthLog = () => {
    const context = useOutletContext();

    return (
        <div className="layout-page">
            <Header showMenu={context.hideBlock}
                    menu={context.headerMenu}>
                Employees health log
            </Header>

            <LogsInfo tabs={HEALTH_LOG_TAB_INFO}
                      indicators={HEALTH_INDICATORS_INFO}
                      hideBlock={context.hideBlock}/>

            <div className={context.hideBlock ? 'logs-main hide' : 'logs-main'}>
                <div className='main__container'>
                    <Outlet context={context}/>
                </div>
            </div>
        </div>
    );
};

export default MainHealthLog;
