import React from 'react';
import {LuPencilLine} from 'react-icons/lu' ;                                        
import Avatar from './Avatar';
import '../css/Profile.css';

import { getProfileOwner } from '../utils/functions';

const Profile = () => {
    const avatarStyles = {
        style_avatar: {gap: 35},
        style_contour: {width: 80, height: 80},
        style_img: {width: 74, height: 74, borderRadius: 100},
        style_name: {fontSize: 24, fontWeight: 700},
        setStatus: true,
    };

    // temp
    const owner = getProfileOwner();

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <span style={{color: '#4BB56F',
                          fontWeight: 400,
                          fontSize: 12}}>Active  |  Online</span>

            <div className='profile__info'>
                <div style={{display:'flex', 
                             gap: 35}}>
                    <span>{`№ ${owner.number}`}</span>
                    <span>{`male, ${owner.age} y.o.`}</span>
                    {/* <LuPencilLine color='#717171' size={20}/> */}
                </div>

                <Avatar styles={avatarStyles}/>

                <div className='profile__info__data'>
                    <div className='profile__info__data__item'>
                        <span style={{color: '#4BB56F',
                                    fontSize: 16,
                                    fontWeight: 500}}>75</span>
                        <span>b/min</span>
                    </div>
                    <div className='profile__info__data__item'>
                        {/* <span style={{fontSize: 16,
                                      fontWeight: 500}}>23</span>
                        <span>м, Altitude</span> */}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Profile;