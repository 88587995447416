import React from 'react';
import '../css/CalendarWeekdays.css';
import { getWeekdays } from '../utils/date-utils';

const sundayWeekdays = getWeekdays();
const weekdays = [...sundayWeekdays.slice(1), sundayWeekdays[0]];

const CalendarWeekdays = () => {
    return (
        <div className='calendar__weekdays'>
            {weekdays.map((day, i) => (
                <div className={`calendar__weekday day${i}`} key={day}>
                    {day}
                </div>
            ))}
        </div>
    );
};

export default CalendarWeekdays;