import {useState, useEffect} from 'react'

export const useField = (init_value) => {
  const [value, set_value] = useState(init_value)
  const [is_error, set_is_error] = useState(false)
  const [validate_empty, set_validate_empty] = useState()
  const [validate_number, set_validate_number] = useState()
  const [validate_phone, set_validate_phone] = useState()
  const [validate_email, set_validate_email] = useState()
  const [validate_phone_opt, set_validate_phone_opt] = useState()
  const [validate_email_opt, set_validate_email_opt] = useState()
  const [validate_number_opt, set_validate_number_opt] = useState()
  const [validate_none, set_validate_none] = useState()

  useEffect(() => {
    set_validate_empty(() => () => {
      if (!value || value === '') {
        set_is_error(true)
        return true
      } else {
        set_is_error(false)
        return false
      }
    })
    set_validate_none(() => () => {
      if (!value || value === '') {
        set_is_error(true)
        return true
      } else if (value === 'None') {
        set_is_error(true)
        return true
      } else {
        set_is_error(false)
        return false
      }
    })
    set_validate_number(() => () => {
      if (!value || value === '') {
        set_is_error(true)
        return true
      } else if (!/^\d+$/g.test(value)) {
        set_is_error(true)
        return true
      } else {
        set_is_error(false)
        return false
      }
    })
    set_validate_phone(() => () => {
      const val = value.replace(/[^\d]/g, '')
      if (!val || val === '') {
        set_is_error(true)
        return true
      } else {
        set_is_error(false)
        return false
      }
    })
    set_validate_email(() => () => {
      if (!value || value === '') {
        set_is_error(true)
        return true
      } else if (!/^[^@]+@[^@]+\.[^@]+$/g.test(value)) {
        set_is_error(true)
        return true
      } else {
        set_is_error(false)
        return false
      }
    })
    set_validate_phone_opt(() => () => {
      if (!value || value === '') {
        set_is_error(false)
        return false
      } else {
        const val = value.replace(/[^\d]/g, '')
        if (!val || val === '') {
          set_is_error(true)
          return true
        } else {
          set_is_error(false)
          return false
        }
      }
    })
    set_validate_email_opt(() => () => {
      if (!value || value === '') {
        set_is_error(false)
        return false
      } else if (!/^[^@]+@[^@]+\.[^@]+$/g.test(value)) {
        set_is_error(true)
        return true
      } else {
        set_is_error(false)
        return false
      }
    })
    set_validate_number_opt(() => () => {
      if (!value || value === '') {
        set_is_error(false)
        return false
      } else if (!/^\d+$/g.test(value)) {
        set_is_error(true)
        return true
      } else {
        set_is_error(false)
        return false
      }
    })
  }, [value])

  const change = val => {
    set_value(val)
    set_is_error(false)
  }

  return {
    value,
    change,
    is_error,
    validate_empty,
    validate_none,
    validate_number,
    validate_phone,
    validate_email,
    validate_phone_opt,
    validate_email_opt,
    validate_number_opt
  }
}
