import { AgGridReact} from 'ag-grid-react';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../css/Table.css';
import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { getStartDate, getEndDate } from '../utils/date-utils';

const Table = ({columns, apiFunction, staticData, ...props}) => {
    const [gridApi, setGridApi] = useState(null);

    const context = useOutletContext();
    const startDate = context.startDate;
    const endDate = context.endDate;

    const perPage = 50;
    const model = staticData ? 'clientSide' : 'infinite';
    const gridOptions = {
        rowHeight: 55,
        rowStyle: {borderBottom: '#FFF 10px solid', background: '#E8E8E8'},
        suppressCellFocus: true,
        defaultColDef: {
            flex: 1,
            wrapText: true,
            wrapHeaderText: true,
        },
        pagination: true,
        paginationPageSize: perPage,
        cacheBlockSize: perPage,
        rowModelType: model,
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    useEffect(() => {
        if (gridApi) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / perPage;
                    gridApi.showLoadingOverlay();
                    apiFunction(
                        getStartDate(startDate).toISOString(), 
                        getEndDate(endDate).toISOString(),
                        page, perPage, gridApi, params.successCallback
                    )
                }
            }

            gridApi.setDatasource(dataSource);
        }
    }, [gridApi, startDate, endDate]);

    if (staticData) {
        return (
            <div className='ag-theme-alpine' {...props}>
                <AgGridReact
                    rowData={staticData}
                    columnDefs={columns}
                    gridOptions={gridOptions}>
                </AgGridReact>
            </div>
        )
    }

    return (
        <div className='ag-theme-alpine' {...props}>
            <AgGridReact
                columnDefs={columns}
                gridOptions={gridOptions}
                onGridReady={onGridReady}>
            </AgGridReact>
        </div>
    );
};

export default Table;