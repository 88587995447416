import {useState, useEffect} from 'react'
import { IoMdTime } from "react-icons/io";
import '../css/PeriodTime.css';

const lead_zero = val => val > 9 ? val.toString() : `0${val}`

const hs = [...Array(24).keys()].map(val => lead_zero(val))
const ms = [...Array(60).keys()].map(val => lead_zero(val))

const PeriodTime = (props) => {
  const selectDate = props.date
  const setSelectDate = props.setDate
  const [hours, set_hours] = useState('00')
  const [minutes, set_minutes] = useState('00')

  useEffect(() => {
    const date_hours = selectDate ? selectDate.getHours() : 0
    const date_minutes = selectDate ? selectDate.getMinutes() : 0
    const hours_val = lead_zero(date_hours)
    const minutes_val = lead_zero(date_minutes)
    set_hours(hours_val)
    set_minutes(minutes_val)
  }, [selectDate.getTime()])

  const on_hours_change = e => {
    const new_date = new Date(selectDate.getTime())
    new_date.setHours(parseInt(e.target.value))
    setSelectDate(new_date)
    set_hours(e.target.value)
  }

  const on_minutes_change = e => {
    const new_date = new Date(selectDate.getTime())
    new_date.setMinutes(parseInt(e.target.value))
    setSelectDate(new_date)
    set_minutes(e.target.value)
  }

  return (
    <div className={[props.className, 'Period-time'].join(' ')}>
      <IoMdTime color='#3D7CB6' size='24px'></IoMdTime>
      <select
        className="item_hours"
        value={hours}
        onChange={on_hours_change}
      >
        {hs.map(hour => (
          <option key={`hour_${hour}`} value={hour}>
            {hour}
          </option>
        ))}
      </select>
      <span>:</span>
      <select
        className="item_minutes"
        value={minutes}
        onChange={on_minutes_change}
      >
        {ms.map(minute => (
          <option key={`minute_${minute}`} value={minute}>
            {minute}
          </option>
        ))}
      </select>
    </div>
  )
}

export default PeriodTime
