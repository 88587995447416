// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logs-info {
    position: fixed;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 30px; 
    background: #FFF;
    box-shadow: 0px 9px 16px -14px #566067;
    height: 442px;
    width: calc(100vw - 380px);;
    padding-left: 105px;
    padding-top: 100px;
    transition: transform .2s linear;
}

.logs-info.hide {
    transform: translateY(-442px);
}

.logs-menu {
    display: flex;
    gap: 20px;
    width: 40%;
}

.department {
    display: flex;
    gap: 15px;
    align-items: center;
}

.department__text {
    -webkit-text-decoration: underline solid #3D3D3D;
            text-decoration: underline solid #3D3D3D;;
    font-weight: 400;
    color: #3D3D3D;
}

.indicators {
    display: flex;
    gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/css/LogsInfo.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gBAAgB;IAChB,sCAAsC;IACtC,aAAa;IACb,0BAA0B;IAC1B,mBAAmB;IACnB,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,SAAS;IACT,UAAU;AACd;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,gDAAwC;YAAxC,wCAAwC;IACxC,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,SAAS;AACb","sourcesContent":[".logs-info {\r\n    position: fixed;\r\n    z-index: 1;\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 30px; \r\n    background: #FFF;\r\n    box-shadow: 0px 9px 16px -14px #566067;\r\n    height: 442px;\r\n    width: calc(100vw - 380px);;\r\n    padding-left: 105px;\r\n    padding-top: 100px;\r\n    transition: transform .2s linear;\r\n}\r\n\r\n.logs-info.hide {\r\n    transform: translateY(-442px);\r\n}\r\n\r\n.logs-menu {\r\n    display: flex;\r\n    gap: 20px;\r\n    width: 40%;\r\n}\r\n\r\n.department {\r\n    display: flex;\r\n    gap: 15px;\r\n    align-items: center;\r\n}\r\n\r\n.department__text {\r\n    text-decoration: underline solid #3D3D3D;;\r\n    font-weight: 400;\r\n    color: #3D3D3D;\r\n}\r\n\r\n.indicators {\r\n    display: flex;\r\n    gap: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
