import '../css/PositionCard.css'

import {fields} from 'utils/position.fields'
import {HR_EMPTY_VALUE} from 'utils/constants'

const show_category = category => category ? category.name : HR_EMPTY_VALUE

const show_field = value => value ? value : HR_EMPTY_VALUE

const PositionCard = ({className, position}) => {
  return (
    <div className={`hr_position-card ${className}`}>
      <div className="hr_position-card__field">
        <div className="hr_position-card__field--title">{fields.name}</div>
        <div className="hr_position-card__field--value">{show_field(position?.name)}</div>
      </div>
      <div className="hr_position-card__field">
        <div className="hr_position-card__field--title">{fields.category}</div>
        <div className="hr_position-card__field--value">{show_category(position?.category)}</div>
      </div>
      <div className="hr_position-card__field">
        <div className="hr_position-card__field--title">{fields.id}</div>
        <div className="hr_position-card__field--value">{show_field(position?.id)}</div>
      </div>
    </div>
  )
}

export default PositionCard
