import {useState, useEffect} from 'react'
import {enqueueSnackbar} from 'notistack'

import DataService from 'API/DataService'
import {
  devices as api,
  device_types as devices_types_api
} from 'API/hr'
import {useField} from 'hooks/useField'
import {
  Field,
  Select,
  SearchField
} from 'components/HrForms'
import HrTable from '../components/HrTable'
import HrModal from 'components/HrModal'
import ProfileDialog from 'components/ProfileDialog'
import plus_icon from 'assets/hr_add_item.svg'
import eye_icon from 'assets/profile_eye.svg'
import unlink_icon from 'assets/profile_unlink.svg'
import remove_icon from 'assets/profile_remove.svg'

const fields = {
  serial_number: 'Серийный номер устройства',
  device_type: 'Тип носимого устройства',
  pin_code: 'Пин-код прибора'
}

const main = 'serial_number'

const table = ['device_type', 'pin_code']

const process_device = model => {
  const {device_type} = model
  const res = {...model}
  res.device_type = res.device_type.name
  res.pin_code = '1234'
  return res
}

const process_empty_device = device => ({
  id: device.id,
  name: device.serial_number
})

const ProfileDevices = ({profile, onReload}) => {
  const [models, set_models] = useState()
  const [is_add, set_is_add] = useState(false)
  const [device_types, set_device_types] = useState([{id: 'None', name: 'N/A'}])
  const device_type = useField()
  const [empty_devices, set_empty_devices] = useState([])
  const [full_empty_devices, set_full_empty_devices] = useState([])
  const selected_device = useField()
  const pin_code = useField('')
  const [view_set, set_view_set] = useState(new Set())
  const [unlink_device, set_unlink_device] = useState()
  const [remove_device, set_remove_device] = useState()

  const load_devices = async () => {
    try {
      const loaded_res = await api
        .get_all_by_person_id(profile.id)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_models(loaded_res.Success.map(device => process_device(device)))
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  const load_device_types = async () => {
    try {
      const loaded_res = await devices_types_api
        .get_all_by_person(profile.id)
        .then(res => res.json())
      if (loaded_res.Success) {
        const values = [
          {id: 'None', name: 'N/A'},
          ...loaded_res.Success
        ]
        set_device_types(values)
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  const load_empty_devices = async query => {
    if (!device_type ||
        !device_type.value ||
        device_type.value.id === 'None') return
    const args = {
      page: {page: 0, page_size: 5},
      order: {column: null, direction: null},
      query: query || ''
    }
    try {
      const loaded_res = await api
        .get_all_empty_with_type(device_type.value.id, args)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_full_empty_devices(loaded_res.Success.models)
        set_empty_devices(loaded_res.Success.models.map(model => process_empty_device(model)))
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }

  }

  useEffect(() => {
    if (!device_type.value) return
    if (device_type.value.id === 'None') {
      selected_device.change()
      set_empty_devices([])
      return
    }
    load_empty_devices()
  }, [device_type.value])

  useEffect(() => {
    if (!is_add) return
    device_type.change()
    set_empty_devices([])
    set_full_empty_devices([])
    selected_device.change()
    pin_code.change('')
  }, [is_add])

  useEffect(() => {
    load_devices()
  }, [])

  const on_select = () => {}

  const on_add = async () => {
    await load_device_types()
    set_is_add(true)
  }

  const on_cancel_add_device = () => set_is_add(false)

  const on_select_device_type = value => {
    if (!value) return
    device_type.change(value)
  }

  const on_save = async () => {
    const errors = []
    const none_res = 
    errors.push(device_type.validate_none())
    errors.push(selected_device.validate_empty())
    errors.push(pin_code.validate_empty())
    if (errors.some(err => err)) return
    const full_device = full_empty_devices
      .find(device => device.id == selected_device.value.id)
    full_device.device_type = full_device.device_type.id
    full_device.user_id = profile.id
    try {
      await DataService.deviceLink(full_device.id)
      enqueueSnackbar('Привязка устройства прошла успешно', {variant: 'success'})
      set_is_add(false)
      load_devices()
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  const on_view = id => () => set_view_set(set => {
    const next_set = new Set(set)
    if (next_set.has(id))
      next_set.delete(id)
    else 
      next_set.add(id)
    console.log(next_set)
    return next_set
  })

  const on_unlink = device => () => set_unlink_device(device)

  const on_remove = device => () => set_remove_device(device)

  const on_unlink_aprove = async () => {
    try {
      await DataService.deviceUnlink(unlink_device.id)
      load_devices()
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
    set_unlink_device()
  }

  const on_remove_aprove = async () => {
    try {
      await DataService.deviceUnlink(unlink_device.id)
      load_devices()
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
    set_remove_device()
  }

  return (
    <>
      <div className="profile_container devices">
        <div className="profile__devices--tools" onClick={on_add}>
          <img src={plus_icon} className="profile__devices--tool__icon" />
          <div className="profile__devices--tool__title">Добавить новое устройство</div>
        </div>
        {models && models.length > 0 ? (
          <>
            <div className={`hr_table--first-header profile--devices`}>{fields[main]}</div>
            <div className={`hr_table profile--devices`}>
              <table className="hr_table--root">
                <thead>
                  <tr>
                    <th>{fields[main]}</th>
                    <th>{fields[table[0]]}</th>
                    <th>{fields[table[1]]}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {models.map(model => (
                    <tr onClick={() => on_select(model.id)}>
                      <th>{model[main]}</th>
                      <td>{model[table[0]]}</td>
                      <td className="with__icon">
                        <div>
                          {view_set.has(model.id) ? model[table[1]] : "****"}
                          <img src={eye_icon} onClick={on_view(model.id)} />
                        </div>
                      </td>
                      <td className="with__icon">
                        <div>
                          <img src={unlink_icon} onClick={on_unlink(model)} />
                          <img src={remove_icon} onClick={on_remove(model)} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="hr_page--empty">Нет ни одного прибора</div>
        )}
      </div>
      {is_add ? (
        <HrModal
          title="Добавление устройства"
          onClose={on_cancel_add_device}
        >
          <div className="hr_dialog hr_dialog--profile_devices">
            <div className="hr_dialog--profile_devices__form">
              <div className="hr_dialog--profile_devices__row">
                <div className="hr_dialog--profile_devices__title">Тип носимого устройства</div>
                <Select
                  className="hr_dialog--profile_devices__value"
                  value={device_type.value}
                  values={device_types}
                  isError={device_type.is_error}
                  onChange={on_select_device_type}
                  emptyValue={"N/A"}
                />
              </div>
              <div className="hr_dialog--profile_devices__row">
                <div className="hr_dialog--profile_devices__title">Серийный номер устройства</div>
                <SearchField
                  className="hr_dialog--profile_devices__value"
                  value={selected_device.value}
                  emptyValue
                  values={empty_devices}
                  isError={selected_device.is_error}
                  onSearch={load_empty_devices}
                  onChange={val => selected_device.change(val)}
                />
              </div>
              <div className="hr_dialog--profile_devices__row">
                <div className="hr_dialog--profile_devices__title">Пин-код прибора</div>
                <Field
                  className="hr_dialog--profile_devices__value"
                  value={pin_code.value}
                  isError={pin_code.is_error}
                  onChange={e => pin_code.change(e.target.value)}
                />
              </div>
            </div>
            <div className="hr_dialog--buttons">
              <button className="hr_dialog--button success" onClick={on_save}>ДОБАВИТЬ</button>
              <button className="hr_dialog--button" onClick={on_cancel_add_device}>ОТМЕНА</button>
            </div>
          </div>
        </HrModal>
      ) : null}
      {unlink_device ? (
        <ProfileDialog
          text="Are you sure you want to unbundle the wearable equipment?"
          tool="unlink"
          onClose={() => set_unlink_device(false)}
          onAprove={on_unlink_aprove}
          onCancel={() => set_unlink_device(false)}
        />
      ) : null}
      {remove_device ? (
        <ProfileDialog
          text="Are you sure you want to remove the wearable equipment?"
          tool="remove"
          onClose={() => set_remove_device(false)}
          onAprove={on_remove_aprove}
          onCancel={() => set_remove_device(false)}
        />
      ) : null}
    </>
  )

}

export default ProfileDevices
