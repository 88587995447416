import React, { useRef } from 'react';
import {IoMdClose} from 'react-icons/io';
import '../css/CalendarHeader.css';
import { getMonthsFull, getFirstUpperCase, getMonth, getYear, getSpecificDate } from '../utils/date-utils';
import { YEARS } from '../utils/constants';

const current_year = parseInt(new Date().getFullYear())
const FULL_YEARS = Array.from(Array(110).keys()).map(year => current_year - year)

const CalendarHeader = (props) => {
    const calendarDate = props.calendarDate;
    const handleClick = props.handleClick;
    const setCalendarDate = props.setCalendarDate;
    const fullYears = props.fullYears;

    const monthSelect = useRef(null);
    const yearSelect  = useRef(null);

    const months = getMonthsFull().map((m) => (getFirstUpperCase(m)));

    const handleChange = () => {
        const month = +monthSelect.current.value;
        const year = yearSelect.current.value;
        setCalendarDate(getSpecificDate(month + 1, 1, year));
    };

    return (
        <div className='calendarHeader'>
            <div className='calendarHeader__selects'>
                <select name='months' 
                        value={getMonth(calendarDate)} 
                        className='customSelect'
                        onChange={handleChange}
                        ref={monthSelect}>
                    {months.map((month, i) => (
                        <option key={month} value={i}>
                            {month}
                        </option>
                    ))}
                </select>
                <select name='years' 
                        value={getYear(calendarDate)} 
                        className='customSelect'
                        onChange={handleChange}
                        ref={yearSelect}>
                    {(fullYears ? FULL_YEARS : YEARS).map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </div>
            <IoMdClose color='#1C5182' size={20} onClick={handleClick} cursor={'pointer'}/>
        </div>
    );
};

export default CalendarHeader;
