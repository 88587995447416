import React from 'react';
import chart_icon from '../img/chart.svg' ;
import send_icon from '../img/send.svg';
import download_icon from '../img/download.svg';
import { setColor } from '../utils/color';
import '../css/Indicator.css';
import Battery from './Battery';

const Indicator = (props) => {
    let info = props.info;
    let data = props.data;

    const setValue = () => {
        let result;
        
        if (typeof data.startAge !== 'undefined' && typeof data.endAge !== 'undefined') {
            // temp
            if (localStorage.getItem('login') === 'UlanovKA_')
                result = '24-32';
            else
                result = `${data.startAge}-${data.endAge}`;
        }
        if (typeof data.percent !== 'undefined')
            result = `${data.percent}%`;
        return result;
    };

    const color = setColor(data, info.reverse);

    return (
        <div className='indicator'>
            <div className='indicator__container'>
                <div className='indicator__text'>
                    <span style={{paddingRight: 10, whiteSpace: 'pre'}}>{info.name}</span>
                    <div style={{color: color}} className='indicator__data'>
                        {(typeof data.startAge !== 'undefined' || typeof data.percent !== 'undefined') && (
                            <div className='indicator__data__values'>
                                {info.battery && (
                                    <Battery value={data.percent}
                                             color={color}/>
                                )}
                                <span>{setValue()}</span>
                            </div>
                        )}
                        {data.state && (
                            <span className={typeof data.percent !== 'undefined' || 
                                             typeof data.startAge !== 'undefined' ? 
                                             'indicator__data__text end' :
                                             'indicator__data__text'}>
                                {data.state}
                            </span>
                        )}                          
                    </div>                  
                </div>
                {info.icons && (
                    <div className='indicator__icons'>
                        <img src={chart_icon} alt='chart_icon' width={22} height={22}/>
                        <img src={send_icon} alt='send_icon' width={22} height={22}/>
                        <img src={download_icon} alt='download_icon' width={22} height={22}/>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Indicator;