// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile__info {
    display: flex;
    flex-direction: column;
    gap: 12px;   
    font-size: 12px;
    font-weight: 400;
}

.profile__info__data {
    display: flex;
    align-items: center; 
    gap: 40px;
}

.profile__info__data__item {
    display: flex;
    align-items: center; 
    gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/css/Profile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".profile__info {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 12px;   \r\n    font-size: 12px;\r\n    font-weight: 400;\r\n}\r\n\r\n.profile__info__data {\r\n    display: flex;\r\n    align-items: center; \r\n    gap: 40px;\r\n}\r\n\r\n.profile__info__data__item {\r\n    display: flex;\r\n    align-items: center; \r\n    gap: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
