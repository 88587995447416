// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hr_form.person_form.block-one {
  margin-top: 40px;
  grid-template-columns: 180px 280px 80px 250px 100px 290px;
}

.hr_form.person_form.block-two {
  margin-top: 30px;
  grid-template-columns: 180px 110px 60px 110px 120px 600px;
}

.hr_form.person_form.block-three {
  margin-top: 30px;
  grid-template-columns: 180px 110px 220px 730px;
}

.hr_form.person_form.block-four {
  margin-top: 30px;
  grid-template-columns: 180px 130px 40px 90px 170px 150px 200px 160px;
}

.hr_form.person_form.block-five {
  margin-top: 30px;
  grid-template-columns: 180px 320px 180px 100px 150px 250px;
}

.hr_form.person_form.block-six {
  margin-top: 30px;
  grid-template-columns: 180px 190px 330px 540px;
}

.hr_form.line {
  margin-top: 20px;
}

.hr_form.person_form.block-seven {
  margin-top: 30px;
  grid-template-columns: 310px 1fr;
}

.hr_form.person_form.block-four .save_button {
  width: 308px;
}

.hr_form.person_form .hr_form--select_icon {
  margin-left: 0;
}

.hr_form.person_form.block-eight {
  margin-top: 40px;
  grid-template-columns: 310px 1fr;
}

`, "",{"version":3,"sources":["webpack://./src/css/PersonForm.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,yDAAyD;AAC3D;;AAEA;EACE,gBAAgB;EAChB,yDAAyD;AAC3D;;AAEA;EACE,gBAAgB;EAChB,8CAA8C;AAChD;;AAEA;EACE,gBAAgB;EAChB,oEAAoE;AACtE;;AAEA;EACE,gBAAgB;EAChB,0DAA0D;AAC5D;;AAEA;EACE,gBAAgB;EAChB,8CAA8C;AAChD;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gCAAgC;AAClC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,gCAAgC;AAClC","sourcesContent":[".hr_form.person_form.block-one {\r\n  margin-top: 40px;\r\n  grid-template-columns: 180px 280px 80px 250px 100px 290px;\r\n}\r\n\r\n.hr_form.person_form.block-two {\r\n  margin-top: 30px;\r\n  grid-template-columns: 180px 110px 60px 110px 120px 600px;\r\n}\r\n\r\n.hr_form.person_form.block-three {\r\n  margin-top: 30px;\r\n  grid-template-columns: 180px 110px 220px 730px;\r\n}\r\n\r\n.hr_form.person_form.block-four {\r\n  margin-top: 30px;\r\n  grid-template-columns: 180px 130px 40px 90px 170px 150px 200px 160px;\r\n}\r\n\r\n.hr_form.person_form.block-five {\r\n  margin-top: 30px;\r\n  grid-template-columns: 180px 320px 180px 100px 150px 250px;\r\n}\r\n\r\n.hr_form.person_form.block-six {\r\n  margin-top: 30px;\r\n  grid-template-columns: 180px 190px 330px 540px;\r\n}\r\n\r\n.hr_form.line {\r\n  margin-top: 20px;\r\n}\r\n\r\n.hr_form.person_form.block-seven {\r\n  margin-top: 30px;\r\n  grid-template-columns: 310px 1fr;\r\n}\r\n\r\n.hr_form.person_form.block-four .save_button {\r\n  width: 308px;\r\n}\r\n\r\n.hr_form.person_form .hr_form--select_icon {\r\n  margin-left: 0;\r\n}\r\n\r\n.hr_form.person_form.block-eight {\r\n  margin-top: 40px;\r\n  grid-template-columns: 310px 1fr;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
