import DownArrowCell from "components/DownArrowCell";
import Status from "../components/Status"
import { ACCESS_LOG_TAB_INFO, COLORS, HEALTH_LOG_TAB_INFO, STROKE_COLORS, TAB_BUTTONS_INFO } from "./constants"
import { PROFILE_DATA } from 'utils/constants';

export const severityLevelRender = (level) => {
    const colors = Object.values(COLORS);
    const currentColor = colors.at(level.value);
    const strokeColors =  Object.values(STROKE_COLORS);
    const currentStrokeColor = strokeColors.at(level.value);
    return <Status addStyles={{color: currentColor, 
                               stroke: currentStrokeColor, 
                               strokeWidth: 0.5}}/>;
}

export const getAccessType = (accessType) => {
    let result = 'Entry';
    if (accessType.value)
        result = 'Exit';
    return result;
}

export const downArrowSellRender = (pathology) => {
    return <DownArrowCell>{pathology.value}</DownArrowCell>
}

// temp func
export const getProfileOwner = () => {
    const login = localStorage.getItem('login');
    if (login in PROFILE_DATA)
        return PROFILE_DATA[login]
    return PROFILE_DATA.Dev;
};

export const generateRandomArray = () => {
    var randomArray = [];

    for (var i = 0; i < 1440; i++) {
      var randomNumber = Math.floor(Math.random() * (130 - 70 + 1)) + 70;
      randomArray.push(randomNumber);
    }

    return randomArray;
};

export const getTabInfo = (pathname) => {
    let tabs;
    if (pathname.includes('report'))
        tabs = TAB_BUTTONS_INFO;
    else if (pathname.includes('health-log'))
        tabs = HEALTH_LOG_TAB_INFO;
    else
        tabs = ACCESS_LOG_TAB_INFO;

    const tab = tabs.find(b => b.path === pathname || b.subPath === pathname);
    return tab;
};

export const setTabButton = (pathname) => {
    let result = {
        tabIndex: 0,
        diagnostics: false
    };
    const tab = getTabInfo(pathname);
    if (tab)
        result.tabIndex = tab.index;
    else if (pathname.includes('diagnostics')) {
        result.tabIndex = 2;
        result.diagnostics = true;
    }
    return result;
};

export const getHeaderMenu = (pathname) => {
    let result = [];
    const tab = getTabInfo(pathname);
    if (tab) {
        if (tab.subPath) {
            result.push(tab.subTabInfo.find(b => b.path === pathname));
        } else {
            result.push(tab);
        }
    } else if (pathname.includes('diagnostics')) {
        result = [
            {
                label: 'Raw ECG',
                path: '/report/ecg'
            },
            {
                label: 'Diagnostics',
                path: '/report/diagnostics'
            }
        ];
    }
    return result;
};

export const getEmailMessage = (owner) => {
    const message =
`Hello!
This is a system notification.
The user ${owner.name} ${owner.surname} has shared his data with you.
Have a nice day!

This is a system message. You will not be able to reply to this message.`;
    return message;
}

export const lead_zero = value => value > 9 ? value : `0${value}`
