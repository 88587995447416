import {useEffect} from 'react'
import {enqueueSnackbar} from 'notistack'

import DataService from 'API/DataService'
import {useField} from 'hooks/useField'
import {Field} from 'components/HrForms'

const ProfileAuth = () => {
  const login = useField('')
  const password = useField('')

  useEffect(() => {
    login.change(localStorage.getItem('login'))
  }, [])

  const on_save = async () => {
    const errors = []
    errors.push(login.validate_empty())
    errors.push(password.validate_empty())
    if (errors.some(err => err)) return
    try {
      const save_res = await DataService.updateAuth({
        login: login.value,
        password: password.value
      })
      login.change(localStorage.setItem('login', login.value))
      enqueueSnackbar('Аутентификация успешно обновлена', {variant: 'success'})
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  return (
    <div className="profile_container">
      <div className="profile_personal__one_column--auth">
        <div className="profile_title">Логин</div>
        <Field
          value={login.value}
          isError={login.is_error}
          onChange={e => login.change(e.target.value)}
        />
        <div className="profile_title">Пароль</div>
        <Field
          value={password.value}
          isError={password.is_error}
          onChange={e => password.change(e.target.value)}
        />
      </div>
      <div className="profile__footer">
        <button className="profile--button" onClick={on_save}>СОХРАНИТЬ </button>
      </div>
    </div>
  )
}

export default ProfileAuth
