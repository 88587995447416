// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hr_form.device_form.block-one {
  margin-top: 40px;
  grid-template-columns: 180px 260px 240px 230px 90px 180px;
}

.hr_form.device_form.block-one .hr_form--title.date_of_issue {
  justify-self: end;
}

.hr_form.device_form.block-two {
  margin-top: 30px;
  grid-template-columns: 180px 260px 240px 230px 1fr;
}

.hr_form.device_form.block-three {
  margin-top: 30px;
  grid-template-columns: 180px 260px 240px 1fr;
}

.hr_form.line {
  margin-top: 20px;
}

.hr_form.device_form.block-four {
  margin-top: 30px;
  grid-template-columns: 310px 1fr;
}

.hr_form.device_form.block-four .save_button {
  width: 308px;
}
`, "",{"version":3,"sources":["webpack://./src/css/DeviceForm.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,yDAAyD;AAC3D;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,kDAAkD;AACpD;;AAEA;EACE,gBAAgB;EAChB,4CAA4C;AAC9C;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gCAAgC;AAClC;;AAEA;EACE,YAAY;AACd","sourcesContent":[".hr_form.device_form.block-one {\r\n  margin-top: 40px;\r\n  grid-template-columns: 180px 260px 240px 230px 90px 180px;\r\n}\r\n\r\n.hr_form.device_form.block-one .hr_form--title.date_of_issue {\r\n  justify-self: end;\r\n}\r\n\r\n.hr_form.device_form.block-two {\r\n  margin-top: 30px;\r\n  grid-template-columns: 180px 260px 240px 230px 1fr;\r\n}\r\n\r\n.hr_form.device_form.block-three {\r\n  margin-top: 30px;\r\n  grid-template-columns: 180px 260px 240px 1fr;\r\n}\r\n\r\n.hr_form.line {\r\n  margin-top: 20px;\r\n}\r\n\r\n.hr_form.device_form.block-four {\r\n  margin-top: 30px;\r\n  grid-template-columns: 310px 1fr;\r\n}\r\n\r\n.hr_form.device_form.block-four .save_button {\r\n  width: 308px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
