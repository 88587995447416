// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar__days {
    display: grid;
    grid-template-columns: repeat(7, minmax(auto, 1fr));
    grid-template-rows: repeat(5, minmax(auto, 1fr));
}

.calendar__day {
    height: 24px;
    width: 24px;
    display: flex;
    justify-self: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.calendar__day.weekday {
    color: #17A1FA;
}

.calendar__day.notCurrent {
    color: #6161614D;
    cursor: default;
}

.calendar__day.selected {
    border-radius: 4px;
    border: 1px solid #1270B0;
}

.calendar__day.highlighted {
    border-radius: 4px;
    background: rgba(18, 112, 176, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/css/CalendarDays.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mDAAmD;IACnD,gDAAgD;AACpD;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,oBAAoB;IACpB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,mCAAmC;AACvC","sourcesContent":[".calendar__days {\r\n    display: grid;\r\n    grid-template-columns: repeat(7, minmax(auto, 1fr));\r\n    grid-template-rows: repeat(5, minmax(auto, 1fr));\r\n}\r\n\r\n.calendar__day {\r\n    height: 24px;\r\n    width: 24px;\r\n    display: flex;\r\n    justify-self: center;\r\n    justify-content: center;\r\n    align-items: center;\r\n    cursor: pointer;\r\n}\r\n\r\n.calendar__day.weekday {\r\n    color: #17A1FA;\r\n}\r\n\r\n.calendar__day.notCurrent {\r\n    color: #6161614D;\r\n    cursor: default;\r\n}\r\n\r\n.calendar__day.selected {\r\n    border-radius: 4px;\r\n    border: 1px solid #1270B0;\r\n}\r\n\r\n.calendar__day.highlighted {\r\n    border-radius: 4px;\r\n    background: rgba(18, 112, 176, 0.2);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
