import React, { useState, useContext, useEffect } from 'react';
import Header from "../components/Header";
import InfoBlock from "../components/InfoBlock";
import { Outlet, useOutletContext } from 'react-router-dom';
import { getToday } from "../utils/date-utils";
import {BASE_URL} from 'API'
import { TabContext } from 'context'
import { getProfileOwner, lead_zero } from '../utils/functions';
import { BUILD_CHART_EVENTS } from 'utils/constants';

const ReportingData = () => {
    const [chartDate, setChartDate] = useState()
    const [chartShift, setChartShift] = useState(0)
    const {clicked} = useContext(TabContext);
    const {
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      timeDate,
      setTimeDate,
      isModalActive,
      setIsModalActive,
      hideBlock,
      setHideBlock,
      headerMenu,
      isDiagnostics
    } = useOutletContext();
    const owner = getProfileOwner();

    useEffect(() => {
      if (clicked === null) return
      if (clicked === 2) {
        setStartDate(getToday())
        setTimeDate(new Date())
        setChartDate({
          date: new Date(),
          event: BUILD_CHART_EVENTS.build
        })
        setChartShift(0)
      }
    }, [clicked])

    const on_build_chart = () => {
      const date = new Date(startDate.getTime())
      date.setHours(timeDate.getHours(), timeDate.getMinutes(), 0, 0)
      setChartDate({
        date: date,
        event: BUILD_CHART_EVENTS.build
      })
      setChartShift(0)
    }

    const on_download_pdf = async () => {
      const date = new Date(startDate.getTime())
      date.setHours(timeDate.getHours(), timeDate.getMinutes(), 0, 0)
      let minutes = Math.floor((new Date().getTime() - date.getTime()) / 1000 / 60)
      if (minutes <= 0) {
        alert("Wrong date and time")
        return
      } else if (minutes > 10) {
        minutes = 10
      }
      const offset = startDate.getTimezoneOffset()
      const is_plus = offset < 0
      const iso_hh = Math.floor(Math.abs(offset) / 60)
      const iso_mm = Math.floor(Math.abs(offset) % 60)
      const iso_date = new Date(date.getTime() - offset * 1000 * 60)
      const tz = `${is_plus ? '+' : '-'}${lead_zero(iso_hh)}:${lead_zero(iso_mm)}`
      const a = document.createElement("a")
      document.body.appendChild(a)
      a.style = "display: none";
      const res = await fetch(`${BASE_URL}EcgData/GetEcgPdf/${iso_date.toISOString().replace('Z', '')}${tz}/${minutes}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(response => response.blob())
      const hours = lead_zero(date.getHours())
      const mins = lead_zero(date.getMinutes())
      const day = lead_zero(date.getDate())
      const month = lead_zero(date.getMonth() + 1)
      const year = date.getFullYear()
      a.download = `${owner.name}_${owner.surname}_${hours}_${mins}T${day}_${month}_${year}.pdf`
      a.href = URL.createObjectURL(res)
      a.setAttribute('target', '_blank')
      a.click()
    }

    const on_change = value => {
      value.setSeconds(0)
      value.setMilliseconds(0)
      setStartDate(value)
    }

    return (
        <div className="layout-page">
            <Header showMenu={hideBlock}
                    menu={headerMenu}
                    startDate={startDate}
                    setStartDate={on_change}
                    timeDate={timeDate}
                    setTimeDate={setTimeDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    buildChart={on_build_chart}
                    downloadPdf={on_download_pdf}
                    setIsModalActive={setIsModalActive}
                    isDiagnostics={isDiagnostics}>
                Employee profile
            </Header>

            <InfoBlock startDate={startDate}
                       setStartDate={on_change}
                       timeDate={timeDate}
                       setTimeDate={setTimeDate}
                       endDate={endDate}
                       setEndDate={setEndDate}
                       buildChart={on_build_chart}
                       downloadPdf={on_download_pdf}
                       isModalActive={isModalActive}
                       setIsModalActive={setIsModalActive}
                       hideBlock={hideBlock}
                       isDiagnostics={isDiagnostics}
                       setChartDate={setChartDate}
            />

             <div className={hideBlock ? 'main hide' : 'main'}>
                <div className='main__container'>
                    <Outlet context={{chartDate,
                                      setChartDate,
                                      startDate,
                                      setStartDate,
                                      endDate,
                                      hideBlock,
                                      setHideBlock,
                                      timeDate,
                                      setTimeDate,
                                      chartShift,
                                      setChartShift}}/>
                </div>
             </div>
        </div>
    );
};

export default ReportingData;
