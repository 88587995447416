import React, { useContext, useState } from 'react';
import menu_img from '../img/2099_menu.png';
import '../css/Login.css';
import Button from '../components/Button';
import DataService from 'API/DataService';
import { AuthContext } from 'context';
import Input from 'components/Input';

const Login = () => {
    const {setIsAuth} = useContext(AuthContext);

    const [login, setLogin] = useState(''); 
    const [password, setPassword] = useState('');
    const [placeholder, setPlaceholder] = useState('');

    const onLogin = async () => {
        try {
            const response = await DataService.login(login, password);
            localStorage.setItem('token', response);

            // temporarily
            localStorage.setItem('login', login);
            
            setIsAuth(true);
        } catch (e) {
            const status = e.response.status;
            if (status === 401 || status === 404)
                setPassword('');
                setLogin('');
                setPlaceholder('INVALID DATA');
        }
    }

    const onRegistration = async () => {
        try {
            const response = await DataService.registration(login, password);
            localStorage.setItem('token', response);
            setIsAuth(true);
        } catch (e) {
            const status = e.response.status;
            if (status === 409)
                setPassword('');
                setLogin('');
                setPlaceholder('INVALID DATA');
        }
    }

    return (
        <div className='login'>
            <div className='login__container'>
                <img className='login__img' src={menu_img} alt='company_img' />
                <p className='login__img-text'>MAP SYSTEM</p>
                <div className='login__form' >
                    <Input label='LOGIN'
                           type='text'
                           onChange={e => {
                            setLogin(e.target.value);
                            setPlaceholder('');
                           }}
                           value={login}
                           placeholder={placeholder}/>
                    <Input label='PASSWORD'
                           type='password'
                           onChange={e => {
                            setPassword(e.target.value);
                            setPlaceholder('');
                           }}
                           value={password}
                           placeholder={placeholder}/>
                    <div className='form__buttons'>
                        <Button className='button blue_btn' 
                                onClick={onLogin}>
                            LOG IN
                        </Button>
                        <Button className='button white_btn'
                                onClick={onRegistration}>
                            REGISTRATION
                        </Button>
                    </div>
                </div>
            </div>
            
        </div>   
    );
};

export default Login;