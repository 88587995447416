// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.displayPeriod__item.date {
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
}

.item__date {
    font-size: 14px;
    font-weight: 400;
    padding: 6px 6px 5px 6px;
    border-radius: 5px;
    background: #D0E9FF;
}

.item__date span {
    color: #1C5182;
}

.absoluteContainer {
    position: absolute;
    top: 35px;
}
`, "",{"version":3,"sources":["webpack://./src/css/PeriodData.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,wBAAwB;IACxB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,SAAS;AACb","sourcesContent":[".displayPeriod__item.date {\r\n    position: relative;\r\n    display: flex;\r\n    gap: 10px;\r\n    align-items: center;\r\n}\r\n\r\n.item__date {\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n    padding: 6px 6px 5px 6px;\r\n    border-radius: 5px;\r\n    background: #D0E9FF;\r\n}\r\n\r\n.item__date span {\r\n    color: #1C5182;\r\n}\r\n\r\n.absoluteContainer {\r\n    position: absolute;\r\n    top: 35px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
