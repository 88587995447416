import {useRef, useEffect, useState} from 'react'
import * as d3 from 'd3'

import { ECG_MARGIN_RIGHT, ECG_MARGIN_LEFT, 
         ECG_MARGIN_BOTTOM, ECG_SHIFT } from 'utils/constants'
import { getDayMonthFullYear } from 'utils/date-utils'

const get_time = time => {
  const hours = lead_zero(time.getHours())
  const mins = lead_zero(time.getMinutes())
  const secs = lead_zero(time.getSeconds())
  return `${hours}:${mins}:${secs}`
}

const lead_zero = num => num > 9 ? num.toString() : `0${num}`

const get_step = scale => {
  if (scale === 0) return 200
  if (scale === 1) return 500
  if (scale === 2) return 1000
  if (scale === 3) return 2000
  if (scale === 4) return 3000
  return 0
}

const get_axis_class = theme => {
  if (theme === 'dark') return 'axis-dark'
  return 'axis-light'
}

export const EcgChannel = ({points, ranges, isTimeAxis, scale, ch, isolineColor}) => {
  const [width, setWidth] = useState(0)

  const
    main_ref = useRef(),
    tooltip_ref = useRef(),
    intervals_r_r_ref = useRef(),
    intervals_p_q_ref = useRef(),
    intervals_q_t_ref = useRef(),
    intervals_s_t_ref = useRef(),
    segments_p_q_ref = useRef(),
    waves_p_ref = useRef(),
    waves_qrs_ref = useRef(),
    waves_t_ref = useRef()

  const resizeHandler = () => {
    const width = main_ref.current.getBoundingClientRect().width || 0
    setWidth(width)
  }

  useEffect(() => {
    window.addEventListener("resize", resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener("resize", resizeHandler)
    }
  }, [])

  useEffect(() => {
    if (!points || points.length < 144) return
    if (!main_ref || !main_ref.current || !width) return

    const height = main_ref.current.getBoundingClientRect().height

    const bg_color = '#1B1B1B'

    d3
      .select(main_ref.current)
      .selectAll('svg')
      .remove()
    const svg = d3
      .select(main_ref.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
    const rect = svg
      .append('rect')
      .attr('width', width - ECG_MARGIN_RIGHT  - ECG_MARGIN_LEFT)
      .attr('height', isTimeAxis ? height - ECG_MARGIN_BOTTOM : height)
      .attr('x', ECG_MARGIN_LEFT)
      .attr('y', 0)
      .attr('fill', bg_color)

    const x_ticks = []
    const x_step = get_step(scale)
    const begin_point = points[0]
    const end_point = points[points.length - 1]
    for (let t = 0; t <= 9; t++) {
      const tick = new Date(begin_point.time.getTime() + t * x_step)
      x_ticks.push(tick)
    }
    const x_scale = d3
      .scaleTime([begin_point.time, end_point.time], [ECG_MARGIN_LEFT, width - ECG_MARGIN_RIGHT]).clamp(true)
    const x_axis = d3
      .axisBottom(x_scale)
      .tickSize(isTimeAxis ? -height + ECG_MARGIN_BOTTOM : -height)
      .tickFormat(time => {
        if (isTimeAxis) {
          return get_time(time)
        } else {
          return ''
        }
      })
      .tickPadding(isTimeAxis ? 12 : 0)
      .tickValues(x_ticks)

    svg
      .append('g')
      .attr('class', `axis-dark`)
      .attr('transform', `translate(0, ${isTimeAxis ? height - ECG_MARGIN_BOTTOM : height})`)
      .call(x_axis)
      .call(g => g.select(".domain").remove())
      .call(!isTimeAxis ? () => {} : g => g.append('text')
          .attr('class', 'axis-text')
          .attr('y', ECG_MARGIN_BOTTOM)
          .attr('text-anchor', 'start')
          .text(`${getDayMonthFullYear(points[0].time)}`))
      .call(!isTimeAxis ? () => {} : g => g.append('text')
      .attr('class', 'axis-text')
      .attr('x', width)
      .attr('y', ECG_MARGIN_BOTTOM)
      .attr('text-anchor', 'end')
      .text(`${getDayMonthFullYear(points[0].time)}`))

    const max = Math.max(...points.map(p => p.value))
    const min = Math.min(...points.map(p => p.value))
    let top = max + 0.2
    let bottom = min - 0.2
    if (max - min  >= 0.4) {
      top = max > 0 ? max * 1.1 : max * 0.9
      bottom = min > 0 ? min * 0.9 : min * 1.1
    }
    const y_scale = d3.scaleLinear([bottom, top], [isTimeAxis ? height - ECG_MARGIN_BOTTOM - ECG_SHIFT
                                                              : height - ECG_SHIFT, ECG_SHIFT]).nice()
    const y_axis = d3
      .axisLeft(y_scale)
      .tickSize(-width + ECG_MARGIN_RIGHT + ECG_MARGIN_LEFT)
      .tickPadding(4)
      .ticks(5)
    svg
      .append('g')
      .attr('class', `axis-dark`)
      .attr('transform', `translate(${ECG_MARGIN_LEFT}, 0)`)
      .call(y_axis)
      .call(g => g.select(".domain").remove())
      .call(g => g.append('text')
          .attr('class', 'axis-text')
          .attr('x', -4)
          .attr('y', 8)
          .attr('text-anchor', 'end')
          .text('mV'))
    const y_axis_2 = d3
      .axisRight(y_scale)
      .tickSize(0)
      .tickPadding(4)
      .ticks(5)
    svg
      .append('g')
      .attr('class', `axis-dark`)
      .attr('transform', `translate(${width - ECG_MARGIN_RIGHT}, 0)`)
      .call(y_axis_2)
      .call(g => g.select(".domain").remove())
      .call(g => g.append('text')
          .attr('class', 'axis-text')
          .attr('x', 4)
          .attr('y', 8)
          .text('mV'))

    const line = d3
      .line()
      .x(d => x_scale(d.time))
      .y(d => y_scale(d.value))
    svg
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', isolineColor)
      .attr('stroke-width', 2)
      .attr('d', line(points))

    for (let range of ranges) {
      const timestamp = new Date(range.timestamp)
      if (timestamp < begin_point.time || timestamp >= end_point.time) continue
      let begin = x_scale(timestamp)
      svg
        .append('rect')
        .attr('x', begin)
        .attr('y', 0)
        .attr('width', 3)
        .attr('height', isTimeAxis ? height - ECG_MARGIN_BOTTOM : height)
        .attr('fill', '#FFD75B')
        .attr('fill-opacity', '0.3')
        .on("mouseover", e => {
          if (!tooltip_ref || !tooltip_ref.current) return
          d3
            .select(tooltip_ref.current)
            .style('display', 'flex')
            .style('top', `${e.offsetY - 140}px`)
            .style('left', `${e.offsetX - 200}px`)
        })
        .on("mousemove", e => {
          if (!tooltip_ref || !tooltip_ref.current) return
          d3
            .select(tooltip_ref.current)
            .style('top', `${e.offsetY - 140}px`)
            .style('left', `${e.offsetX - 200}px`)
          d3.select(intervals_r_r_ref.current).html(range.rrIntervalLen)
          d3.select(intervals_p_q_ref.current).html(range.pqIntervalLen)
          d3.select(intervals_q_t_ref.current).html(range.qtIntervalLen)
          d3.select(intervals_s_t_ref.current).html(range.stIntervalLen)
          d3.select(segments_p_q_ref.current).html(range.pqSeqmentLen)
          d3.select(waves_p_ref.current).html(range.pWaveLen)
          d3.select(waves_qrs_ref.current).html(range.qrsComplexLen)
          d3.select(waves_t_ref.current).html(range.tWaveLen)
        })
        .on("mouseout", e => {
          if (!tooltip_ref || !tooltip_ref.current) return
          d3
            .select(tooltip_ref.current)
            .style('display', 'none')
        })
    }
  }, [points, ranges, scale, width])

  const classes = () => {
    const res = ['ecg-channel']
    if (ch === 1) res.push('ch_one')
    if (ch === 2) res.push('ch_two')
    if (ch === 3) res.push('ch_three')
    if (isTimeAxis) res.push('withTimeAxis')
    return res
  }

  return (
    <div className={classes().join(' ')} ref={main_ref}>
        <div className="ecg__tooltip" ref={tooltip_ref}>
          <div className="ecg__tooltip--body">
            <div className="ecg__tooltip--column__base">
              <div className="ecg__tooltip--title">Intervals:</div>
              <div className="ecg__tooltip--column">
                <div className="ecg__tooltip--row">
                  <div className="ecg__tooltip--field">R-R:</div>
                  <div className="ecg__tooltip--value" ref={intervals_r_r_ref}></div>
                  <div className="ecg__tooltip--aux">ms</div>
                </div>
                <div className="ecg__tooltip--row">
                  <div className="ecg__tooltip--field">P-Q:</div>
                  <div className="ecg__tooltip--value" ref={intervals_p_q_ref}></div>
                  <div className="ecg__tooltip--aux">ms</div>
                </div>
                <div className="ecg__tooltip--row">
                  <div className="ecg__tooltip--field">Q-T:</div>
                  <div className="ecg__tooltip--value" ref={intervals_q_t_ref}></div>
                  <div className="ecg__tooltip--aux">ms</div>
                </div>
                <div className="ecg__tooltip--row">
                  <div className="ecg__tooltip--field">S-T:</div>
                  <div className="ecg__tooltip--value" ref={intervals_s_t_ref}></div>
                  <div className="ecg__tooltip--aux">ms</div>
                </div>
              </div>
            </div>
            <div className="ecg__tooltip--column__base">
              <div className="ecg__tooltip--title">Segments:</div>
              <div className="ecg__tooltip--column">
                <div className="ecg__tooltip--row">
                  <div className="ecg__tooltip--field">P-Q:</div>
                  <div className="ecg__tooltip--value" ref={segments_p_q_ref}></div>
                  <div className="ecg__tooltip--aux">ms</div>
                </div>
              </div>
            </div>
            <div className="ecg__tooltip--column__base">
              <div className="ecg__tooltip--title">Waves:</div>
              <div className="ecg__tooltip--column">
                <div className="ecg__tooltip--row">
                  <div className="ecg__tooltip--field">P:</div>
                  <div className="ecg__tooltip--value" ref={waves_p_ref}></div>
                  <div className="ecg__tooltip--aux">ms</div>
                </div>
                <div className="ecg__tooltip--row">
                  <div className="ecg__tooltip--field">QRS:</div>
                  <div className="ecg__tooltip--value" ref={waves_qrs_ref}></div>
                  <div className="ecg__tooltip--aux">ms</div>
                </div>
                <div className="ecg__tooltip--row">
                  <div className="ecg__tooltip--field">T:</div>
                  <div className="ecg__tooltip--value" ref={waves_t_ref}></div>
                  <div className="ecg__tooltip--aux">ms</div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}
