import React, { useContext } from 'react';
import PeriodData from './PeriodData';
import PeriodTime from './PeriodTime';
import '../css/DisplayPeriod.css';
import pdf_icon from 'assets/pdf.png'
import send_icon from '../img/send.svg';

import { TabContext } from 'context';

import { Link } from 'react-router-dom';
import { BUILD_CHART_EVENTS } from 'utils/constants';

const DisplayPeriod = (props) => {
    const {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        buildChart,
        downloadPdf,
        timeDate,
        setTimeDate,
        setIsModalActive,
        isDiagnostics,
        setChartDate
    } = props

    const {clicked} = useContext(TabContext);

    return (
        <div className="displayPeriod">
            <div className='displayPeriod__container'>
                <div className='displayPeriod__title'>Period</div>
                <PeriodData date={startDate} setDate={setStartDate}/>
                {
                    clicked === 2 ? (
                        <PeriodTime
                          className="displayPeriod__time"
                          date={timeDate}
                          setDate={setTimeDate}
                        />
                    ) : (
                        <PeriodData
                          className="displayPeriod__date"
                          date={endDate}
                          setDate={setEndDate}
                        />
                    )
                }

            </div>
            {clicked === 2 ? (
              <>
                <button
                  className="displayPeriod__button build"
                  onClick={buildChart}>
                    BUILD ECG CHART
                </button>
                {/*
                  !isDiagnostics && (
                    <Link
                      to={"/report/diagnostics"}>
                        <button
                          className="displayPeriod__button diagnostic"
                          onClick={() => setChartDate((chartDate) => ({...chartDate, event: BUILD_CHART_EVENTS.build}))}>
                          GO TO DIAGNOSTICS
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clipPath="url(#clip0_2416_178384)">
                              <path d="M1.803 8.58712C2.6585 6.02299 4.44791 3.87487 6.81525 2.57013C9.18258 1.26539 11.9543 0.899644 14.5791 1.54566C17.2038 2.19168 19.4892 3.80211 20.9805 6.05657C22.4719 8.31103 23.0598 11.0443 22.6275 13.7126C22.1952 16.3808 20.7742 18.7886 18.6473 20.4568C16.5204 22.125 13.8434 22.9314 11.149 22.7156C8.45453 22.4997 5.94008 21.2775 4.10585 19.292C2.27162 17.3064 1.25206 14.7032 1.25 12.0001H7.58L10.1185 17.6776L13.631 6.12612L15.505 12.0001H18.477" stroke="#1C5182" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M19.4721 12.9954C20.0219 12.9954 20.4676 12.5497 20.4676 11.9999C20.4676 11.4501 20.0219 11.0044 19.4721 11.0044C18.9223 11.0044 18.4766 11.4501 18.4766 11.9999C18.4766 12.5497 18.9223 12.9954 19.4721 12.9954Z" stroke="#1C5182" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            <defs>
                              <clipPath id="clip0_2416_178384">
                                <rect width="24" height="24" fill="white"/>
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                    </Link>
                  )
                */}
                <button
                  className="displayPeriod__button download"
                  onClick={downloadPdf}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                      <path d="M5.5625 16C5.71168 16 5.85476 16.0499 5.96025 16.1387C6.06574 16.2276 6.125 16.3481 6.125 16.4737V17.8947C6.125 17.9819 6.209 18.0526 6.3125 18.0526H15.6875C15.7372 18.0526 15.7849 18.036 15.8201 18.0064C15.8552 17.9768 15.875 17.9366 15.875 17.8947V16.4737C15.875 16.3481 15.9343 16.2276 16.0398 16.1387C16.1452 16.0499 16.2883 16 16.4375 16C16.5867 16 16.7298 16.0499 16.8352 16.1387C16.9407 16.2276 17 16.3481 17 16.4737V17.8947C17 18.1879 16.8617 18.469 16.6156 18.6763C16.3694 18.8836 16.0356 19 15.6875 19H6.3125C5.9644 19 5.63056 18.8836 5.38442 18.6763C5.13828 18.469 5 18.1879 5 17.8947V16.4737C5 16.3481 5.05926 16.2276 5.16475 16.1387C5.27024 16.0499 5.41332 16 5.5625 16Z" fill="#1C5182"/>
                      <path d="M6.15698 10.6562C6.20667 10.6068 6.26568 10.5676 6.33064 10.5408C6.39561 10.514 6.46524 10.5003 6.53557 10.5003C6.60589 10.5003 6.67553 10.514 6.74049 10.5408C6.80545 10.5676 6.86447 10.6068 6.91416 10.6562L10.4643 14.1827V5.53227C10.4643 5.3911 10.5208 5.25572 10.6213 5.1559C10.7217 5.05608 10.858 5 11.0001 5C11.1422 5 11.2784 5.05608 11.3789 5.1559C11.4794 5.25572 11.5358 5.3911 11.5358 5.53227V14.1827L15.086 10.6562C15.1864 10.5565 15.3226 10.5004 15.4646 10.5004C15.6066 10.5004 15.7428 10.5565 15.8432 10.6562C15.9436 10.756 16 10.8913 16 11.0324C16 11.1734 15.9436 11.3087 15.8432 11.4085L11.3787 15.844C11.329 15.8935 11.27 15.9327 11.205 15.9595C11.14 15.9862 11.0704 16 11.0001 16C10.9298 16 10.8601 15.9862 10.7952 15.9595C10.7302 15.9327 10.6712 15.8935 10.6215 15.844L6.15698 11.4085C6.10721 11.3591 6.06773 11.3005 6.0408 11.2359C6.01386 11.1714 6 11.1022 6 11.0324C6 10.9625 6.01386 10.8933 6.0408 10.8288C6.06773 10.7642 6.10721 10.7056 6.15698 10.6562Z" fill="#1C5182"/>
                    </svg>
                    <img src={pdf_icon} width={22} height={22}/>
                </button>
                <button
                  className="displayPeriod__button send"
                  onClick={() => setIsModalActive(true)}>
                    <img src={send_icon} width={22} height={22}/>
                </button>
              </>
            ) : null}
        </div>
    );
};

export default DisplayPeriod;
