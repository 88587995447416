import React from 'react';
import NavBar from './NavBar';
import menu_img from '../img/2099_menu.png';
import '../css/Menu.css';

const Menu = () => {
    return (
        <div className='menu'>
            <img className='menu__img' src={menu_img} alt='menu_img' />
            <p className='menu__text'>MAP SYSTEM</p>
            <NavBar />
        </div>
    );
};

export default Menu;