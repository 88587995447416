import React from 'react';
import Status from './Status';
import {useNavigate} from "react-router-dom"
import '../css/Avatar.css';

import { getProfileOwner } from '../utils/functions';

const Avatar = ({styles}) => {
    const addStyles = {
        circleStyle: {
            position: 'absolute', 
            top: '5px', 
            left: '60px',
        },
        imgStatusStyle: {
            color: '#4BB56F',
            stroke:'#F2F2F2',
            strokeWidth: 2
        },
        statusStyle: {
            color: '#4BB56F',
            stroke: '#00923A',
            strokeWidth: 0.5,
        },
        unactiveStatusStyle: {
            color: '#BDBDBD',
            stroke: '#868686',
            strokeWidth: 0.5
        }
    };
    const navigate = useNavigate()

    // temp
    const owner = getProfileOwner();

    const on_click = () => {
      navigate('/profile')
    }

    return (
        <div className='avatar' style={styles.style_avatar} onClick={on_click}> 
            <div className='avatar__contour' style={styles.style_contour}>
                <div><img className='avatar__image' src={owner.photo} alt='avatar_img' style={styles.style_img}/></div>
                {styles.setStatus && (
                    <div style={addStyles.circleStyle}>
                        <Status addStyles={addStyles.imgStatusStyle}/>
                    </div>
                )}
            </div>
            <div className='avatar__name' style={styles.style_name}>
                {owner.name} <br/>{owner.surname}
                {styles.setStatus && (
                    <div style={{display: 'flex', gap: 10}}>
                        <Status addStyles={addStyles.statusStyle}/>
                        <Status addStyles={addStyles.unactiveStatusStyle}/>
                        <Status addStyles={addStyles.unactiveStatusStyle}/>
                    </div>
                )}
            </div>  
        </div>
    );
};

export default Avatar;
