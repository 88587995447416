// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal__content__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.modal__content__container > .close-icon {
    align-self: flex-end;
    cursor: pointer;    
    width: 20px;
    height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/css/Main.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,WAAW;IACX,YAAY;AAChB","sourcesContent":[".modal__content__container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    gap: 25px;\r\n}\r\n\r\n.modal__content__container > .close-icon {\r\n    align-self: flex-end;\r\n    cursor: pointer;    \r\n    width: 20px;\r\n    height: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
