import React, {useContext, useState} from 'react';
import Button from './Button';
import '../css/ButtonGroup.css';
import { TabContext } from '../context';
import { Link } from 'react-router-dom';

const ButtonGroup = (props) => {
    const {
        tabs,
        bigGap
    } = props

    const {clicked} = useContext(TabContext);

    return (
        <div className={bigGap ? 'buttonGroup bigGap' : 'buttonGroup'}>
            {tabs.map((b, i) => (
                <Link to={b.path} key={b.label} style={{flexGrow: 1, flexBasis: 0}}>
                    <Button className={i === clicked ? 'button active' : 'button'}>
                        {b.label}
                    </Button>
                </Link>
                
            ))}
        </div>
    );
};

export default ButtonGroup;