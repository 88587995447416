import {useState, useEffect} from 'react'
import {useNavigate} from "react-router-dom"
import {enqueueSnackbar} from 'notistack'

import '../css/OfficeForm.css'
import {
  companies as company_api,
  departments as department_api,
  persons as persons_api,
  office_tasks as tasks_api
} from 'API/hr'
import HrModal from 'components/HrModal'
import HrDialog from 'components/HrDialog'
import OfficeTask from 'components/OfficeTask'
import {HR_PAGE_SIZE} from 'utils/constants'

import {useField} from 'hooks/useField'
import {OFFICE_CHIEF} from 'utils/position.fields'
import {fields} from 'utils/office.fields'
import {
  Title,
  Field,
  SearchField,
  List,
  Line,
  Button
} from 'components/HrForms'

const get_full_name = person => {
  const {
    family_name,
    first_name,
    last_name
  } = person
  return `${family_name} ${first_name} ${last_name}`
}

const process_department = department => {
  if (!department) return
  return ({
    id: department.id,
    name: department.full_name
  })
}

const process_company = company => {
  if (!company) return
  return ({
    id: company.id,
    name: company.short_name
  })
}

const process_person = person => {
  if (!person) return
  const {
    first_name,
    family_name,
    last_name
  } = person
  return ({
    id: person.id,
    name: `${family_name} ${first_name} ${last_name}`
  })
}

const process_persons = persons => {
  if (!persons) return []
  return persons.map(item => process_person(item))
}

const OfficeForm = ({office, onSave, onReload}) => {
  const full_name = useField('')
  const chief_phone = useField('')
  const chief_phone_additional = useField('')
  const chief_email = useField('')
  const company = useField()
  const department = useField()
  const chief_person = useField()

  const [companies, set_companies] = useState([])
  const [departments, set_departments] = useState([])
  const [persons, set_persons] = useState([])
  const [edit_task, set_edit_task] = useState()
  const [remove_task, set_remove_task] = useState()
  const [remove_person, set_remove_person] = useState()

  const navigate = useNavigate()

  const load_persons = async query => {
    const args = {
      page: {page: 0, page_size: 5},
      order: {column: null, direction: null},
      query: query || ''
    }
    try {
      const loaded_res = await persons_api
        .get_page(args, null, OFFICE_CHIEF)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_persons(loaded_res.Success.models.map(model => process_person(model)))
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  const load_companies = async query => {
    const args = {
      page: {page: 0, page_size: 5},
      order: {column: null, direction: null},
      query: query || ''
    }
    try {
      const loaded_res = await company_api
        .get_page(args)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_companies(loaded_res.Success.models.map(model => process_company(model)))
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  const load_departments = async query => {
    const args = {
      page: {page: 0, page_size: 5},
      order: {column: null, direction: null},
      query: query || ''
    }
    try {
      const loaded_res = await department_api
        .get_page(args)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_departments(loaded_res.Success.models.map(model => process_department(model)))
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  useEffect(() => {
    if (!office) return
    full_name.change(office.full_name)
    chief_phone.change(office.chief_phone)
    chief_phone_additional.change(office.chief_phone_additional)
    chief_email.change(office.chief_email)
    company.change(process_company(office.company))
    chief_person.change(process_person(office.chief_person))
    department.change(process_department(office.department))
  }, [office])

  const on_save = () => {
    const errors = []
    errors.push(full_name.validate_empty())
    errors.push(chief_phone.validate_phone_opt())
    errors.push(chief_phone_additional.validate_phone_opt())
    errors.push(chief_email.validate_email_opt())
    errors.push(company.validate_empty())
    errors.push(department.validate_empty())
    if (errors.some(err => err)) return
    onSave({
      full_name: full_name.value,
      company_id: company.value.id,
      department_id: department.value?.id,
      chief_phone: chief_phone.value,
      chief_phone_additional: chief_phone_additional.value,
      chief_email: chief_email.value,
      chief_person_id: chief_person.value?.id
    })
  }

  const on_task_add = () => {
    set_edit_task({name: '', office_id: office.id})
  }

  const on_task_edit = task => {
    set_edit_task({...task, office_id: office.id})
  }

  const on_task_edit_cancel = () => {
    set_edit_task()
  }

  const on_task_edit_save = () => {
    onReload()
    set_edit_task()
  }

  const on_task_remove = task => {
    set_remove_task(task)
  }

  const on_cancel_remove_task = () => {
    set_remove_task()
  }

  const on_aprove_remove_task = async () => {
    try {
      const remove_res = await tasks_api
        .remove(remove_task.id)
        .then(res => res.json())
      if (remove_res.Success) {
        onReload()
        enqueueSnackbar('Задача удалена', {variant: 'success'})
      } else if (remove_res.IsAbsent) {
        enqueueSnackbar('Нет задачи с таким Id', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
    set_remove_task()
  }

  const on_person_add = () => {
    navigate(`/hr/office/${office.id}/person`)
  }

  const on_person_edit = person => {
    navigate(`/hr/person/${person.id}`)
  }

  const on_person_remove = person => {
    set_remove_person(person)
  }

  const on_cancel_remove_person = () => {
    set_remove_person()
  }

  const on_aprove_remove_person = async () => {
    try {
      const remove_res = await persons_api
        .remove(remove_person.id)
        .then(res => res.json())
      if (remove_res.Success) {
        onReload()
        enqueueSnackbar('Сотрудник удалён', {variant: 'success'})
      } else if (remove_res.IsAbsent) {
        enqueueSnackbar('Нет сотрудника с таким Id', {variant: 'error'})
      } else if (remove_res.NotEmpty) {
        enqueueSnackbar('Невозможно удалить. Есть связанные объекты', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
    set_remove_person()
  }

  return (
    <>
      <div className="hr_form office_form block-one">
        <Title>{fields.full_name}</Title>
        <Field
          value={full_name.value}
          isError={full_name.is_error}
          onChange={e => full_name.change(e.target.value)}
        />
        <Title>{fields.department}</Title>
        <SearchField
          value={department.value}
          values={departments}
          isError={department.is_error}
          onSearch={load_departments}
          onChange={val => department.change(val)}
        />
        <Title>{fields.company}</Title>
        <SearchField
          value={company.value}
          values={companies}
          isError={company.is_error}
          onSearch={load_companies}
          onChange={val => company.change(val)}
        />
      </div>
      <div className="hr_form office_form block-two">
        <Title>{fields.chief_person}</Title>
        <SearchField
          value={chief_person.value}
          emptyValue
          values={persons}
          onSearch={load_persons}
          onChange={val => chief_person.change(val)}
        />
        <Title>{fields.chief_phone}</Title>
        <Field
          value={chief_phone.value}
          isError={chief_phone.is_error}
          onChange={e => chief_phone.change(e.target.value)}
        />
        <Title>{fields.chief_email}</Title>
        <Field
          value={chief_email.value}
          isError={chief_email.is_error}
          onChange={e => chief_email.change(e.target.value)}
        />
        <Title>{fields.chief_phone_additional}</Title>
        <Field
          value={chief_phone_additional.value}
          isError={chief_phone_additional.is_error}
          onChange={e => chief_phone_additional.change(e.target.value)}
        />
      </div>
      {(office && office.id) ? (
        <div className="hr_form office_form block-three">
          <Title>{fields.tasks}</Title>
          <List
            values={office?.tasks}
            onAdd={on_task_add}
            onEdit={on_task_edit}
            onRemove={on_task_remove}
          />
          <Title>{fields.persons}</Title>
          <List
            values={process_persons(office?.persons)}
            onAdd={on_person_add}
            onEdit={on_person_edit}
            onRemove={on_person_remove}
          />
        </div>
      ) : null}
      <Line className="hr_form line" />
      <div className="hr_form office_form block-four">
        <Button className="save_button" onClick={on_save}>СОХРАНИТЬ ИЗМЕНЕНИЯ</Button>
      </div>
      {edit_task ? (
        <HrModal
          title="Редактирование задачи"
          onClose={on_task_edit_cancel}
        >
          <OfficeTask
            task={edit_task}
            onSuccess={on_task_edit_save}
            onCancel={on_task_edit_cancel}
          />
        </HrModal>
      ) : null}
      {remove_task ? (
        <HrModal
          title="Удаление задачи"
          onClose={on_cancel_remove_task}
        >
          <HrDialog
            title={`Удалить задачу "${remove_task.name}"?`}
            onRemove={on_aprove_remove_task}
            onCancel={on_cancel_remove_task}
          />
        </HrModal>
      ) : null}
      {remove_person ? (
        <HrModal
          title="Удаление сотрудника"
          onClose={on_cancel_remove_person}
        >
          <HrDialog
            title={`Удалить сотрудника "${remove_person.name}"?`}
            onRemove={on_aprove_remove_person}
            onCancel={on_cancel_remove_person}
          />
        </HrModal>
      ) : null}
    </>
  )
}

export default OfficeForm
