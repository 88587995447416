// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter {
    display: flex;
    align-items: center;
    gap: 15px;
    position: relative;
}

.filter .filter__title {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;    
}

.filter .filter__title .title__icon {
    width: 30px;
    height: 30px;
}

.filter .filter__title .title__text {
    font-size: 12px;
    font-weight: 400;
    color: #1c5182;
    -webkit-user-select: none;
            user-select: none;
}

.filter-list__container {
    position: absolute;
    z-index: 1;
    top: 32px;
    border: 1px solid #a7d5ff;
    border-radius: 10px;
    padding: 15px 20px;
    background: #FFF;
}

.filter-list__container .filter-list__content {
    display: flex;
    width: max-content;
    flex-direction: column;
    gap: 15px;
}

.filter-list__container .filter-list__content .filter-list__value {
    padding: 0px 10px;
    font-family: Inter;
    font-weight: 400;
    font-size: 11px;
    color: #1c5182;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/css/Filter.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,eAAe;AACnB","sourcesContent":[".filter {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 15px;\r\n    position: relative;\r\n}\r\n\r\n.filter .filter__title {\r\n    display: flex;\r\n    gap: 5px;\r\n    align-items: center;\r\n    cursor: pointer;    \r\n}\r\n\r\n.filter .filter__title .title__icon {\r\n    width: 30px;\r\n    height: 30px;\r\n}\r\n\r\n.filter .filter__title .title__text {\r\n    font-size: 12px;\r\n    font-weight: 400;\r\n    color: #1c5182;\r\n    user-select: none;\r\n}\r\n\r\n.filter-list__container {\r\n    position: absolute;\r\n    z-index: 1;\r\n    top: 32px;\r\n    border: 1px solid #a7d5ff;\r\n    border-radius: 10px;\r\n    padding: 15px 20px;\r\n    background: #FFF;\r\n}\r\n\r\n.filter-list__container .filter-list__content {\r\n    display: flex;\r\n    width: max-content;\r\n    flex-direction: column;\r\n    gap: 15px;\r\n}\r\n\r\n.filter-list__container .filter-list__content .filter-list__value {\r\n    padding: 0px 10px;\r\n    font-family: Inter;\r\n    font-weight: 400;\r\n    font-size: 11px;\r\n    color: #1c5182;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
