import React, { useState, useEffect } from 'react';
import Indicator from '../components/Indicator';
import RateTrend from '../components/RateTrend';
import { useFetching } from '../hooks/useFetching';
import DataService from '../API/DataService';
import { getEndDate, getStartDate } from '../utils/date-utils';
import { DELAY, COLORS, INDICATORS_INFO } from '../utils/constants';
import { useOutletContext } from 'react-router-dom';

const Summary = () => {
    const {chartDate, startDate, endDate } = useOutletContext();

    const [deviceHistoryData, setDeviceHistoryData] = useState();
    const [clientData, setClientData] = useState([]);

    const [fetchDeviceHistoryData, deviceHistoryLoading, deviceHistoryError] = useFetching(async () => {
        const responseData = await DataService.getDeviceHistoryData();
        setDeviceHistoryData(responseData);
    });
    const [fetchClientData, clientLoading, clientError] = useFetching(async () => {
        const responseData = await DataService.getClientData(getStartDate(startDate).toISOString(), 
                                                             getEndDate(endDate).toISOString());
        setClientData(Object.values(responseData));
    });

    useEffect(() => {
        fetchDeviceHistoryData();
        const id = setInterval(fetchDeviceHistoryData, DELAY);
        return () => clearInterval(id);
    }, []);

    useEffect(() => {   
        fetchClientData();
    }, [startDate, endDate]);

    return (
        <div className='main__container__up'>
            <div className='main__container__left'>
                <RateTrend baseDate={chartDate?.date}/>
                {deviceHistoryError ? 
                    <h2 style={{color: COLORS.red}}>{deviceHistoryError}</h2> :
                    <Indicator info={{name: 'System events log', 
                                      battery: true, 
                                      reverse: true}} 
                               data={{percent: deviceHistoryData}}/>
                }
            </div>
            <div className='main__container__right'>
                {clientError ?
                    <h2 style={{color: COLORS.red}}>{clientError}</h2> :
                    clientData.map((data, i) => (<Indicator key={i} 
                                                            info={INDICATORS_INFO[i]} 
                                                            data={data}/>))
                }
            </div>   
        </div>
    );
};

export default Summary;