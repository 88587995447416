export const fields = {
  full_name: 'Полное наименование отдела',
  department_id: 'Департамент, к которому относится организация',
  department: 'Департамент, к которому относится организация',
  company_id: 'Юридическое лицо, к которому относится организация',
  company: 'Юридическое лицо, к которому относится организация',
  chief_person_id: 'ФИО руководителя отдела',
  chief_person: 'ФИО руководителя отдела',
  chief_phone: 'Телефон Руководителя',
  chief_phone_additional: 'Телефон руководителя (доп).',
  chief_email: 'Эл. почта руководителя',
  tasks: 'Задачи отдела',
  persons: 'Сотрудники, которые работают в отделе'
}

export const order = [
  'full_name',
  'company_id',
  'department_id',
  'chief_person_id',
  'chief_phone',
  'chief_phone_additional',
  'chief_email'
]

export const main = 'full_name'

export const table = [
  'department',
  'company',
  'chief_person',
  'chief_phone',
  'chief_phone_additional',
  'chief_email'
]
