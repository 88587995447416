import {useState, useEffect} from 'react'
import {useNavigate} from "react-router-dom"
import {enqueueSnackbar} from 'notistack'

import {departments as api} from 'API/hr'
import {fields, order, main, table} from 'utils/department.fields'
import {HR_PAGE_SIZE} from 'utils/constants'

import HrModal from 'components/HrModal'
import Header from '../components/Header'
import HrMenu from '../components/HrMenu'
import HrSearch from '../components/HrSearch'
import HrTable from '../components/HrTable'
import DepartmentCard from 'components/DepartmentCard'
import HrDialog from 'components/HrDialog'
import HrPagination from 'components/HrPagination'

import 'css/DepartmentTable.css'

const process_person = person => {
  if (!person) return ''
  const {
    first_name,
    family_name,
    last_name
  } = person
  return `${family_name} ${first_name} ${last_name}`
}

const process_model = model => {
  const {
    chief_person,
    company
  } = model
  const res = {...model}
  res.chief_person = process_person(chief_person)
  res.company = model.company.short_name
  return res
}

const Departments = () => {
  const [models, set_models] = useState()
  const [total, set_total] = useState(0)
  const [page, set_page] = useState(0)
  const [page_size, set_page_size] = useState(HR_PAGE_SIZE)
  const [column, set_column] = useState()
  const [direction, set_direction] = useState('asc')
  const [query, set_query] = useState()

  const [department, set_department] = useState()
  const [remove_department, set_remove_department] = useState()

  const navigate = useNavigate()

  const load_departments = async () => {
    const args = {
      page: {page, page_size},
      order: {column: column || order[0], direction},
      query: query || ''
    }
    try {
      const loaded_res = await api
        .get_page(args)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_models(loaded_res.Success.models.map(model => process_model(model)))
        set_total(loaded_res.Success.total.amount)
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  useEffect(() => {
    load_departments()
  }, [page, page_size, column, direction, query])

  const column_change = column => {
    if (!column) set_column()
    else set_column(column)
  }

  const direction_change = direction => {
    if (!direction) set_direction('asc')
    else (set_direction(direction))
  }

  const query_change = query => {
    if (!query) set_query('')
    else set_query(query)
  }

  const on_add = () => {
    navigate('/hr/department')
  }

  const on_select = async id => {
    try {
      const loaded_res = await api
        .get_by_id(id)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_department(loaded_res.Success)
      } else if (loaded_res.IsAbsent) {
        enqueueSnackbar('Нет департамента с таким Id', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  const on_department_close = () => {
    set_department()
  }

  const on_department_edit = () => {
    navigate(`/hr/department/${department.id}`)
    set_department()
  }

  const on_department_remove = () => {
    set_remove_department(department)
    set_department()
  }

  const on_remove_close = () => {
    set_remove_department()
  }

  const on_remove_aprove = async () => {
    try {
      const remove_res = await api
        .remove(remove_department.id)
        .then(res => res.json())
      if (remove_res.Success) {
        load_departments()
        enqueueSnackbar('Департамент удалён', {variant: 'success'})
      } else if (remove_res.IsAbsent) {
        enqueueSnackbar('Нет департамента с таким Id', {variant: 'error'})
      } else if (remove_res.NotEmpty) {
        enqueueSnackbar('Невозможно удалить. Есть связанные объекты', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
    set_remove_department()
  }

  const on_page_change = value => {
    set_page(value)
  }

  return (
    <div className="layout-page hr_layout">
      <Header>Кадровый учет</Header>
      <HrMenu />
      <div className="hr_page">
        <HrSearch
          fields={fields}
          order={order}
          column={column}
          onColumnChange={column_change}
          direction={direction}
          onDirectionChange={direction_change}
          query={query}
          onQueryChange={query_change}
          addTitle="Добавить нвый департамент"
          onAdd={on_add}
        />
        {models && models.length > 0 ? (
          <>
            <HrTable
              models={models}
              fields={fields}
              main={main}
              table={table}
              select={on_select}
              short={total > page_size}
              className="hr_department"
            />
            {total > page_size ? (
              <HrPagination
                current={page}
                size={page_size}
                total={total}
                change={on_page_change}
              />
            ) : null}
          </>
        ) : (
          <div className="hr_page--empty">Нет ни одного департамента</div>
        )}
      </div>
      {department ? (
        <HrModal
          title={department.name}
          onClose={on_department_close}
          onEdit={on_department_edit}
          onRemove={on_department_remove}
        >
          <DepartmentCard className="hr_modal--card" department={department} />
        </HrModal>
      ) : null}
      {remove_department ? (
        <HrModal
          title="Удаление департамента"
          onClose={on_remove_close}
        >
          <HrDialog
            title={`Удалить департамент "${remove_department.full_name}"?`}
            onRemove={on_remove_aprove}
            onCancel={on_remove_close}
          />
        </HrModal>
      ) : null}
    </div>
  )
}

export default Departments
