import React from 'react';

const Battery = ({value, color}) => {
    const capacityHeight = value ? value : 0
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="58" viewBox="0 0 28 58" fill="none">
            <g clipPath="url(#clip0_424_28667)">
                <path d="M25.6667 57.75H2.33333C1.05 57.75 0 56.5687 0 55.125V10.5C0 9.05625 1.05 7.875 2.33333 7.875H25.6667C26.95 7.875 28 9.05625 28 10.5V55.125C28 56.5687 26.95 57.75 25.6667 57.75Z" fill="#021139"/>
                <path d="M18.6667 17.0625H9.33341C8.63341 17.0625 8.16675 16.5375 8.16675 15.75V6.5625C8.16675 5.775 8.63341 5.25 9.33341 5.25H18.6667C19.3667 5.25 19.8334 5.775 19.8334 6.5625V15.75C19.8334 16.5375 19.3667 17.0625 18.6667 17.0625Z" fill="#021139"/>
            </g>
            <svg y="8" width="28" height="50" >
                <rect y={`${100 - capacityHeight}%`} width="100%" height={`${capacityHeight}%`} rx="2" fill={color}/>
            </svg>
            <defs>
                <clipPath id="clip0_424_28667">
                    <rect width="28" height="58" fill="white"/>
                </clipPath>
            </defs>
        </svg>   
    );
};

export default Battery;