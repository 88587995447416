// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hr_form.position_form.block-one {
  margin-top: 40px;
  grid-template-columns: 220px 660px;
}

.hr_form.position_form.block-two {
  margin-top: 30px;
  grid-template-columns: 220px 400px;
}

.hr_form.position_form.block-three {
  margin-top: 30px;
  grid-template-columns: 220px 400px;
}

.hr_form.line {
  margin-top: 20px;
}

.hr_form.position_form.block-four {
  margin-top: 30px;
  grid-template-columns: 310px 1fr;
}

.hr_form.position_form.block-four .save_button {
  width: 308px;
}
`, "",{"version":3,"sources":["webpack://./src/css/PositionForm.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gCAAgC;AAClC;;AAEA;EACE,YAAY;AACd","sourcesContent":[".hr_form.position_form.block-one {\r\n  margin-top: 40px;\r\n  grid-template-columns: 220px 660px;\r\n}\r\n\r\n.hr_form.position_form.block-two {\r\n  margin-top: 30px;\r\n  grid-template-columns: 220px 400px;\r\n}\r\n\r\n.hr_form.position_form.block-three {\r\n  margin-top: 30px;\r\n  grid-template-columns: 220px 400px;\r\n}\r\n\r\n.hr_form.line {\r\n  margin-top: 20px;\r\n}\r\n\r\n.hr_form.position_form.block-four {\r\n  margin-top: 30px;\r\n  grid-template-columns: 310px 1fr;\r\n}\r\n\r\n.hr_form.position_form.block-four .save_button {\r\n  width: 308px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
