import React, { useState } from 'react';
import '../css/LogsMenu.css';
import { Link, useLocation } from 'react-router-dom';
import { SUBTAB_INFO } from '../utils/constants';

const setSubTabButton = (pathname) => {
    let tab = SUBTAB_INFO.find(b => b.path === pathname);
    let result = 0;
    if (tab)
        result = tab.index;
    return result;
};

const LogsMenu = () => {
    const location = useLocation();
    const [clicked, setClicked] = useState(() => {
        return setSubTabButton(location.pathname)
    });

    return (
        <div className='logsMenu'>
            {SUBTAB_INFO.map((log, i) => (
                <Link key={i} to={log.path} style={{flexGrow: 1, flexBasis: 0}}>
                    <div 
                        onClick={() => setClicked(i)}
                        className={i === clicked ? 'logsMenu__item active' : 'logsMenu__item'}>
                        {log.label}
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default LogsMenu;