import React from 'react';

import LogHeader from 'components/LogHeader';
import Table from 'components/Table';

import { EVENT_HEALTH_LOG_DEF } from 'utils/constants';

const staticDate = new Date(2023, 5, 14, 17, 36, 0);

const staticData = [
    {
        name: 'Maxim Vlasov',
        recommendation: 'Urgent hospitalization',
        serial: 'MAPSYS0007',
        pathologies: 'Tachycardia',
        severity: 0,
        rate: '80 BPM',
        battery: '98%',
        time: staticDate
    },
    {
        name: 'Anastasia Zayakina',
        recommendation: 'Seek medical advice',
        serial: 'MAPSYS0008',
        pathologies: 'Sinus arrhythmia',
        severity: 1,
        rate: '95 BPM',
        battery: '67%',
        time: staticDate
    },
    {
        name: 'Philipp Srecommendationin',
        recommendation: 'Monitor the load',
        serial: 'MAPSYS0009',
        pathologies: 'Elevated stress levels',
        severity: 0,
        rate: '78 BPM',
        battery: '100%',
        time: staticDate
    },
    {
        name: 'Kirill Ulanov',
        recommendation: 'Urgent hospitalization',
        serial: 'MAPSYS0006',
        pathologies: 'AV block',
        severity: 2,
        rate: '82 BPM',
        battery: '15%',
        time: staticDate
    },
    {
        name: 'Maxim Vlasov',
        recommendation: 'Seek medical advice',
        serial: 'MAPSYS0005',
        pathologies: 'Runs of ventricular tachycardia',
        severity: 1,
        rate: '105 BPM',
        battery: '24%',
        time: staticDate
    },
    {
        name: 'Anastasia Zayakina',
        recommendation: 'Monitor the load',
        serial: 'MAPSYS0004',
        pathologies: 'Bradycardia',
        severity: 1,
        rate: '87 BPM',
        battery: '6%',
        time: staticDate
    },
    {
        name: 'Kirill Ulanov',
        recommendation: 'Urgent hospitalization',
        serial: 'MAPSYS0003',
        pathologies: 'Ischemia',
        severity: 2,
        rate: '123 BPM',
        battery: '100%',
        time: staticDate
    },
    {
        name: 'Philipp Sidin',
        recommendation: 'Seek medical advice',
        serial: 'MAPSYS0002',
        pathologies: 'Bradycardia',
        severity: 1,
        rate: '79 BPM',
        battery: '86%',
        time: staticDate
    },

];

const EventHealthLog = () => {
    return (
        <div className='logs-container'>
            <LogHeader downloads>
                Employees health log
            </LogHeader>
            <Table columns={EVENT_HEALTH_LOG_DEF}
                   staticData={staticData}
                   style={{height: 460}}/>
        </div>
    );
};

export default EventHealthLog;