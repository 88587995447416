import {useState, useEffect} from 'react'

import next_svg from 'assets/next.svg'
import next_active_svg from 'assets/next_active_hr.svg'
import prev_svg from 'assets/prev.svg'
import prev_active_svg from 'assets/prev_active_hr.svg'

import '../css/HrPagination.css'

const HrPagination = (props) => {
  const [amount, set_amount] = useState(0)
  const [is_arrows, set_is_arrows] = useState(false)

  console.log(props)

  const {
    current,
    size,
    total,
    change
  } = props

  useEffect(() => {
    const pages_amount = Math.ceil(total / size)
    set_amount(pages_amount)
    set_is_arrows(pages_amount > 3)
  }, [size, total])

  const get_next = is_next => is_next ? next_active_svg : next_svg
  const get_prev = is_prev => is_prev ? prev_active_svg : prev_svg

  const is_prev = current > 0
  const is_next = current < amount - 1

  const get_range = () => {
    if (amount <= 3) {
      return [...Array(amount).keys()].map(idx => idx + 1)
    } else if (current === 0) {
      return [1, 2, 3]
    } else if (current === amount - 1) {
      return [amount - 2, amount - 1, amount]
    } else {
      return [current, current + 1, current + 2]
    }
  }

  const get_clx = idx => `hr-pagination--page ${idx === current + 1 ? 'active' : ''}`

  const to_prev = () => {
    if (!is_prev) return
    change(current - 1)
  }

  const to_next = () => {
    if (!is_next) return
    change(current + 1)
  }

  return (
    <div className="hr-pagination--container">
      <div className="hr-pagination">
        {is_arrows ? (
          <img className="hr-pagination--icon" src={get_prev(is_prev)} onClick={to_prev} />
        ) : null}
        {get_range().map(idx => (
          <div className={get_clx(idx)} onClick={() => change(idx - 1)}>{idx}</div>
        ))}
        {is_arrows ? (
          <img className="hr-pagination--icon" src={get_next(is_next)} onClick={to_next} />
        ) : null}
      </div>
    </div>
  )
}

export default HrPagination
