import React, { useEffect, useRef, useState } from 'react';
import filter_icon from '../img/filter.svg';
import FilterButton from './FilterButton';
import '../css/Filter.css';
import { useOutsideClick } from 'hooks/useOutsideClick';

const Filter = ({filters, clicked, setClicked}) => {
    const [filter, setFilter] = useState(filters[0].value);

    const filterRef = useRef(null);

    const [isActive, setIsActive] = useOutsideClick(filterRef, false);

    useEffect(() => {
        const clickedFilter = filters[clicked].value;
        setFilter(clickedFilter);
        setIsActive(false);
    }, [clicked])

    return (
        <div className='filter'>
            <div className='filter__title' 
                 onClick={() => setIsActive(!isActive)}>
                <img 
                    className='title__icon' 
                    src={filter_icon} 
                    alt='filter_icon'/>
                <div className='title__text'>
                    FILTERS
                </div>  
            </div>
            {
                isActive && (
                    <div className='filter-list__container' ref={filterRef}>
                        <div className='filter-list__content'>
                            {
                                filters.map((f, i) => (
                                    <div className='filter-list__value'
                                         id={i}
                                         key={i}
                                         onClick={() => setClicked(i)}>
                                        {f.value}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            } 
            <FilterButton onCloseButtonClicked={setClicked}>
                {filter}
            </FilterButton>
        </div>
    );
};

export default Filter;