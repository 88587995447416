// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hr_table--first-header.hr_office {
  width: 250px;
}

.hr_table.hr_office .hr_table--root thead th:nth-child(1) {
  min-width: 250px;
}

.hr_table.hr_office .hr_table--root thead th:nth-child(2) {
  min-width: 280px;
}

.hr_table.hr_office .hr_table--root thead th:nth-child(3) {
  min-width: 360px;
}

.hr_table.hr_office .hr_table--root thead th:nth-child(4) {
  min-width: 220px;
}

.hr_table.hr_office .hr_table--root thead th:nth-child(5) {
  min-width: 220px;
}

.hr_table.hr_office .hr_table--root thead th:nth-child(6) {
  min-width: 220px;
}
`, "",{"version":3,"sources":["webpack://./src/css/OfficeTable.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".hr_table--first-header.hr_office {\r\n  width: 250px;\r\n}\r\n\r\n.hr_table.hr_office .hr_table--root thead th:nth-child(1) {\r\n  min-width: 250px;\r\n}\r\n\r\n.hr_table.hr_office .hr_table--root thead th:nth-child(2) {\r\n  min-width: 280px;\r\n}\r\n\r\n.hr_table.hr_office .hr_table--root thead th:nth-child(3) {\r\n  min-width: 360px;\r\n}\r\n\r\n.hr_table.hr_office .hr_table--root thead th:nth-child(4) {\r\n  min-width: 220px;\r\n}\r\n\r\n.hr_table.hr_office .hr_table--root thead th:nth-child(5) {\r\n  min-width: 220px;\r\n}\r\n\r\n.hr_table.hr_office .hr_table--root thead th:nth-child(6) {\r\n  min-width: 220px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
