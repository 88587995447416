import React, { useState } from 'react';
import {BiSolidDownArrow} from "react-icons/bi";
import { Link } from 'react-router-dom';

const Dropdown = ({onMove}) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(!open);
        onMove(!open);
    }

    return (
        <div className='subbar'>
            <button className='subbar__button' onClick={handleOpen}>
                <span>Logs</span>
                <BiSolidDownArrow color='white' 
                                  size='13px' 
                                  className={open ? 'subbar__button__icon__up' : 
                                                    'subbar__button__icon__down'} />
            </button>
            {open && (
                <div className='subbar__links'>
                    <Link to='/health-log/name' className='subbar__link'>Health log</Link>
                    <Link to='/access-log/name' className='subbar__link'>Access and location log</Link>
                </div>
            )}                          
        </div>
    );
};

export default Dropdown;