// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendarHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.calendarHeader__selects {
    display: flex;
    gap: 10px;
}

.customSelect {
    font-family: Inter;
    color: #1C5182;
    font-size: 13px;
    border: 1px solid #1C5182;
    border-radius: 5px;
    outline: 0;
    cursor: pointer;
    padding: 5px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/css/CalendarHeader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".calendarHeader {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.calendarHeader__selects {\r\n    display: flex;\r\n    gap: 10px;\r\n}\r\n\r\n.customSelect {\r\n    font-family: Inter;\r\n    color: #1C5182;\r\n    font-size: 13px;\r\n    border: 1px solid #1C5182;\r\n    border-radius: 5px;\r\n    outline: 0;\r\n    cursor: pointer;\r\n    padding: 5px 0px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
