import '../css/PersonCard.css'

import {fields} from 'utils/person.fields'
import {HR_EMPTY_VALUE, HR_EMPTY_VALUES} from 'utils/constants'

const show_field = value => value ? value : HR_EMPTY_VALUE

const get_passport = person => {
  if (!person) return ''
  const {
    passport_series,
    passport_number,
    passport_issue_office,
    passport_office_code,
    passport_date_of_issue
  } = person
  const result = `${passport_series} ${passport_number} ${passport_issue_office} ${passport_office_code}`.trim()
  return result === '' ? HR_EMPTY_VALUE : result
}

const PersonCard = ({className, person}) => {
  return (
    <div className={`hr_person-card ${className}`}>
      <div className="hr_person-card__field">
        <div className="hr_person-card__field--title">{fields.passport}</div>
        <div className="hr_person-card__field--value">{get_passport(person)}</div>
      </div>
      <div className="hr_person-card__field">
        <div className="hr_person-card__field--title">{fields.family_status}</div>
        <div className="hr_person-card__field--value">{show_field(person?.family_status)}</div>
      </div>
      <div className="hr_person-card__field">
        <div className="hr_person-card__field--title">{fields.actual_address}</div>
        <div className="hr_person-card__field--value">{show_field(person?.actual_address)}</div>
      </div>
      <div className="hr_person-card__field">
        <div className="hr_person-card__field--title">{fields.date_of_birth}</div>
        <div className="hr_person-card__field--value">{show_field(person?.date_of_birth)}</div>
      </div>
      <div className="hr_person-card__field">
        <div className="hr_person-card__field--title">{fields.gender}</div>
        <div className="hr_person-card__field--value">{show_field(person?.gender)}</div>
      </div>
      <div className="hr_person-card__field">
        <div className="hr_person-card__field--title">{fields.phone}</div>
        <div className="hr_person-card__field--value">{show_field(person?.phone)}</div>
      </div>
      <div className="hr_person-card__field">
        <div className="hr_person-card__field--title">{fields.email}</div>
        <div className="hr_person-card__field--value">{show_field(person?.email)}</div>
      </div>
      <div className="hr_person-card__field">
        <div className="hr_person-card__field--title">{fields.office}</div>
        <div className="hr_person-card__field--value">{show_field(person?.office?.full_name)}</div>
      </div>
      <div className="hr_person-card__field">
        <div className="hr_person-card__field--title">{fields.personal_number}</div>
        <div className="hr_person-card__field--value">{show_field(person?.personal_number)}</div>
      </div>
      <div className="hr_person-card__field">
        <div className="hr_person-card__field--title">{fields.clearance_category}</div>
        <div className="hr_person-card__field--value">{show_field(person?.clearance_category)}</div>
      </div>
      <div className="hr_person-card__field">
        <div className="hr_person-card__field--title">{fields.position}</div>
        <div className="hr_person-card__field--value">{show_field(person?.position?.name)}</div>
      </div>
      <div className="hr_person-card__field">
        <div className="hr_person-card__field--title">{fields.devices}</div>
        <div className="hr_person-card__field--list">
          {person?.devices?.map(device => (
            <div className="hr_person-card__field--value">{device.serial_number}</div>
          ))}
        </div>
      </div> 
    </div>
  )
}

export default PersonCard
