import {useRef, useEffect, useState} from 'react'
import * as d3 from 'd3'

import loading_img from 'assets/loading.gif'

import { ECG_MARGIN_RIGHT, ECG_MARGIN_LEFT, ECG_MARGIN_BOTTOM, ECG_SHIFT } from 'utils/constants'

const get_time = time => {
  const hours = lead_zero(time.getHours())
  const mins = lead_zero(time.getMinutes())
  const secs = lead_zero(time.getSeconds())
  return `${hours}:${mins}:${secs}`
}

const time_tick = time => {
  const hours = lead_zero(time.getHours())
  return `${hours} h`
}

const lead_zero = num => num > 9 ? num.toString() : `0${num}`

const get_axis_class = theme => {
  if (theme === 'dark') return 'axis-dark'
  return 'axis-light'
}

export const EcgTrend = ({points, ranges, cursor, setCursor, loading}) => {
  const [size, setSize] = useState({})
  const main_ref = useRef()

  const resizeHandler = () => {
    const { width, height } = main_ref.current.getBoundingClientRect() || {}
    setSize({ width, height })
  }

  useEffect(() => {
    window.addEventListener("resize", resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener("resize", resizeHandler)
    }
  }, [])

  useEffect(() => {
    if (!points || points.length < 1440) return
    const path_stroke = points.every(p => p.value === 0) ? '#a3a3a3' : '#4BB56F'
    if (!main_ref || !main_ref.current) return

    const bg_color = '#1B1B1B'

    d3
      .select(main_ref.current)
      .selectAll('svg')
      .remove()
    const svg = d3
      .select(main_ref.current)
      .append('svg')
      .attr('width', size.width)
      .attr('height', size.height)
      .attr('viewBox', [0, 0, size.width, size.height])
    const rect = svg
      .append('rect')
      .attr('width', size.width - ECG_MARGIN_RIGHT  - ECG_MARGIN_LEFT)
      .attr('height', size.height - ECG_MARGIN_BOTTOM)
      .attr('x', ECG_MARGIN_LEFT)
      .attr('y', 0)
      .attr('fill', bg_color)

    const x_ticks = []
    const begin_point = points[0]
    const end_point = points[points.length - 1]
    
    for (let t = 0; t <= 23; t++) {
      const tick = points[t * 60].time
      x_ticks.push(tick)
    }
    const x_scale = d3
      .scaleTime([begin_point.time, end_point.time], [ECG_MARGIN_LEFT, size.width - ECG_MARGIN_RIGHT]).clamp(true)
    const x_axis = d3
      .axisBottom(x_scale)
      .tickSize(-size.height + ECG_MARGIN_BOTTOM)
      .tickFormat(time_tick)
      .tickPadding(12)
      .tickValues(x_ticks)
    svg
      .append('g')
      .attr('class', `axis-dark`)
      .attr('transform', `translate(0, ${size.height - ECG_MARGIN_BOTTOM})`)
      .call(x_axis)
      .call(g => g.select(".domain").remove())

    const max = Math.max(...points.map(p => p.value))
    const min = Math.min(...points.map(p => p.value))
    let top = max > 0 ? max * 1.1 : max * 0.9
    let bottom = min > 0 ? min * 0.9 : min * 1.1
    const y_scale = d3.scaleLinear([bottom, top], [size.height - ECG_MARGIN_BOTTOM - ECG_SHIFT , ECG_SHIFT]).nice()
    const y_axis = d3
      .axisLeft(y_scale)
      .tickSize(-size.width + ECG_MARGIN_RIGHT + ECG_MARGIN_LEFT)
      .tickPadding(4)
      .ticks(5)
    svg
      .append('g')
      .attr('class', `axis-dark`)
      .attr('transform', `translate(${ECG_MARGIN_LEFT}, 0)`)
      .call(y_axis)
      .call(g => g.select(".domain").remove())
      .call(g => g.append('text')
          .attr('class', 'axis-text')
          .attr('x', -4)
          .attr('y', 8)
          .attr('text-anchor', 'end')
          .text('BPM'))
    const y_axis_2 = d3
      .axisRight(y_scale)
      .tickSize(0)
      .tickPadding(4)
      .ticks(5)
    svg
      .append('g')
      .attr('class', `axis-dark`)
      .attr('transform', `translate(${size.width - ECG_MARGIN_RIGHT}, 0)`)
      .call(y_axis_2)
      .call(g => g.select(".domain").remove())
      .call(g => g.append('text')
          .attr('class', 'axis-text')
          .attr('x', 4)
          .attr('y', 8)
          .text('BPM'))

    const line = d3
      .line()
      .x(d => x_scale(d.time))
      .y(d => y_scale(d.value))
    svg
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', path_stroke)
      .attr('stroke-width', 2)
      .attr('d', line(points))

    for (let range of ranges) {
      let begin = x_scale(new Date(range.timestamp))
      svg
        .append('rect')
        .attr('x', begin)
        .attr('y', 0)
        .attr('width', 3)
        .attr('height', size.height - ECG_MARGIN_BOTTOM)
        .attr('fill', '#FFD75B')
        .attr('fill-opacity', '0.3')
    }

    if (cursor) {
      const begin = x_scale(cursor)
      svg
        .append('line')
        .attr('x1', begin)
        .attr('y1', 0)
        .attr('x2', begin)
        .attr('y2', size.height - ECG_MARGIN_BOTTOM)
        .attr('stroke', '#A7D5FF')
        .attr('stroke-width', 2)
    }

    svg
      .on('click', e => {
        if (e.offsetX < ECG_MARGIN_LEFT || e.offsetX > size.width - ECG_MARGIN_RIGHT) return
        let time = x_scale.invert(e.offsetX)
        time.setMilliseconds(0)
        time.setSeconds(0)
        setCursor(time)
      })
  }, [points, ranges, cursor, size])

  return (
    <div className="ecg-trend" ref={main_ref}>
      {loading ? (
        <img className="ecg-trend__loader" src={loading_img} />
      ) : null}
    </div>
  )
}
