// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu {
    width: 380px;
    height: 100vh;
    background-color: #021139;
    color: #FFF;
    position: fixed;
}

.menu__img {
    width: 151px;
    height: 54px; 
    margin-top: 57px;
    margin-left: 115px;
    filter: invert(1);
}

.menu__text {   
    margin-top: 13px;
    margin-left: 134px;    
    font-family: Roboto;
}
`, "",{"version":3,"sources":["webpack://./src/css/Menu.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".menu {\r\n    width: 380px;\r\n    height: 100vh;\r\n    background-color: #021139;\r\n    color: #FFF;\r\n    position: fixed;\r\n}\r\n\r\n.menu__img {\r\n    width: 151px;\r\n    height: 54px; \r\n    margin-top: 57px;\r\n    margin-left: 115px;\r\n    filter: invert(1);\r\n}\r\n\r\n.menu__text {   \r\n    margin-top: 13px;\r\n    margin-left: 134px;    \r\n    font-family: Roboto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
