import moment from 'moment/moment';
// import 'moment/locale/ru';
// moment.locale('ru');

export const getToday = () => moment().toDate();

export const getSpecificDate = (month, dayOfMonth, year) => {
    return moment(`${month}-${dayOfMonth}-${year}`, 'MM-DD-YYYY').toDate();
};

export const getDayOfMonth = (date) => moment(date).date();

export const getDayMonthYear = (date) => moment(date).format('DD.MM.YY');

export const getDayMonthFullYear = (date) => moment(date).format('DD.MM.YYYY');

export const getMonthsFull = () => moment.months();

export const getMonth = (date) => moment(date).month();

export const getYear = (date) => moment(date).year();

export const getWeekdays = () => moment.weekdaysShort();

export const getStartDate = (date) => moment(date).startOf('day').toDate();

export const getEndDate = (date) => moment(date).endOf('day').toDate();

export const subtractMinutes = (date, minutes) => moment(date).subtract(minutes, 'minutes').toDate();

export const addMinutes = (date, minutes) => moment(date).add(minutes, 'minutes').toDate();

export const getHourMinute = (date) => moment(date).format('HH:mm');

export const getMinutesDiff = (startDate, endDate) => moment(endDate).diff(moment(startDate), 'minutes');

export const getPathologyDateTime = (date) => moment(date.value).format('DD.MM.YYYY[\n]HH:mm:ss');

export const getDateHourMinute = (date) => moment(date).format('DD.MM.YYYY HH:mm');

export const getSpecificToday = (date) => {
    let result = moment(date).toDate();
    const currentDate = getToday();
    if (result.getDate() < currentDate.getDate())
        result = getStartDate(currentDate);
    return result;
}

export const checkDate = (date) => {
    let result = true;
    const checkedDate = moment(date).toDate().getTime();
    const startDate = getStartDate(date).getTime();
    if (checkedDate === startDate)
        result = false;
    return result;
};

export const isWeekday = (date) => {
    let result = false;
    if (moment(date).weekday() === 0 || moment(date).weekday() === 6) // sunday = 0
        result = true;
    return result;
};

const getPrevMonthYear = (month, year) => {
    if (month === 1) {
      return {
        month: 12,
        year: year - 1,
      };
    } else {
      return {
        month: month - 1,
        year,
      };
    }
};

const getNextMonthYear = (month, year) => {
    if (month === 12) {
      return {
        month: 1,
        year: year + 1,
      };
    } else {
      return {
        month: month + 1,
        year,
      };
    }
};

const totalCalendarDays = 42 ;

export const getDatesInMonthDisplay = (month, year) => {
    const daysInMonth = moment(`${month}-${year}`, 'MM-YYYY').daysInMonth(); // 1 - янв
    const firstWeekday = moment(`${month}-${year}`, 'MM-YYYY')
        .startOf('month')
        .weekday() - 1; // 0 - вскр, а не пн

    const result = [];

    const prevMonthYear = getPrevMonthYear(month, year);
    const prevDaysInMonth = moment(
        `${prevMonthYear.month}-${prevMonthYear.year}`,
        'MM-YYYY'
    ).daysInMonth();
    
    // дни предыдущего месяца
    for (let i = firstWeekday - 1; i >= 0; i--) {
        result.push({
            currentMonth: false, 
            date: getSpecificDate(prevMonthYear.month, 
                prevDaysInMonth - i, 
                prevMonthYear.year),
            });
    }

    // дни текущего
    for (let i = 1; i <= daysInMonth; i++) {
        result.push({
            currentMonth: true, 
            date: getSpecificDate(month, i, year),
        });
    }

    // дни следующего
    if (result.length < totalCalendarDays) {
        const nextMonthDays = totalCalendarDays - result.length;
        const nextMonthYear = getNextMonthYear(month, year);
        for (let i = 1; i <= nextMonthDays; i++) {
            result.push({
                currentMonth: false,
                date: getSpecificDate(nextMonthYear.month, i, nextMonthYear.year),
            });
        }
    }

    return result;
};

export const getFirstUpperCase = (value) => {
    return value ? value[0].toUpperCase() + value.slice(1) : "";
};
