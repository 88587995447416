import {useEffect} from 'react'
import {enqueueSnackbar} from 'notistack'

import DataService from 'API/DataService'
import {useField} from 'hooks/useField'
import {
  Field,
  DatePicker,
  Select
} from 'components/HrForms'

const genders = [
  {id: 'None', name: 'N/A'},
  {id: 'Male', name: 'Муж.'},
  {id: 'Female', name: 'Жен.'}
]

const process_gender = gender => {
  if (!gender) return null
  if (gender.id === genders[0].id) return null
  return gender.id
}

const process_date = date => {
  if (!date || date === '') return null
  return new Date(date)
}

const ProfilePersonal = ({profile, onReload}) => {
  const first_name = useField('')
  const family_name = useField('')
  const last_name = useField('')
  const date_of_birth = useField()
  const gender = useField('')
  const passport_issue_office = useField('')
  const passport_series = useField('')
  const passport_number = useField('')
  const passport_office_code = useField('')

  useEffect(() => {
    if (!profile) return
    first_name.change(profile.first_name)
    family_name.change(profile.family_name)
    last_name.change(profile.last_name)
    gender.change(genders.find(g => g.id === profile.gender))
    date_of_birth.change(process_date(profile.date_of_birth))
    passport_issue_office.change(profile.passport_issue_office)
    passport_series.change(profile.passport_series)
    passport_number.change(profile.passport_number)
    passport_office_code.change(profile.passport_office_code)
  }, [profile])

  const on_save = async () => {
    const errors = []
    errors.push(first_name.validate_empty())
    errors.push(family_name.validate_empty())
    errors.push(passport_series.validate_number_opt())
    errors.push(passport_number.validate_number_opt())
    if (errors.some(err => err)) return
    try {
      const save_res = await DataService.updateProfile({
        id: profile.id,
        first_name: first_name.value,
        family_name: family_name.value,
        last_name: last_name.value,
        passport_series: passport_series.value,
        passport_number: passport_number.value,
        passport_issue_office: passport_issue_office.value,
        passport_office_code: passport_office_code.value,
        passport_date_of_issue: profile.passport_date_of_issue,
        actual_address: profile.actual_address,
        date_of_birth: date_of_birth.value?.toISOString().split('T')[0],
        gender: process_gender(gender.value),
        personal_number: profile.personal_number,
        family_status: profile.family_status,
        clearance_category: profile.clearance_category,
        position_id: profile.position?.id,
        phone: profile.phone,
        email: profile.email,
        office_id: profile.office?.id
      })
      if (save_res.Success) {
        enqueueSnackbar('Сохранение прошло успешно', {variant: 'success'})
        onReload()
      } else {
        enqueueSnackbar('Invalid update', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  return (
    <div className="profile_container">
      <div className="profile_personal__two_column">
        <div className="profile_title">Фамилия</div>
        <Field
          value={family_name.value}
          isError={family_name.is_error}
          onChange={e => family_name.change(e.target.value)}
        />
        <div className="profile_title second">Дата рождения</div>
        <DatePicker
          value={date_of_birth.value}
          isError={date_of_birth.is_error}
          onChange={date => date_of_birth.change(date)}
          emptyValue={"N/A"}
        />
        <div className="profile_title">Имя</div>
        <Field
          value={first_name.value}
          isError={first_name.is_error}
          onChange={e => first_name.change(e.target.value)}
        />
        <div className="profile_title second">Пол</div>
        <Select
          value={gender.value}
          values={genders}
          isError={gender.is_error}
          onChange={val => gender.change(val)}
          emptyValue={"N/A"}
        />
        <div className="profile_title">Отчество</div>
        <Field
          value={last_name.value}
          isError={last_name.is_error}
          onChange={e => last_name.change(e.target.value)}
        />
      </div>
      <div className="profile_personal__one_column">
        <div className="profile_title">Кем выдан</div>
        <Field
          value={passport_issue_office.value}
          isError={passport_issue_office.is_error}
          onChange={e => passport_issue_office.change(e.target.value)}
        />
      </div>
      <div className="profile_personal__three_column">
        <div className="profile_title">Серия</div>
        <Field
          value={passport_series.value}
          isError={passport_series.is_error}
          onChange={e => passport_series.change(e.target.value)}
        />
        <div className="profile_title second">Номер</div>
        <Field
          value={passport_number.value}
          isError={passport_number.is_error}
          onChange={e => passport_number.change(e.target.value)}
        />
        <div className="profile_title second">Код подразделения</div>
        <Field
          value={passport_office_code.value}
          isError={passport_office_code.is_error}
          onChange={e => passport_office_code.change(e.target.value)}
        />
      </div>
      <div className="profile__footer">
        <button className="profile--button" onClick={on_save}>СОХРАНИТЬ </button>
      </div>
    </div>
  )
}

export default ProfilePersonal
