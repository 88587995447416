export const fields = {
  id: 'ID записи',
  name: 'Название должности',
  category: 'Категория должности',
  category_id: 'Категория должности'
}

export const order = [
  'id',
  'name',
  'category_id'
]

export const main = 'name'

export const table = [
  'category',
  'id'
]

export const
  COMPANY_CHIEF = 'COMPANY_CHIEF',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  COMPANY_HR = 'COMPANY_HR',
  DEPARTMENT_CHIEF = 'DEPARTMENT_CHIEF',
  OFFICE_CHIEF = 'OFFICE_CHIEF'
