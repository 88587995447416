// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customTooltip {
    background: white;
    border-radius: 5px;
    border: 0.5px solid #000;
}

.customTooltip > .label {
    margin: 17px 11px;
    font-weight: 400;
    font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/css/CustomTooltip.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".customTooltip {\r\n    background: white;\r\n    border-radius: 5px;\r\n    border: 0.5px solid #000;\r\n}\r\n\r\n.customTooltip > .label {\r\n    margin: 17px 11px;\r\n    font-weight: 400;\r\n    font-size: 12px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
