import React, { useContext } from 'react';
import { useOutletContext } from "react-router-dom"
import RateTrend from '../components/RateTrend';
import {EcgCharts} from '../components/EcgCharts'
import { TabContext } from '../context';

const Ecg = () => {
    const {chartDate, setChartDate, chartShift, setChartShift} = useOutletContext()
    const {clicked} = useContext(TabContext);

    return (
        <div className='main__container__up'>
            <div className='main__container__left'>
                <RateTrend baseDate={chartDate?.date}/>
            </div>

            {clicked === 2 ? (
              <div className='main__container__right'>
                <EcgCharts baseDate={chartDate}
                           setBaseDate={setChartDate} 
                           chartShift={chartShift}
                           setChartShift={setChartShift}/>
              </div>
            ) : null}
        </div>
    );
};

export default Ecg;
