import React, { useEffect, useState } from 'react'
import { useFetching } from '../hooks/useFetching'

import DataService from '../API/DataService'
import TrendGraph from './TrendGraph'
import {FiArrowRight, FiArrowLeft} from 'react-icons/fi'

import { addMinutes, getHourMinute} from '../utils/date-utils'

import { INIT_STEP, CURRENT_STEP, HISTORY_STEP } from '../utils/constants'

import '../css/RateTrend.css'

export const
  SET = 0,
  APPEND = 1,
  PREPEND = 2

const RateTrend = ({baseDate}) => {
    const [step, setStep] = useState(INIT_STEP)
    const [trendData, setTrendData] = useState([])
    const [wnd, setWnd] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()

    const [fetchRateTrendData, rateTrendLoading, rateTrendError] = useFetching(async (minutes, date, flag) => {
        const responseData = await DataService.getRateTrendData(minutes, date.toISOString())
        const data = responseData.map((y, i) => {
            const time = addMinutes(date, i)
            const x = getHourMinute(time)
            return {x: x, y: y}
        })
        const start_date = new Date(date.getTime())
        const end_date = addMinutes(date, data.length)
        if (flag === SET) {
            setTrendData(data)
            setStartDate(start_date)
            setEndDate(end_date)
            setWnd({begin: 0, end: 30})
        }
        if (flag === APPEND) {
            setTrendData(d => [...d, ...data])
            setEndDate(end_date)
        }
        if (flag === PREPEND) {
            setTrendData(d => [...data, ...d])
            setStartDate(start_date)
        }
        
    })

    const load_next = (next_date) => {
        let load_date
        if (next_date) 
            load_date = new Date(next_date.getTime())
        else if (endDate)
            load_date = new Date(endDate.getTime())
        if (!load_date) return
        let minutes = Math.floor((new Date().getTime() - load_date.getTime()) / 1000 / 60)
        if (minutes <= 0) return
        if (minutes >= 15) minutes = 15
        fetchRateTrendData(minutes, load_date, APPEND)
    }

    const load_prev = () => {
        if (!startDate) return
        const load_date = new Date(startDate.getTime() - 1000 * 60 * 15)
        fetchRateTrendData(15, load_date, PREPEND)
    }

    useEffect(()=> {
        (async () => {
            if (typeof baseDate === 'undefined') return
            if (baseDate == null) {
                const init_date = new Date(new Date().getTime() - 30 * 60 * 1000)
                await fetchRateTrendData(30, init_date, SET)
                setStep(CURRENT_STEP)
            } else {
                let minutes = Math.floor((new Date().getTime() - baseDate.getTime() - 10 * 60 * 1000) / 1000 / 60)
                let start_date
                if (minutes <= 0) {
                    start_date = new Date(new Date().getTime() - 30 * 60 * 1000)
                } else {
                    start_date = new Date(baseDate.getTime() - 20 * 60 * 1000)
                }
                await fetchRateTrendData(30, start_date, SET)
                const next_date = new Date(start_date.getTime() + 30 * 60 * 1000)
                load_next(next_date)
                setStep(HISTORY_STEP)
            }
        })()   
    }, [baseDate])

    useEffect(() => {
        if (step !== CURRENT_STEP) return
        const interval_id = window.setInterval(() => {
            load_next()
        }, 1000 * 10)
        return () => {
            if (interval_id) window.clearInterval(interval_id)
        }
    }, [step, load_next])

    const is_next = wnd && wnd.end < trendData?.length
    const is_prev = true

    const to_next = () => {
        if (!is_next) return
        const shift = trendData.length - wnd.end
        if (shift > 15) {
            setWnd({begin: wnd.begin + 15, end: wnd.end + 15})
        } else {
            setWnd({begin: wnd.begin + shift, end: trendData.length})
            load_next()
        }
    }

    const to_prev = () => {
        if (rateTrendLoading) return
        const next_begin_idx = wnd.begin - 15
        if (next_begin_idx >= 0) {
            setWnd({begin: next_begin_idx, end: wnd.end - 15})
        } else {
            load_prev()
        }   
    }

    return (
        <div className='rate-trend'>
            <div className='rate-trend__container'>
                <div className='rate-trend__header'>
                    <div className='rate-trend__header__text'>
                        Heart rate trend
                    </div>
                </div>
                <div className='rate-trend__graph'>
                    <TrendGraph data={trendData} wnd={wnd}/>
                    <div className='rate-trend__arrows'>
                        <FiArrowLeft size={22} 
                                     onClick={to_prev} 
                                     className={is_prev ? 'active__arrow' : 'notactive__arrow'}/>
                        <FiArrowRight size={22}
                                      onClick={to_next}
                                      className={is_next ? 'active__arrow' : 'notactive__arrow'}/>
                    </div>
                </div>           
            </div>
        </div>
    );
};

export default RateTrend;