import React from 'react';
import '../css/CustomTooltip.css';

const CustomTooltip = ({active, payload}) => {
    if (active && payload && payload.length) {
        return (
            <div className={'customTooltip'}>
                <p className={'label'}>
                    {`${payload[0].value} b/min`}
                </p>
            </div>
        );
    }
    
};

export default CustomTooltip;