import React, { useContext } from 'react';
import Avatar from './Avatar';
import { MdLogout } from 'react-icons/md';
import '../css/Header.css';
import { AuthContext, TabContext } from 'context';
import { Link } from 'react-router-dom';
import DisplayPeriod from './DisplayPeriod';

const avatarStyles = {
    style_avatar: {gap: '11px', height: '100%', borderRight: '1px solid #DCEDEC'},
    style_contour: {width: '40px', height: '40px'},
    style_img: {width: '34px', height: '34px', borderRadius: '17px'},
    style_name: {fontSize: '12px', fontWeight: 400},
    setStatus: false,
};

const Header = ({children, showMenu, menu, ...props}) => {
    const {setIsAuth} = useContext(AuthContext);

    const logout = () => {
        localStorage.removeItem('token');

         // temporarily
         localStorage.removeItem('login');
         
        setIsAuth(false);
    }

    return (
        <div className={`header${props.withoutBottom ? ' header--without_bottom' : ''}`}>
            <div className='header__title'>
                <div className='header__text'>
                    {children}
                </div>
                <div className={showMenu ? 'header__menu show' : 'header__menu'}>
                    {
                        menu && (
                            menu.map((m, i) => (
                                <div className='header__menu__container' key={m.label}>
                                    <div>/</div>
                                    <Link to={m.path}
                                          className={i === menu.length - 1 ? 'header__menu__link active' : 'header__menu__link'}>
                                            {m.label}
                                    </Link>
                                </div>
                            ))
                        )
                    }
                </div>
                {
                    props.isDiagnostics && (
                        <div className={showMenu ? 'header__displayPeriod show' : 'header__displayPeriod'}>
                            <DisplayPeriod {...props} />
                        </div>
                    )
                }
            </div>
            <div className='header__info'>
                <Avatar styles={avatarStyles}/>
                <MdLogout color='#717171' 
                          size='20px' 
                          style={{margin: '0px 30px', cursor: 'pointer'}}
                          onClick={logout}/>               
            </div>
        </div>
    );
};

export default Header;
