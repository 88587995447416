import $api from "API";
import axios from "axios";

const CLIENT_ENDPOINT = 'Client/GetClientInformation';
const DEVICE_HISTORY_ENDPOINT = 'DeviceStatus/GetDeviceHistory';
const PULSE_TREND_ENDPOINT = 'PulseTrend/Get';
const ANALYSIS_GET = 'v1/Analysis/Get';
const ANALYSIS_GET_SHORT = 'v1/Analysis/GetShort';
const DEVICE_CHARGE_ENDPOINT = 'DeviceStatus/GetBatteryCharge';
const INTEGRAL_INDICATOR_ENDPOINT = 'Pathology/GetClientIntegralHealthIndicator';
const ECG_DATA = 'EcgData/GetEcgData';
const ECG_STATUS = 'EcgData/GetEcgStatus';
const ECG_PDF = 'EcgData/GetEcgPdf';
const PATHOLOGY_COUNT_ENDPOINT = 'Pathology/GetClientPathologyEventsCount';
const PATHOLOGY_EVENTS_ENDPOINT = 'Pathology/GetClientPathologyEvents';
const MAP_INDICATOR_ENDPOINT = 'AccessMovement/GetClientIntegralIndicatorRouteMapExecution';
const ACCESS_COUNT_ENDPOINT = 'AccessMovement/GetClientAccessAndMovementEventsCount';
const ACCESS_EVENTS_ENDPOINT = 'AccessMovement/GetClientAccessAndMovementEvents';
const LOGIN_ENDPOINT = 'Authentication/SignIn';
const REGISTRATION_ENDPOINT = 'Authentication/SignUp';
const AUTH_URL = 'https://mapsys.spa2099.com:1343/api/'
const EMAIL_ENDPOINT = 'Email/SendEmailWithFiles'
const GET_PROFILE = 'v1/account/person/Get'
const UPDATE_PROFILE = 'v1/account/person/Update'
const AUTH_UPDATE = 'v1/account/auth/Update'
const DEVICE_LINK = 'v1/account/device/Link'
const DEVICE_UNLINK = 'v1/account/device/Unlink'

export default class DataService {
    static async getClientData(startDate, endDate) {
        const url = `${CLIENT_ENDPOINT}/${startDate}/${endDate}`;
        const response = await $api.get(url);
        return response.data;
    }

    static async getDeviceHistoryData() {
        const url = `${DEVICE_HISTORY_ENDPOINT}`;
        const response = await $api.get(url);
        return response.data;
    }

    static async getRateTrendData(minCount, startTime, period=60) {
        const url = `${PULSE_TREND_ENDPOINT}/${startTime}/${minCount}/${period}`;
        const response = await $api.get(url, {
            validateStatus: (status) => {
                return (status === 404 || status === 200);
            }
        });
        if (response.status === 404) {
            return new Array(minCount).fill(0);
        }
        return response.data;
    }

    static async getAnalysis(startTime, minCount) {
        const url = `${ANALYSIS_GET}/${startTime}/${minCount}`;
        const response = await $api.get(url, {
            validateStatus: (status) => {
                return (status === 404 || status === 200);
            }
        });
        if (response.status === 404) {
            return new Array(minCount).fill(0);
        }
        return response.data;
    }

    static async getAnalysisShort(startTime, minCount) {
        const url = `${ANALYSIS_GET_SHORT}/${startTime}/${minCount}`;
        const response = await $api.get(url, {
            validateStatus: (status) => {
                return (status === 404 || status === 200);
            }
        });
        if (response.status === 404) {
            return new Array(minCount).fill(0);
        }
        return response.data;
    }

    static async getDeviceChargeData() {
        const url = `${DEVICE_CHARGE_ENDPOINT}`;
        const response = await $api.get(url);
        return response.data;
    }

    static async getIntegralIndicatorData() {
        const url = `${INTEGRAL_INDICATOR_ENDPOINT}`;
        const response = await $api.get(url);
        return response.data;
    }

    static async getEcgData(date, offset, lowFrequency=0.1, highFrequency=120, flag=false) {
        const url = `${ECG_DATA}/${date.toISOString()}/${offset}/${lowFrequency}/${highFrequency}/${flag}`;
        const response = await $api.get(url);
        return response.data;
    }

    static async getEcgStatus(year, month) {
        const url = `${ECG_STATUS}/${year}/${month}`;
        const response = await $api.get(url);
        return response.data;
    }

    static async getEcgPdf(date, offset) {
        const url = `${ECG_PDF}/${date.toISOString()}/${offset}`;
        const response = await $api.get(url);
        return response.data;
    }

    static async getPathologyData(startDate, endDate, page, eventsCount, gridApi, callback) {
        const endpoints = [
            `${PATHOLOGY_COUNT_ENDPOINT}/${startDate}/${endDate}`, 
            `${PATHOLOGY_EVENTS_ENDPOINT}/${startDate}/${endDate}/${eventsCount}/${page}`
        ];
        await axios.all(endpoints.map((endpoint) => $api.get(endpoint))).then(
            axios.spread((count, events) => {
                gridApi.hideOverlay();
                callback(events.data, count.data);
            })
        ).catch((err) => {
            gridApi.showNoRowsOverlay();
            callback([], 0);
        });
    }

    static async getMapIndicatorData() {
        const url = `${MAP_INDICATOR_ENDPOINT}`;
        const response = await $api.get(url);
        return response.data;
    }    

    static async getAccessMovementData(startDate, endDate, page, eventsCount, gridApi, callback) {
        const endpoints = [
            `${ACCESS_COUNT_ENDPOINT}/${startDate}/${endDate}`, 
            `${ACCESS_EVENTS_ENDPOINT}/${startDate}/${endDate}/${eventsCount}/${page}`
        ];
        await axios.all(endpoints.map((endpoint) => $api.get(endpoint))).then(
            axios.spread((count, events) => {
                gridApi.hideOverlay();
                callback(events.data, count.data);
            })
        ).catch((err) => {
            gridApi.showNoRowsOverlay();
            callback([], 0);
        });
    }

    static async login(login, password) {
        const url = `${AUTH_URL}${LOGIN_ENDPOINT}`;
        const response = await axios.post(url, {
            login,
            password
        });
        return response.data;
    }

    static async registration(login, password) {
        const url = `${AUTH_URL}${REGISTRATION_ENDPOINT}`;
        const response = await axios.post(url, {
            login,
            password
        });
        return response.data;
    }

    static async sendEmail(email, data) {
        const url = `${EMAIL_ENDPOINT}`;
        const response = await $api.post(url, data, {params: {email}});
        return response.data;
    }

    static async getProfile() {
        const response = await $api.get(GET_PROFILE);
        return response.data;
    }
  
    static async updateProfile(profile) {
        const response = await $api.put(UPDATE_PROFILE, profile);
        return response.data;
    }
 
    static async updateAuth(auth) {
        const response = await $api.put(AUTH_UPDATE, auth);
        return response.data;
    }

    static async deviceLink(id) {
        const response = await $api.put(`${DEVICE_LINK}/${id}`);
        return response.data;
    }

    static async deviceUnlink(id) {
        const response = await $api.put(`${DEVICE_UNLINK}/${id}`);
        return response.data;
    }
}
