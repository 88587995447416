// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hr-pagination--container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hr-pagination {
  margin: 42px 0;
  display: flex;
  gap: 12px;
  align-items: center;
}

.hr-pagination--page {
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 5px;
  border: 1px solid #1C5182;
  background: rgba(255, 255, 255, 1);
  color: #1C5182;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.hr-pagination--page.active {
  background: #1C5182;
  color: #ffffff;
}

.hr-pagination--icon {
  width: 32px;
  height: 32px;
  object-fit: contain;
  background: rgba(255, 255, 255, 1);
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/css/HrPagination.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,kCAAkC;EAClC,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,kCAAkC;EAClC,eAAe;AACjB","sourcesContent":[".hr-pagination--container {\r\n  position: absolute;\r\n  bottom: 0;\r\n  left: 0;\r\n  right: 0;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n}\r\n\r\n.hr-pagination {\r\n  margin: 42px 0;\r\n  display: flex;\r\n  gap: 12px;\r\n  align-items: center;\r\n}\r\n\r\n.hr-pagination--page {\r\n  width: 32px;\r\n  height: 32px;\r\n  line-height: 32px;\r\n  border-radius: 5px;\r\n  border: 1px solid #1C5182;\r\n  background: rgba(255, 255, 255, 1);\r\n  color: #1C5182;\r\n  font-weight: 500;\r\n  font-size: 16px;\r\n  cursor: pointer;\r\n  text-align: center;\r\n}\r\n\r\n.hr-pagination--page.active {\r\n  background: #1C5182;\r\n  color: #ffffff;\r\n}\r\n\r\n.hr-pagination--icon {\r\n  width: 32px;\r\n  height: 32px;\r\n  object-fit: contain;\r\n  background: rgba(255, 255, 255, 1);\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
