// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info__block {
    position: fixed;
    z-index: 990;
    background: #FFF;
    box-shadow: 0px 9px 16px -14px #566067;
    height: 313px;
    padding-top: 64px;
    transition: transform .2s linear;
}

.info__container {
    padding: 37px 118px 30px 105px;
    width: calc(100vw - 380px);
    height: 100%;
}

.info__block.hide {
    transform: translateY(-313px);
}`, "",{"version":3,"sources":["webpack://./src/css/InfoBlock.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,sCAAsC;IACtC,aAAa;IACb,iBAAiB;IACjB,gCAAgC;AACpC;;AAEA;IACI,8BAA8B;IAC9B,0BAA0B;IAC1B,YAAY;AAChB;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":[".info__block {\r\n    position: fixed;\r\n    z-index: 990;\r\n    background: #FFF;\r\n    box-shadow: 0px 9px 16px -14px #566067;\r\n    height: 313px;\r\n    padding-top: 64px;\r\n    transition: transform .2s linear;\r\n}\r\n\r\n.info__container {\r\n    padding: 37px 118px 30px 105px;\r\n    width: calc(100vw - 380px);\r\n    height: 100%;\r\n}\r\n\r\n.info__block.hide {\r\n    transform: translateY(-313px);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
