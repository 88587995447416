import React from 'react';

import '../css/Modal.css';

const Modal = React.forwardRef(({children, disabled}, ref) => {
    return (
        <div className='modal' >
            <div className='modal__content' ref={ref}
                 id={disabled ? 'disabled' : 'abled'}>
                {children}
            </div>
        </div>
    );
});

export default Modal;