// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rate-trend {
    width: 424px;
    height: 360px;
    border-radius: 10px;
    border: 1px solid #000;
    background: #FFF;
    -webkit-user-select: none;
            user-select: none;
}

.rate-trend__container {
    padding: 20px 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rate-trend__arrows {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;
}

.rate-trend__graph {
    display: flex;
    flex-direction: column;
    gap: 13px;
}

.active__arrow {
    cursor: pointer;
    opacity: 1;
}

.notactive__arrow {
    cursor: default;
    opacity: 0.3;
}

.rate-trend__header {
    display: flex;
    justify-content: space-between;
}

.rate-trend__header .rate-trend__header__text {
    font-weight: 700;
    font-size: 18px;
    color: #3d3d3d;
}`, "",{"version":3,"sources":["webpack://./src/css/RateTrend.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;IAChB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,UAAU;AACd;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB","sourcesContent":[".rate-trend {\r\n    width: 424px;\r\n    height: 360px;\r\n    border-radius: 10px;\r\n    border: 1px solid #000;\r\n    background: #FFF;\r\n    user-select: none;\r\n}\r\n\r\n.rate-trend__container {\r\n    padding: 20px 30px;\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n}\r\n\r\n.rate-trend__arrows {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    font-size: 18px;\r\n    font-weight: 700;\r\n}\r\n\r\n.rate-trend__graph {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 13px;\r\n}\r\n\r\n.active__arrow {\r\n    cursor: pointer;\r\n    opacity: 1;\r\n}\r\n\r\n.notactive__arrow {\r\n    cursor: default;\r\n    opacity: 0.3;\r\n}\r\n\r\n.rate-trend__header {\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n\r\n.rate-trend__header .rate-trend__header__text {\r\n    font-weight: 700;\r\n    font-size: 18px;\r\n    color: #3d3d3d;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
