import {useState, useEffect} from 'react'
import {useParams} from "react-router-dom"
import {useNavigate} from "react-router-dom"
import {enqueueSnackbar} from 'notistack'

import {
  offices as api,
  companies as company_api,
  departments as departments_api
} from 'API/hr'
import Header from 'components/Header'
import HrMenu from 'components/HrMenu'
import HrTitle from 'components/HrTitle'
import OfficeForm from 'components/OfficeForm'
import OfficeCard from 'components/OfficeCard'

import {HR_VIEW_MODE, HR_EDIT_MODE} from 'utils/constants'

const Office = () => {
  const [office, set_office] = useState()
  const [mode, set_mode] = useState(HR_EDIT_MODE)

  const {id, department_id} = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const load_office = async () => {
    if (id) {
      try {
        const loaded_res = await api
          .get_by_id(id)
          .then(res => res.json())
        if (loaded_res.Success) {
          set_office(loaded_res.Success)
        } else if (loaded_res.IsAbsent) {
          enqueueSnackbar('Нет отдела с таким Id', {variant: 'error'})
        } else {
          enqueueSnackbar('Invalid query', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    } else if (department_id) {
      load_department()
    }
  }

  const load_department = async () => {
    try {
      const loaded_res = await departments_api
        .get_by_id(department_id)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_office({
          full_name: '',
          chief_phone: '',
          chief_phone_additional: '',
          chief_email: '',
          department: {
            id: loaded_res.Success.id,
            full_name: loaded_res.Success.full_name
          }
        })
        load_company(loaded_res.Success.company.id)
      } else if (loaded_res.IsAbsent) {
        enqueueSnackbar('Нет компании с таким Id', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  const load_company = async company_id => {
    try {
      const loaded_res = await company_api
        .get_by_id(company_id)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_office(office => ({
          ...office,
          company: {
            id: loaded_res.Success.id,
            short_name: loaded_res.Success.short_name
          }
        }))
      } else if (loaded_res.IsAbsent) {
        enqueueSnackbar('Нет компании с таким Id', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  useEffect(() => {
    load_office()
  }, [id])

  const save_office = async values => {
    if (id) {
      try {
        const save_res = await api
          .update({id: office.id, ...values})
          .then(res => res.json())
        if (save_res.Success) {
          enqueueSnackbar('отдел успешно обновлён', {variant: 'success'})
          load_office()
        } else if (save_res.IsAbsent) {
          enqueueSnackbar('Нет отдела с таким Id', {variant: 'error'})
        } else if (save_res.IsExistByField) {
          enqueueSnackbar('отдел с таким названием уже существует', {variant: 'error'})
        } else {
          enqueueSnackbar('Invalid update', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    } else {
      try {
        const save_res = await api
          .create({...values})
          .then(res => res.json())
        if (save_res.Success) {
          enqueueSnackbar('отдел успешно сохранён', {variant: 'success'})
          navigate(`/hr/office/${save_res.Success}`)
        } else if (save_res.IsExist) {
          enqueueSnackbar('отдел с таким названием уже существует', {variant: 'error'})
        } else {
          enqueueSnackbar('Invalid update', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    }
  }

  const on_view = () => set_mode(HR_VIEW_MODE)
  const on_edit = () => set_mode(HR_EDIT_MODE)

  const on_back = () => {
    navigate('/hr/offices')
  }

  return (
    <div className="layout-page hr_layout">
      <Header>Кадровый учет</Header>
      <HrMenu />
      <div className="hr_page">
        <HrTitle
          mode={mode}
          onView={on_view}
          onEdit={on_edit}
          onBack={on_back}
        >{office ? office.full_name : 'Новый отдел'}</HrTitle>
        {mode === HR_EDIT_MODE ? (
          <OfficeForm
            office={office}
            onSave={save_office}
            onReload={load_office}
          />
        ) : null}
        {mode === HR_VIEW_MODE ? (
          <OfficeCard className="hr_page--card" office={office} />
        ) : null}
      </div>
    </div>  )
}

export default Office
