import React, { useState, useEffect } from 'react';
import { useFetching } from '../hooks/useFetching';
import Indicator from '../components/Indicator';
import DataService from '../API/DataService';
import RateTrend from '../components/RateTrend';
import Filter from '../components/Filter';
import Table from '../components/Table';
import { ACCESS_DEF, COLORS, DELAY } from '../utils/constants';
import LogHeader from 'components/LogHeader';
import { useOutletContext } from 'react-router-dom';

const AccessLog = () => {
    const [mapIndicator, setMapIndicator] = useState({});
    const {chartDate} = useOutletContext();

    const [fetchMapIndicator, mapIndicatorLoading, mapIndicatorError] = useFetching(async () => {
        const responseData = await DataService.getMapIndicatorData();
        setMapIndicator(responseData);
    });

    useEffect(() => {
        fetchMapIndicator();
        const id = setInterval(fetchMapIndicator, DELAY);
        return () => clearInterval(id);
    }, []);

    return (
        <div className='main__container__up'>
            <div className='main__container__left'>
                <RateTrend baseDate={chartDate?.date}/>
                {mapIndicatorError ?
                    <h2 style={{color: COLORS.red}}>{mapIndicatorError}</h2> :
                    <Indicator info={{name: 'Integrated flowchart\nexecution index'}}
                               data={mapIndicator}/>
                }
            </div>
            <div className='main__container__right'>
                <div className='logs-container'>
                    <LogHeader>
                        Access and location log
                    </LogHeader>
                    <Table columns={ACCESS_DEF} 
                           apiFunction={DataService.getAccessMovementData} 
                           style={{height: 690}}/> 
                </div>    
            </div>
        </div>
    );
};

export default AccessLog;