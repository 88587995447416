// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonGroup {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}
.buttonGroup.bigGap {
    gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/css/ButtonGroup.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,QAAQ;IACR,WAAW;AACf;AACA;IACI,SAAS;AACb","sourcesContent":[".buttonGroup {\r\n    display: flex;\r\n    align-items: flex-start;\r\n    gap: 8px;\r\n    width: 100%;\r\n}\r\n.buttonGroup.bigGap {\r\n    gap: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
