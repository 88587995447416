// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.indicator {
    width: 424px;
    height: 170px;
    border-radius: 10px;
    border: 1px solid #000;
    background: #FFF;
    -webkit-user-select: none;
            user-select: none;
}

.indicator__container {
    padding: 20px 30px;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.indicator__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
}

.indicator__icons {
    display: flex;
    flex-direction: column;
    gap: 27px;
    width: -moz-fit-content;
    width: fit-content;
}

.indicator__data {
    display: flex;
    align-items: last baseline;
    justify-content: space-between;
    text-transform: uppercase;
}

.indicator__data__values {
    flex-basis: 0;
    flex-grow: 2;
    display: flex;
    gap: 30px;
    align-items: center;
    font-size: 72px;
    line-height: 72px;
    font-weight: 300;   
}

.indicator__data__text {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 320px;
}

.indicator__data__text.end{
    text-align: end;
}`, "",{"version":3,"sources":["webpack://./src/css/Indicator.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;IAChB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,0BAA0B;IAC1B,8BAA8B;IAC9B,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".indicator {\r\n    width: 424px;\r\n    height: 170px;\r\n    border-radius: 10px;\r\n    border: 1px solid #000;\r\n    background: #FFF;\r\n    user-select: none;\r\n}\r\n\r\n.indicator__container {\r\n    padding: 20px 30px;\r\n    height: 100%;\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n\r\n.indicator__text {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n    width: 100%;\r\n    font-size: 18px;\r\n    font-weight: 700;\r\n}\r\n\r\n.indicator__icons {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 27px;\r\n    width: fit-content;\r\n}\r\n\r\n.indicator__data {\r\n    display: flex;\r\n    align-items: last baseline;\r\n    justify-content: space-between;\r\n    text-transform: uppercase;\r\n}\r\n\r\n.indicator__data__values {\r\n    flex-basis: 0;\r\n    flex-grow: 2;\r\n    display: flex;\r\n    gap: 30px;\r\n    align-items: center;\r\n    font-size: 72px;\r\n    line-height: 72px;\r\n    font-weight: 300;   \r\n}\r\n\r\n.indicator__data__text {\r\n    flex-basis: 0;\r\n    flex-grow: 1;\r\n    max-width: 320px;\r\n}\r\n\r\n.indicator__data__text.end{\r\n    text-align: end;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
