// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-page.hr_layout {
  overflow: hidden;
  margin-bottom: 40px;
}

.hr_page {
  margin-top: 140px;
  margin-left: 105px;
  width: 1330px;
  min-height: calc(100vh - 180px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
}

.hr_page--empty {
  width: 100%;
  text-align: center;
  margin-top: 80px;
  line-height: 24px;
  font-weight: 700;
  font-size: 18px;
  color: #002242;
}

.hr_page--card {
  margin-top: 42px;
  width: 1330px;
}

.hr_modal--card {
  margin: 42px;
}
`, "",{"version":3,"sources":["webpack://./src/css/HrPage.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,+BAA+B;EAC/B,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,YAAY;AACd","sourcesContent":[".layout-page.hr_layout {\r\n  overflow: hidden;\r\n  margin-bottom: 40px;\r\n}\r\n\r\n.hr_page {\r\n  margin-top: 140px;\r\n  margin-left: 105px;\r\n  width: 1330px;\r\n  min-height: calc(100vh - 180px);\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: stretch;\r\n  position: relative;\r\n}\r\n\r\n.hr_page--empty {\r\n  width: 100%;\r\n  text-align: center;\r\n  margin-top: 80px;\r\n  line-height: 24px;\r\n  font-weight: 700;\r\n  font-size: 18px;\r\n  color: #002242;\r\n}\r\n\r\n.hr_page--card {\r\n  margin-top: 42px;\r\n  width: 1330px;\r\n}\r\n\r\n.hr_modal--card {\r\n  margin: 42px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
