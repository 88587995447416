// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container > .label {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    color: #7E7E7E;
    font-size: 16px; 
    font-weight: 500;
}

.input-container > .label > .input {
    width: 340px;
    height: 40px;
    border-radius: 5px;
    border: 0.5px solid #000;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px #1850AC inset;
    font-size: 14px;
    text-align: center;
    letter-spacing: 1.4px;
    font-family: Montserrat;
    font-weight: 300;
}

.input-container > .label > .input::placeholder {
    color: #CC2A2A;
    font-weight: 300;
    opacity: 1;
}

.input-container > .label > .input:focus {
    outline: 2px solid #F07F09;
    border: none;
    box-shadow: none;
}

.input-container > .label input[type="password"] {
    font-weight: 700;
}

.input-container > .label > .input.error {
    color: #CC2A2A;
    border: 0.5px solid #CC2A2A;
}

.input-container > .label > .input.error:focus {
    outline: 2px solid #CC2A2A;
    border: none;
    box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/css/Input.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,wBAAwB;IACxB,gBAAgB;IAChB,yCAAyC;IACzC,eAAe;IACf,kBAAkB;IAClB,qBAAqB;IACrB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,0BAA0B;IAC1B,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,2BAA2B;AAC/B;;AAEA;IACI,0BAA0B;IAC1B,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".input-container > .label {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 10px;\r\n    align-items: center;\r\n    color: #7E7E7E;\r\n    font-size: 16px; \r\n    font-weight: 500;\r\n}\r\n\r\n.input-container > .label > .input {\r\n    width: 340px;\r\n    height: 40px;\r\n    border-radius: 5px;\r\n    border: 0.5px solid #000;\r\n    background: #FFF;\r\n    box-shadow: 0px 0px 4px 0px #1850AC inset;\r\n    font-size: 14px;\r\n    text-align: center;\r\n    letter-spacing: 1.4px;\r\n    font-family: Montserrat;\r\n    font-weight: 300;\r\n}\r\n\r\n.input-container > .label > .input::placeholder {\r\n    color: #CC2A2A;\r\n    font-weight: 300;\r\n    opacity: 1;\r\n}\r\n\r\n.input-container > .label > .input:focus {\r\n    outline: 2px solid #F07F09;\r\n    border: none;\r\n    box-shadow: none;\r\n}\r\n\r\n.input-container > .label input[type=\"password\"] {\r\n    font-weight: 700;\r\n}\r\n\r\n.input-container > .label > .input.error {\r\n    color: #CC2A2A;\r\n    border: 0.5px solid #CC2A2A;\r\n}\r\n\r\n.input-container > .label > .input.error:focus {\r\n    outline: 2px solid #CC2A2A;\r\n    border: none;\r\n    box-shadow: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
