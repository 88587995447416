// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar__weekdays {
    /* text-transform: uppercase; */
    display: grid;
    grid-template-columns: repeat(7, minmax(auto, 1fr));
    grid-template-rows: 1;
    cursor: default;
}

.calendar__weekday {
    height: 24px;
    width: 24px;
    display: flex;
    justify-self: center;
    justify-content: center;
    align-items: center;
}

.calendar__weekday.day5, .calendar__weekday.day6{
    color: #17A1FA;
}
`, "",{"version":3,"sources":["webpack://./src/css/CalendarWeekdays.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,aAAa;IACb,mDAAmD;IACnD,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,oBAAoB;IACpB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".calendar__weekdays {\r\n    /* text-transform: uppercase; */\r\n    display: grid;\r\n    grid-template-columns: repeat(7, minmax(auto, 1fr));\r\n    grid-template-rows: 1;\r\n    cursor: default;\r\n}\r\n\r\n.calendar__weekday {\r\n    height: 24px;\r\n    width: 24px;\r\n    display: flex;\r\n    justify-self: center;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.calendar__weekday.day5, .calendar__weekday.day6{\r\n    color: #17A1FA;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
