export const fields = {
  first_name: 'Имя',
  family_name: 'Фамилия',
  last_name: 'Отчество',
  full_name: 'ФИО',
  passport: 'Паспортные данные',
  passport_series: 'Серия',
  passport_number: 'Номер',
  passport_issue_office: 'Кем выдан',
  passport_office_code: 'Код подразделения',
  passport_date_of_issue: 'Дата выдачи',
  actual_address: 'Адрес фактического проживания',
  date_of_birth: 'Дата рождения',
  gender: 'Пол',
  personal_number: 'Табельный номер',
  family_status: 'Семейное положение',
  clearance_category: 'Категория допуска',
  position: 'Должность',
  position_id: 'Должность',
  phone: 'Телефон',
  email: 'Электронная почта',
  office: 'Отдел где работает сотрудник',
  office_id: 'Отдел где рбаотает сотрудник',
  devices: 'Выданное оборудование',
  login: 'Логин',
  password: 'Пароль'
}

export const order = [
  'full_name',
  'personal_number',
  'position',
  'office',
  'clearance_category'
]

export const main = 'full_name'

export const table = [
  'personal_number',
  'position',
  'office',
  'clearance_category'
]
