import React from 'react';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import CustomTooltip from './CustomTooltip';

const getTrendData = (data, wnd) => {
    if (!wnd) return
    return data.slice(wnd.begin, wnd.end)
}

const TrendGraph = ({data, wnd}) => {
    const trendData = getTrendData(data, wnd)
    
    return (
        <BarChart width={362} height={225} data={trendData} maxBarSize={20}
                  margin={{top: 30, right: 0, left: 0, bottom: 0}}>
            <CartesianGrid vertical={false} stroke='#A7D5FF' />
            <XAxis dataKey='x' fontWeight={400} tick={{fill: 'black'}}
                   stroke='#A7D5FF' fontSize={10} tickSize={8} dy={5} tickCount={5} interval={6}/>
            <YAxis yAxisId={1} orientation='left' fontWeight={400} stroke='black'
                   fontSize={10} axisLine={false} tickLine={false} width={50}
                   dx={-15} type="number" domain={[0, 200]} allowDataOverflow={true} tickCount={11} interval={0}/>
            <YAxis dataKey='y' yAxisId={2} orientation='right' fontWeight={400}
                   stroke='black' fontSize={10} axisLine={false} tickLine={false}
                   width={50} dx={15} type="number" domain={[0, 200]} allowDataOverflow={true}
                   tickCount={11} interval={0}/>
            <defs>
                <linearGradient
                id="colorUv"
                x1="0"
                y1="0"
                x2="0"
                y2="100%"
                gradientUnits="userSpaceOnUse"
                >
                <stop offset="0.2" stopColor="#CC2A2A" />
                <stop offset="0.45" stopColor="#FBBB2E" />
                <stop offset="0.6" stopColor="#00980F" />
                </linearGradient>
            </defs>
            <Tooltip content={<CustomTooltip/>} cursor={{fill: 'rgba(33, 150, 243, 0.1)'}}/>
            <Bar yAxisId={1} dataKey='y' fill='url(#colorUv)' radius={10}/>
        </BarChart>
    );
};

export default TrendGraph;