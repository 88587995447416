import {Outlet} from 'react-router-dom'

import Menu from '../components/Menu'

const Hr = () => {
  return <>
    <Menu/>
    <Outlet />
  </>
}

export default Hr
