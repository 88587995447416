import {useState, useRef, useEffect, useCallback} from 'react'
import * as d3 from 'd3'

import 'css/EcgCharts.css'

import up_scale_svg from 'assets/up_scale.svg'
import up_scale_active_svg from 'assets/up_scale_active.svg'
import down_scale_svg from 'assets/down_scale.svg'
import down_scale_active_svg from 'assets/down_scale_active.svg'
import next_svg from 'assets/next.svg'
import next_active_svg from 'assets/next_active.svg'
import prev_svg from 'assets/prev.svg'
import prev_active_svg from 'assets/prev_active.svg'

import DataService from 'API/DataService'

import { INIT_STEP, CURRENT_STEP, HISTORY_STEP, BUILD_CHART_EVENTS } from 'utils/constants'
import { useOutletContext } from 'react-router-dom'

const get_bg_color = theme => {
  if (theme === 'dark') return '#1B1B1B'
  return '#D0E9FF'
}

const get_axis_class = theme => {
  if (theme === 'dark') return 'axis-dark'
  return 'axis-light'
}

const lead_zero = num => num > 9 ? num.toString() : `0${num}`

const get_time = time => {
  const hours = lead_zero(time.getHours())
  const mins = lead_zero(time.getMinutes())
  const secs = lead_zero(time.getSeconds())
  return `${hours}:${mins}:${secs}`
}

const rn = val => Math.round((val + Number.EPSILON) * 1000) / 1000

const lang = {
  measure: 'ECG, mV',
  ch1: '1  channel ECG, mV',
  ch2: '2  channel ECG, mV',
  ch3: '3  channel ECG, mV',
}

const get_up = scale => scale < 3 ? up_scale_active_svg : up_scale_svg
const get_down = scale => scale > 1 ? down_scale_active_svg : down_scale_svg
const get_next = is_next => is_next ? next_active_svg : next_svg
const get_prev = is_prev => is_prev ? prev_active_svg : prev_svg

const scale_to_seconds = scale => {
  if (scale === 1) return 5
  if (scale === 2) return 10
  if (scale === 3) return 20
  return 0
}

const DPS = 250

const get_shift = scale => {
  if (scale === 1) return 2
  if (scale === 2) return 5
  if (scale === 3) return 10
  return 0
}

const get_step = scale => {
  if (scale === 1) return 500
  if (scale === 2) return 1000
  if (scale === 3) return 2000
  return 0
}

const is_old = last_point => {
  const now = new Date().getTime()
  const last_time = last_point.time.getTime()
  const minutes = Math.floor((now - last_time) / 1000 / 60)
  return minutes > 10
}

const is_in_minutes = last_point => {
  const now = new Date().getTime()
  const last_time = last_point.time.getTime()
  const minutes = Math.floor((now - last_time) / 1000 / 60)
  return minutes > 0 && minutes <= 10
}

const EcgChart = (params) => {
  const {
    title,
    channel,
    theme,
    points,
    wnd,
    scale,
    isNext,
    isPrev,
    toNext,
    toPrev,
    scaleUp,
    scaleDown
  } = params
  const main_ref = useRef()
  const tooltip_ref = useRef()
  const time_ref = useRef()
  const value_ref = useRef()

  useEffect(() => {
    if (!wnd) return
    if (!main_ref || !main_ref.current) return
    const {width, height} = main_ref.current.getBoundingClientRect()
    const bg_color = get_bg_color(theme)

    d3
      .select(main_ref.current)
      .selectAll('svg')
      .remove()
    const svg = d3
      .select(main_ref.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
    const rect = svg
      .append('rect')
      .attr('width', width - 60)
      .attr('height', height - 34)
      .attr('x', 30)
      .attr('y', 0)
      .attr('fill', bg_color)

    const x_ticks = []
    const x_step = get_step(scale)
    const begin_point = points[wnd.begin]
    const end_point = points[wnd.end]
    for (let t = 0; t <= 9; t++) {
      const tick = new Date(begin_point.time.getTime() + t * x_step)
      x_ticks.push(tick)
    }
    const x_scale = d3
      .scaleTime([begin_point.time, end_point.time], [30, width - 30])
    const x_axis = d3
      .axisBottom(x_scale)
      .tickSize(-height)
      .tickFormat(get_time)
      .tickPadding(12)
      .tickValues(x_ticks)
    svg
      .append('g')
      .attr('class', `${get_axis_class(theme)}`)
      .attr('transform', `translate(0,${height-34})`)
      .call(x_axis)

    const points_wnd = points.slice(wnd.begin, wnd.end + 1)
    const max = Math.max(...points_wnd.map(p => p.value))
    const min = Math.min(...points_wnd.map(p => p.value))
    const top = max > 0 ? max * 1.1 : max * 0.9
    const bottom = min > 0 ? min * 0.9 : min * 1.1
    const y_scale = d3.scaleLinear([bottom, top], [height-46, 12])
    const y_axis = d3
      .axisLeft(y_scale)
      .tickSize(-width + 60)
      .tickPadding(4)
      .offset(-1)
      .ticks(5)
    svg
      .append('g')
      .attr('class', `${get_axis_class(theme)}`)
      .attr('transform', 'translate(31,0)')
      .call(y_axis)
    const y_axis_2 = d3
      .axisRight(y_scale)
      .tickSize(0)
      .tickPadding(4)
      .offset(-1)
      .ticks(5)
    svg
      .append('g')
      .attr('class', `${get_axis_class(theme)}`)
      .attr('transform', `translate(${width-30},0)`)
      .call(y_axis_2)

    const tooltip_line = svg
      .append('line')
      .attr('x1', '0')
      .attr('y1', '0')
      .attr('x2', '0')
      .attr('y2', `${height-34}`)
      .attr('stroke', '#3D7CB6')
      .attr('stroke-width', '2')
      .attr('style', 'display: none;')
    const tooltip_dot = svg
      .append('circle')
      .attr('cx', '0')
      .attr('cy', '0')
      .attr('r', '3')
      .attr('fill', '#F2994A')
      .attr('style', 'display: none;')
    svg
      .on("mouseover", e => {
        tooltip_line.style('display', 'block')
        tooltip_dot.style('display', 'block')
        if (!tooltip_ref || !tooltip_ref.current) return
        const tooltip = d3
          .select(tooltip_ref.current)
          .style('display', 'flex')
      })
      .on("mousemove", e => {
        tooltip_line.attr('transform', `translate(${e.offsetX}, 0)`)
        const bisect = d3.bisector(d => d.time)
        const time = x_scale.invert(e.offsetX)
        const idx = bisect.right(points_wnd, time)
        const point = points_wnd[idx] || {value: 0, time}
        const offset_y = y_scale(point.value)
        tooltip_dot.attr('transform', `translate(${e.offsetX}, ${offset_y})`)
        if (!tooltip_ref || !tooltip_ref.current) return
        d3
          .select(tooltip_ref.current)
          .style('top', `${e.offsetY - 60}px`)
          .style('left', `${e.offsetX - 54}px`)
        if (!value_ref || !value_ref.current) return
        d3.select(value_ref.current)
          .html(rn(point.value))
        if (!time_ref || !time_ref.current) return
        d3
          .select(time_ref.current)
          .html(get_time(point.time))
      })
      .on("mouseout", e => {
        tooltip_line.style('display', 'none')
        tooltip_dot.style('display', 'none')
        if (!tooltip_ref || !tooltip_ref.current) return
        const tooltip = d3
          .select(tooltip_ref.current)
          .style('display', 'none')
      })

    const line = d3
      .line()
      .x(d => x_scale(d.time))
      .y(d => y_scale(d.value))
    svg
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', '#4BB56F')
      .attr('stroke-width', 2)
      .attr('d', line(points_wnd))
 
  }, [wnd])

  return (
    <div className="ecg-chart">
      <div className="ecg-title">{title}</div>
      <div className="ecg-header">
        <div className="ecg-measure">{lang.measure}</div>
        <div className="ecg-channel">{channel}</div>
        <div className="ecg-filler"></div>
        <img className="ecg-up-scale" src={get_up(scale)} onClick={scaleUp} />
        <div className="ecg-delimiter"></div>
        <img className="ecg-down-scale" src={get_down(scale)} onClick={scaleDown} />
      </div>
      <div className="ecg-main" ref={main_ref}>
        <div className="ecg-tooltip" ref={tooltip_ref}>
          <div className="ecg-tooltip__value" ref={value_ref}></div>
          <div className="ecg-tooltip__time" ref={time_ref}></div>
        </div>
      </div>
      <div className="ecg-footer">
        <img className="ecg-prev-btn" src={get_prev(isPrev)} onClick={toPrev} />
        <div className="ecg-filler"></div>
        <img className="ecg-next-btn" src={get_next(isNext)} onClick={toNext} />
      </div>
    </div>
  )
}

const channels = [
  {key: 'ecg_channel_1', title: lang.ch1, channel: '1сh: L-R', theme: 'dark'},
  {key: 'ecg_channel_2', title: lang.ch2, channel: '2сh: F-R', theme: 'dark'},
  {key: 'ecg_channel_3', title: lang.ch3, channel: '3сh: L-F', theme: 'dark'}
]

const MV = 1000

const process = (points, current) => {
  let first_acc = []
  let second_acc = []
  let third_acc = []
  const total_amount = points.firstChannel.length
  const point_size = 1000 / DPS
  for (let counter = 0; counter < total_amount; counter++) {
    const point_time = current.getTime() + counter * point_size
    first_acc.push({
      value: points.firstChannel[counter] / MV,
      time: new Date(point_time)
    })
    second_acc.push({
      value: points.secondChannel[counter] / MV,
      time: new Date(point_time)
    })
    third_acc.push({
      value: points.thirdChannel[counter] / MV,
      time: new Date(point_time)
    })
  }
  return ({
    first_acc,
    second_acc,
    third_acc,
    begin_date: new Date(current.getTime()),
    finish_date: new Date(current.getTime() + (total_amount) * point_size)
  })
}

export const EcgCharts = (props) => {
  const [first_channel, set_first_channel] = useState([])
  const [second_channel, set_second_channel] = useState([])
  const [third_channel, set_third_channel] = useState([])
  const [start_date, set_start_date] = useState()
  const [end_date, set_end_date] = useState()
  const [step, set_step] = useState(INIT_STEP)
  const [current_idx, set_current_idx] = useState()
  const [scale, set_scale] = useState(2)
  const [wnd, set_wnd] = useState()
  const [first, set_first] = useState()
  const [last, set_last] = useState()

  const {
    setStartDate: setCalendarDate,
    setTimeDate: setCalendarTime
  } = useOutletContext()

  const {baseDate,
         setBaseDate,
         chartShift,
         setChartShift} = props

  const build_points = async (build_data, minutes) => {
    const points = await DataService.getEcgData(build_data, minutes)
    if (!points.firstChannel) return
    const {
      first_acc,
      second_acc,
      third_acc,
      begin_date,
      finish_date
    } = process(points, build_data)
    set_start_date(begin_date)
    set_end_date(finish_date)
    set_first_channel(first_acc)
    set_second_channel(second_acc)
    set_third_channel(third_acc)
  }

  useEffect(() => {
    (async () => {
      if (typeof baseDate === 'undefined') return
      if (baseDate.event === BUILD_CHART_EVENTS.ecg) return
      if (baseDate.date == null) {
        set_first()
        set_last()
        const init_data = new Date(new Date().getTime() - 10 * 60 * 1000)
        await build_points(init_data, 10)
        set_step(CURRENT_STEP)
      } else {
        let minutes = Math.floor((new Date().getTime() - baseDate.date.getTime()) / 1000 / 60)
        if (minutes <= 0) {
          alert("Wrong date and time")
          return
        } else if (minutes > 10) {
          minutes = 10
        }
        await build_points(baseDate.date, minutes)
        const prev_load_date = new Date(baseDate.date.getTime() - 1000 * 60 * 10)
        await load_prev_base(prev_load_date)
        set_step(HISTORY_STEP)
      }
    })()
  }, [baseDate])

  const load_next = async () => {
    if (!end_date) return
    let load_date = new Date(end_date.getTime())
    let minutes = Math.floor((new Date().getTime() - end_date.getTime()) / 1000 / 60)
    if (minutes <= 0) return
    if (minutes >= 10) {
      minutes = 10
    }
    let points
    try {
      points = await DataService.getEcgData(load_date, minutes)
    } catch(err) {
      alert(err)
      console.log(err)
      return
    }
    if (!points.firstChannel) return
    const point_size = 1000 / DPS
    const {
      first_acc,
      second_acc,
      third_acc,
      begin_date,
      finish_date
    } = process(points, load_date)
    set_end_date(finish_date)
    set_first_channel(ch => [...ch, ...first_acc])
    set_second_channel(ch => [...ch, ...second_acc])
    set_third_channel(ch => [...ch, ...third_acc])
  }

  useEffect(() => {
    if (step !== CURRENT_STEP) return
    const interval_id = window.setInterval(() => {
      load_next()
    }, 1000 * 10)
    return () => {
      if (interval_id) window.clearInterval(interval_id)
    }
  }, [step, load_next])

  const load_prev_base = async (start_date_base) => {
    let points
    try {
      points = await DataService.getEcgData(start_date_base, 10)
    } catch(err) {
      alert(err)
      console.log(err)
      return
    }
    const {
      first_acc,
      second_acc,
      third_acc,
      begin_date,
      finish_date
    } = process(points, start_date_base)
    set_start_date(begin_date)
    set_first_channel(ch => [...first_acc, ...ch])
    set_second_channel(ch => [...second_acc, ...ch])
    set_third_channel(ch => [...third_acc, ...ch])
  }

  const load_prev = async () => {
    if (!start_date) return
    const load_date = new Date(start_date.getTime() - 1000 * 60 * 10)
    load_prev_base(load_date)
  }

  useEffect(() => {
    const points = first_channel
    if (!points || points.length === 0) return
    if ((first == null && last == null)) {
      set_first(points[0])
      set_last(points[points.length - 1])
      if (step !== INIT_STEP)
        set_current_idx(points.length - 1)
      else {
        const scale_seconds = scale_to_seconds(scale)
        const current_idx = scale_seconds * DPS - 1
        set_current_idx(current_idx + chartShift)
      }
    } else {
      const first_idx = points.indexOf(first)
      if (first_idx > 0) {
        set_current_idx(idx => idx + first_idx)
        set_first(points[0])
        set_last(points[points.length - 1])
      } else if (first_idx == 0) {
        set_last(points[points.length - 1])
      } else {
        set_first(points[0])
        set_last(points[points.length - 1])
        const scale_seconds = scale_to_seconds(scale)
        const current_idx = scale_seconds * DPS - 1
        set_current_idx(current_idx)
        set_wnd({begin: 0, end: current_idx})
      }
    }
  }, [first_channel])

  useEffect(() => {
    const points = first_channel
    if (!points || points.length === 0) return
    const scale_seconds = scale_to_seconds(scale)
    let begin = current_idx - scale_seconds * DPS + 1
    let end = current_idx
    if (begin < 0) {
      begin = 0
      end = scale_seconds * DPS - 1
    }
    if (end > points.length - 1) {
      begin = points.length - scale_seconds * DPS
      end = points.length - 1
    }
    set_wnd({begin, end})
  }, [scale, current_idx])

  useEffect(() => {
    if (step === HISTORY_STEP) {
      const points = first_channel
      const start_date = points.at(wnd.begin)
      if (!wnd || !points || points.length === 0 || !start_date) return
      let shift = wnd.begin % (DPS * 10 * 60)
      const next_ten_min = new Date(baseDate.date.getTime() + 10 * 60 * 1000)
      const prev_ten_min = new Date(baseDate.date.getTime() - 10 * 60 * 1000)
      const minutes = Math.floor((start_date.time.getTime() - new Date(baseDate.date.getTime())) / 1000 / 60)
      if (minutes >= 10) {
        setCalendarTime(new Date(next_ten_min.getTime()))
        setCalendarDate(new Date(next_ten_min.getTime()))
        setBaseDate({
          date: new Date(next_ten_min.getTime()),
          event: BUILD_CHART_EVENTS.ecg
        })
        shift = 0
      }  
      if (minutes < 0) {
        setCalendarTime(new Date(prev_ten_min.getTime()))
        setCalendarDate(new Date(prev_ten_min.getTime()))
        setBaseDate({
          date: new Date(prev_ten_min.getTime()),
          event: BUILD_CHART_EVENTS.ecg
        })
      }
      setChartShift(shift)
    }
  }, [wnd])

  const scale_up = () => {
    if (scale >= 3) return
    set_scale(value => value + 1)
  }

  const scale_down = () => {
    if (scale <= 1) return
    set_scale(value => value - 1)
  }

  const is_next = wnd && wnd.end < first_channel?.length - 1
  const is_prev = wnd && wnd.begin > 0

  const to_next = async () => {
    const points = first_channel
    if (!is_next) return
    const scale_shift = get_shift(scale)
    const shift_amount = scale_shift * DPS
    if (current_idx + shift_amount < points.length - 1) {
      set_current_idx(current_idx + shift_amount)
    } else {
      set_current_idx(points.length - 1)
      load_next()
    }
  }

  const to_prev = () => {
    if (!is_prev) return
    const scale_shift = get_shift(scale)
    const next_current = current_idx - scale_shift * DPS
    const scale_seconds = scale_to_seconds(scale)
    let next_begin_idx = next_current - scale_seconds * DPS
    if (next_begin_idx > 0) {
      set_current_idx(next_current)
    } else {
      set_current_idx(scale_seconds * DPS - 1)
      load_prev()
    }
  }

  return (
    <div className="ecg-charts">
      <EcgChart
        {...channels[0]}
        points={first_channel}
        wnd={wnd}
        scale={scale}
        isNext={is_next}
        isPrev={is_prev}
        toNext={to_next}
        toPrev={to_prev}
        scaleUp={scale_up}
        scaleDown={scale_down}
      />
      <EcgChart
        {...channels[1]}
        points={second_channel}
        wnd={wnd}
        scale={scale}
        isNext={is_next}
        isPrev={is_prev}
        toNext={to_next}
        toPrev={to_prev}
        scaleUp={scale_up}
        scaleDown={scale_down}
      />
      <EcgChart
        {...channels[2]}
        points={third_channel}
        wnd={wnd}
        scale={scale}
        isNext={is_next}
        isPrev={is_prev}
        toNext={to_next}
        toPrev={to_prev}
        scaleUp={scale_up}
        scaleDown={scale_down}
      />
    </div>
  )
}
